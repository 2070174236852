import React, { Component } from 'react';
import { screen } from 'helpers';
import {
  Section,
  Button,
  Container,
  Stepper,
  PlanSelected,
  PlanPrice,
  ErrorDialog,
  SignupStepContainer,
} from 'components';
import { GlobalContext } from 'context';

import './confirm.less';
import { withRouter } from 'react-router';
import { request } from 'utils/api';
import { PUBLIC_TOKEN } from 'utils/env';
import { CheckoutCongrats } from 'components/CheckoutCongrats';


@withRouter
@screen
export default class Confirm extends Component {
  static contextType = GlobalContext;

  componentDidMount() {
    window?.gtag('event', 'view_confirm');
    this.timeout = setTimeout(() => {
      window.sessionStorage.clear();
      window.location.href = '/';
    }, 1000 * 60 * 60 * 3);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  state = {
    error: undefined,
  };

  handleOnSubmit = async () => {
    this.setState({ loading: true });
    const plan = this.props.plan;

    try {
      const { content } = await request({
        method: 'POST',
        path: '/v1/web/checkout',
        headers: {
          Authorization: `Basic ${PUBLIC_TOKEN}`,
        },
        body: {
          productExternalId: plan.externalId,
          ...this.context.account,
          phoneNumber: this.context.account.phone,
          planName: plan.name,
          dob: this.context.account.dob ? this.context.account.dob : this.context.startInfo.dateOfBirth, 
        },
      });

      if (!content) {
        this.setState({
          error: new Error('Something went wrong (API is misconfigured)'),
          loading: false,
        });
        return;
      }

      if (plan) {
        //window?.fbq('track', 'InitiateCheckout');
        window?.gtag('event', 'begin_checkout', {
          currency: 'USD',
          value: plan.price,
          items: [
            {
              item_id: plan.id,
              item_name: plan.name,
              price: plan.price,
              quantity: 1,
            },
          ],
        });
      }

      window.location.href = content;
      window.sessionStorage.setItem('form_variant', null); // and clear it in sessionStorage so no problems when changin pages
    } catch (e) {
      this.setState({
        error: e,
        loading: false,
      });
    }
  };

  render() {
    const { planId } = this.props;
    const { error } = this.state;
    const plan = this.context.plans.find((c) => c.id === planId);

    return (
      <div className="stepConfirm">
        {error && (
          <ErrorDialog
            title="Something went wrong"
            message={error.message}
            onClose={() => this.setState({ error: undefined })}
          />
        )}
        <Section color="wellness" style={{ borderRadius: 0 }}>
          <Container>
            <Stepper
              current={this.props.step - 1}
              steps={this.props.numberOfSteps}
            />
            <SignupStepContainer title="complete your purchase.">
              {plan && (
                <>
                  <Section className="planSection">
                    <PlanSelected
                      plan={plan}
                      accordion
                      buttons={false}
                      prices={false}
                    />
                  </Section>

                  <CheckoutCongrats plan={plan}/>
                   {/* <PlanPrice plan={plan} />*/}
                </>
              )}

              <Button
                fluid
                nueblue
                className="submit"
                loading={this.state.loading}
                onClick={this.handleOnSubmit}>
                Continue to payment
              </Button>
              <div className="divider" />

              <p className="bodycopy1">
                What happens next?
              </p>
              <ul className='confirm_list_items'>
                <li>
                  After the payment you will receive a Welcome Email to set up your password
                </li>
                <li >
                  Download the Nue Life App from Apple App Store or Google Play Store
                </li>
                <li>
                  Login to the App to complete your medical onboarding and set up your first virtual consultation
                </li>
              </ul>
            </SignupStepContainer>
          </Container>
        </Section>
      </div>
    );
  }
}
