import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import './planForUpgrade.less';
import { IconCheckmark, IconArrowDown, IconArrow } from '../Icons';
import { Link } from 'react-router-dom';
import { Button } from '../Button';
import { PlanPriceVarient } from '../PlanPriceVarient';
import useOnScreen from 'utils/hooks/onScreen';

import Spacer from 'components/Spacer';
import { PlanPriceVarientForUpgrade } from 'components/PlanPriceVarientForUpgrade';
import { GlobalContext } from 'context';

const highlightPlan = (text, pattern) => {
  const splitText = text.split(pattern);

  if (splitText.length <= 1) {
    return text;
  }

  const matches = text.match(pattern);

  return splitText.reduce(
    (arr, element, index) =>
      matches[index]
        ? [
            ...arr,
            element,
            <span key={index} className="italic">
              {matches[index]}
            </span>,
          ]
        : [...arr, element],
    []
  );
};


export const PlanForUpgrade = ({ style,className,plan,variant, flow,email,code,practice, LinkComponent = Link,
}) => {
  const { name, description, features } = plan;

  const ref = useRef();
  const isVisible = useOnScreen(ref);
  const [triggered, setTriggered] = useState(false);
  const dRef = useRef();
  const [checkout, setCheckout] = useState(flow == 'checkout' ? true : false);
  const context = GlobalContext;
  const [focusedDescription, setFocusedDescription] = useState(null);

  useEffect(() => {
    if (isVisible && !triggered) {
      setTriggered(true);
      window.gtag('event', 'view_item', {
        currency: 'USD',
        value: plan.price,
        items: [
          {
            item_id: plan.id,
            item_name: plan.name,
            price: plan.price,
            quantity: 1,
          },
        ],
      });
    }
  }, [isVisible]);


  function onCheckoutClick() {
    window?.fbq('track', 'AddToCart');

    window?.gtag('event', 'plan_buy_clicked');
    window?.gtag('event', 'add_to_cart', {
      currency: 'USD',
      value: plan.price,
      items: [
        {
          item_id: plan.id,
          item_name: plan.name,
          price: plan.price,
          quantity: 1,
        },
      ],
    });

    window?.gtag('event', 'conversion', {
      send_to: 'AW-743375642/WityCNul6_4CEJqGvOIC',
    });
  }

  return (
    <div
      key={plan.id}
      style={{
        ...style,
        backgroundColor: plan.color,
        '--backgroundColor': plan.color,
      }}
      ref={ref}
      className={classNames('plan', className)}>
      <div>
        <div className="plan__top">
          <h3 className="plan__name title">
            <span className="emp">{name}</span>
          </h3>
          <h6 className="plan__type">{plan.subscription}</h6>
        </div>

        <ul className="plan__features">
          {features.map((feature, index) => {
            const descriptionFocused = focusedDescription === index;
            return (
              <li
                key={index}
                className={feature.highlight ? 'highlight' : undefined}>
                <div className={'plan__itemWrapper'}>
                  {!feature.image && (
                    <div className="left">
                      <IconCheckmark />
                      <div className="text">
                        {highlightPlan(
                          feature.name,
                          /\.reset|\.life|\.360°|\.concierge/im
                        )}
                      </div>
                    </div>
                  )}

                  <a className="cta">
                    {feature.description && !descriptionFocused && (
                      <span
                        onClick={() => {
                          setFocusedDescription(index);
                        }}>
                        <span className="only-desktop">Details</span>
                        <IconArrowDown />
                      </span>
                    )}
                  </a>
                </div>
                {descriptionFocused && (
                  <React.Fragment>
                    <p className="plan__featureDescription">
                      {feature.description}
                    </p>
                    <Spacer size="s" />
                  </React.Fragment>
                )}
                <div className="divider"></div>
              </li>
            );
          })}
        </ul>
      </div>
      <div>
        <div className="plan__bottom">
          <div className="plan__bottom_item">
            <PlanPriceVarientForUpgrade plan={plan} variant={variant} />
          </div>
            {
          <div className="plan__bottom_item" style={{ width: '100%' }}>
          {checkout &&
            <Button
              onClick={onCheckoutClick}
              eggshell
              icon
              as={LinkComponent}
              to={practice== ""
                      ? `/checkout/${plan.id}?email=${encodeURIComponent(email)}&code=${code}`
                      : `/checkout/${plan.id}?email=${encodeURIComponent(email)}&code=${code}&practice=${practice}`}>
              <IconArrow />
              <span className="text">Choose this Program</span>
            </Button>
          }
          </div>
}
        </div>
      </div>
    </div>
  );
};
