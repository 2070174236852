import React, { useEffect } from 'react';

import staticPlans from 'data/plans.json';
import staticPlansForUpgrade from 'data/plansForUpgrade.json';
import staticPlanPrograms from 'data/planPrograms.json';
import staticPlanCare from 'data/plansCare.json';
import dataLeadQuestions from 'data/leadQuestions.json';

import useExperiment from 'utils/hooks/useExperiment';
import {API_URL} from 'utils/env';

const GlobalContext = React.createContext({
  token: undefined,
  account: {},
  setAccount: () => {},
  leadQuestions: [],
  setLeadQuestions: () => {},
  flagQuestions: [],
  setFlagQuestions: () => {},
  startInfo: undefined,
  setStartInfo: () => {},
});
GlobalContext.displayName = 'GlobalContext';

function getSafe(key, parse, defaultValue = undefined) {
  const data = window.sessionStorage.getItem(key);
  try {
    if (parse) {
      return JSON.parse(data) || defaultValue;
    } else {
      return data || defaultValue;
    }
  } catch (e) {
    return defaultValue;
  }
}

import { request } from 'utils/api';
import { PUBLIC_TOKEN } from 'utils/env';
import { captureError } from 'utils/sentry';
import usStates from 'utils/us-states';

const GlobalProvider = ({ children }) => {
  const variant = useExperiment('x9xE8bMAQtWCXbeftfG40g', 'our-process');

  const [error, setError] = React.useState();
  const [token, setToken] = React.useState(getSafe('token', false));
  const [account, setAccount] = React.useState(getSafe('account', true, []));
  const [plans, setPlans] = React.useState([]);
  const [planPrograms, setPlanPrograms] = React.useState([]);
  const [plansForUpgrade, setPlansForUpgrade] = React.useState([]);
  const [plansCare, setPlansCare] = React.useState([]);
  const [email, setEmail] = React.useState(getSafe('email', false, ''));
  const [checkoutCode, setCheckoutCode] = React.useState(getSafe('checkoutCode', false, ''));
  const [checkoutPractice, setCheckoutPractice] = React.useState('');
  const [availableStates, setAvailableStates] = React.useState([]);
  const [leadQuestions, setLeadQuestions] = React.useState(
    getSafe('leadQuestions', true, [])
  );
  const [failedQuestions, setFailedQuestions] = React.useState([]);
  const [flagQuestions, setFlagQuestions] = React.useState(
    getSafe('flagQuestions', true, [])
  );
  const [lockCheckoutOptions, setLockCheckoutOptions] = React.useState(getSafe('lockCheckoutOptions', true, false));

  const dStartInfo = getSafe('startInfo', true);

  const [startInfo, setStartInfo] = React.useState(
    dStartInfo?.dateOfBirth
      ? { ...dStartInfo, dateOfBirth: new Date(dStartInfo.dateOfBirth) }
      : {}
  );

  useEffect(() => {
    window.sessionStorage.setItem('startInfo', JSON.stringify(startInfo));
  }, [startInfo]);

  useEffect(() => {
    window.sessionStorage.setItem(
      'flagQuestions',
      JSON.stringify(flagQuestions)
    );
  }, [flagQuestions]);

  useEffect(() => {
    window.sessionStorage.setItem('lockCheckoutOptions',  JSON.stringify(lockCheckoutOptions));
  }, [lockCheckoutOptions]);

  useEffect(() => {
    window.sessionStorage.setItem('email', email);
  }, [email]);

  useEffect(() => {
    window.sessionStorage.setItem('checkoutCode', checkoutCode);
  },  [checkoutCode]);

  useEffect(() => {
    window.sessionStorage.setItem(
      'leadQuestions',
      JSON.stringify(leadQuestions)
    );
    const failQuestions = {
      breastfeedingOrPregnant: `Thank you for your interest in Nue Life. Unfortunately, our programs aren’t suitable for pregnant or breastfeeding people. We hope you’ll inquire again, once you are no longer pregnant and/or breastfeeding.`,
      schizophreniaOrPsychosis: `In general, ketamine therapy is not a suitable option for those diagnosed with schizophrenia, schizoaffective disorder, schizoid personality disorder, or psychosis. We hope you’ll investigate other alternative therapies with your medical provider.`,
      thoughtsOfEndingYourLife: (
        <>
          Ketamine has been shown to help with thoughts of ending one’s life.
          However, our at-home model is not ideal for those individuals
          currently experiencing them. We strongly recommend you investigate
          in-person ketamine clinics in your area, such as those run by our
          colleagues at{' '}
          <a target="_blank" href="https://www.fieldtriphealth.com/">
            Field Trip Health
          </a>
          . If your thoughts of self-harm are acute, please call the National
          Suicide Lifeline at 988 or seek treatment at your nearest hospital
          emergency room. The American Foundation for Suicide Prevention also
          has this informative list of{' '}
          <a
            target="_blank"
            href="https://afsp.org/suicide-prevention-resources">
            additional resources
          </a>
          .
        </>
      ),
      currentUseOfIllicitSubstances: `Thank you for your interest in Nue Life. Unfortunately, our at-home treatment model isn’t a good fit for those using illicit drugs or substances. Once three months have passed without the use of illicit drugs or substances, you will be eligible to participate in our programs.`,
    };
    const failedQuestions = leadQuestions
      .map((question) => {
        if (question.answer === 'Yes' && failQuestions[question.id]) {
          return {
            title: dataLeadQuestions.find((c) => c.id === question.id).content,
            response: failQuestions[question.id],
          };
        }
        return false;
      })
      .filter(Boolean);

    setFailedQuestions(Object.values(failedQuestions));
  }, [leadQuestions]);

  useEffect(() => {
    if (account) {
      window.sessionStorage.setItem('account', JSON.stringify(account));
    }
  }, [account]);

  useEffect(() => {
    if (token) {
      window.sessionStorage.setItem('token', token);
    }
  }, [token]);

  useEffect(() => {
    if (checkoutPractice !== '') {
      loadAvailableStates();
    }
  }, [checkoutPractice]);

  useEffect(() => {
    boot();
  }, []);

  function resetCheckoutSettings() {
    setLockCheckoutOptions(false);
    setEmail("");
    setCheckoutCode("");
    setAccount({});
  }

  async function boot() {
    try {
      await Promise.all([loadPlans(), loadAvailableStates()]);
    } catch (e) {
      setError(e);
    }
  }

  async function loadFlagQuestions() {
    if (flagQuestions.length) {
      return;
    }

    const data = await request({
      method: 'GET',
      path: '/v1/web/profile/red-flag-questions',
      headers: {
        Authorization: `Basic ${PUBLIC_TOKEN}`,
      },
    });

    setFlagQuestions(
      data.content.map((c) => {
        return {
          id: c.flag,
          ...c,
        };
      })
    );
  }

  async function loadAvailableStates() {
    if (!window.practiceCode && availableStates.length) {
      return;
    }
    let data;
    let isFreeUser = window.sessionStorage.getItem('isAppOnlySignUp');
    if (isFreeUser){
      data = await request({
        method: 'GET',
        path: '/v1/web/profile/all-states',
        headers: {
          Authorization: `Basic ${PUBLIC_TOKEN}`,
        },
      });
      setAvailableStates(
        data.content.map((c) => c.name).sort()
        
      );
      }
    else {
    
    let practiceCode = window.practiceCode || window.sessionStorage.getItem('practiceCode') || undefined;
    if (practiceCode != undefined) {window.sessionStorage.setItem('practiceCode',practiceCode);}
    if (checkoutPractice === "") {
      
      data = await request({
        method: 'GET',
        path: (practiceCode != undefined && practiceCode != 'undefined')
          ? `/v1/web/practice/states/${practiceCode}`
          : '/v1/web/profile/available-states',
        headers: {
          Authorization: `Basic ${PUBLIC_TOKEN}`,
        },
      });
    } else {
      data = await request({
        method: 'GET',
        path: `/v1/web/practice/states/${checkoutPractice}`,
        headers: {
          Authorization: `Basic ${PUBLIC_TOKEN}`,
        },
      });
    }
  

    if (!data?.content?.length) {
      captureError(
        new Error(`No available states (${window.practiceCode || 'default'})`)
      );
    }
    
    setAvailableStates(
      practiceCode
        ? data.content.map((c) => c.name).sort()
        : data.content
            .map((c) => c.name)
            .filter((c) => ['FL', 'CT', 'GA', 'MA', 'CO','TN','CA','WA','WI','MN','NJ','AZ','TX','NY','IL'].includes(c)) //Filtering only this states
            .sort()
    );
    }
  }

  async function loadPlans() {
    if (plans.length) {
      return;
    }

    const data = await request({
      method: 'GET',
      path: '/v1/web/products',
      headers: {
        Authorization: `Basic ${PUBLIC_TOKEN}`,
      },
    });

    setPlans(
      staticPlans.map((plan) => {
        const item =
          data.content.find((c) => c.name.replace('.', '') === plan.nameId) ||
          plan;

        return {
          ...plan,
          externalId: item.externalId,
        };
      })
    );

    setPlanPrograms(
      staticPlanPrograms.map((plan) => {
        const item =
          data.content.find((c) => c.name.replace('.', '') === plan.nameId) ||
          plan;

        return {
          ...plan,
          externalId: item.externalId,
        };
      })
    );

    setPlansCare(
      staticPlanCare.sort((a,b)=>a.price[0].amount-b.price[0].amount).map((plan) => {
        const item =
          data.content.find((c) => c.name.replace('.', '') === plan.nameId) ||
          plan;

        return {
          ...plan,
          externalId: item.externalId,
        };
      })
    );

    setPlansForUpgrade(
      staticPlansForUpgrade.map((plan) => {
        const item =
          data.content.find((c) => c.name.replace('.', '') === plan.nameId) ||
          plan;

        return {
          ...plan,
          externalId: item.externalId,
        };
      })
    );
  }

  const values = React.useMemo(() => {
    return {
      variant,
      account,
      setAccount,
      leadQuestions,
      failedQuestions,
      setLeadQuestions,
      flagQuestions,
      setFlagQuestions,
      availableStates,
      startInfo,
      setStartInfo,
      token,
      setToken,
      loadFlagQuestions,
      loadPlans,
      setPlans,
      plans,
      setPlansForUpgrade,
      plansForUpgrade,
      planPrograms,
      plansCare,
      setPlansCare,
      setPlanPrograms,
      setEmail,
      email,
      checkoutCode,
      setCheckoutCode,
      checkoutPractice,
      setCheckoutPractice,
      loadAvailableStates,
      lockCheckoutOptions,
      setLockCheckoutOptions,
      resetCheckoutSettings
    };
  }, [
    variant,
    token,
    setToken,
    account,
    setAccount,
    leadQuestions,
    failedQuestions,
    setLeadQuestions,
    flagQuestions,
    setFlagQuestions,
    startInfo,
    setStartInfo,
    loadFlagQuestions,
    plans,
    plansForUpgrade,
    setPlansForUpgrade,
    setPlans,
    planPrograms,
    setPlanPrograms,
    plansCare,
    setPlansCare,
    loadPlans,
    setEmail,
    email,
    setCheckoutCode,
    checkoutCode,
    checkoutPractice,
    setCheckoutPractice,
    loadAvailableStates,
    lockCheckoutOptions,
    setLockCheckoutOptions,
    resetCheckoutSettings
  ]);

  return (
    <GlobalContext.Provider value={values}>
      {error && <p>{error.message}</p>}
      {children}
    </GlobalContext.Provider>
  );
};

const useGlobal = () => {
  const context = React.useContext(GlobalContext);
  if (context === undefined) {
    throw new Error('useGlobal must be used within a GlobalProvider Context');
  }
  return context;
};

export { GlobalProvider, useGlobal, GlobalContext };
