import React, { useState } from 'react';
import './lead-dialog.less';
import { Button } from '../Button';
import { Dialog } from '../Dialog';
import { Layout } from '../Layout';
import { Select } from '../Select';
import { Input } from '../Input';
import { request } from 'utils/api';
import { PUBLIC_TOKEN } from 'utils/env';

import { typeToFormErrors } from 'utils/validate';

import states from 'utils/us-states';

import * as yup from 'yup';
import { useHistory } from 'react-router';
import { useGlobal } from 'context';

const accountSchema = yup.object().shape({
  email: yup.string().email().required('Email is Required'),
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  gender: yup.string().required(),
  state: yup.string().required('State is required'),
  /*
  phone: yup
    .string()
    .matches(
      /^\+?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4,6}$/im,
      'Must be a valid US phone number'
    )
    .required(),
  */
});

export const LeadDialog = ({ message, title, ...props }) => {
  const history = useHistory();

  const { setStartInfo, startInfo, availableStates } = useGlobal();

  React.useEffect(() => {
    window?.gtag('event', 'out_of_state_triggered');
  }, []);

  const [state, setState] = useState({
    email: '',
    state: states[props.state],
  });

  const [fieldsErrors, setFieldsErrors] = useState({});

  const [status, setStatus] = useState({});

  async function handleOnSubmit() {
    try {
      await accountSchema.validate(state, { abortEarly: false });
    } catch (e) {
      setFieldsErrors(typeToFormErrors(e));
      return;
    }

    setStatus({ loading: true });

    try {
      await request({
        method: 'POST',
        path: '/v1/web/profile/create-lead',
        body: {
          ...state,
          dateOfBirth: props.dateOfBirth,
          formVariant: 'Nue life Out of State',
        },
        headers: {
          Authorization: `Basic ${PUBLIC_TOKEN}`,
        },
      });
      window?.gtag('event', 'out_of_state_completed');
      history.push('/thank-you-for-your-interest');
      setStartInfo({ ...startInfo, state: props.state });
    } catch (e) {
      setStatus({ error: e });
    }
  }

  function setField(name, value) {
    setState({ ...state, [name]: value });
  }

  return (
    <Dialog {...props} className="leadDialog">
      <h1>AREAS OF SERVICE</h1>
      <div className="h2">
        Our therapies are currently available for residents in{' '}
        {[...availableStates]
          .slice(0, -1)
          .map((c) => states[c])
          .join(', ')}{' '}
        and {states[availableStates[availableStates.length - 1]]}.
      </div>
      <p>
        We are coming to your state soon - for early access, please enter your
        preferred email below and you will be first to know when we are live in{' '}
        {states[props.state]}.
      </p>

      <Layout horizontal stackable>
        <Select
          label="Gender"
          required
          name="gender"
          error={fieldsErrors.gender}
          onChange={(e, { name, value }) => setField(name, value)}
          options={[
            { value: '', text: 'Your gender' },
            { value: 'MALE', text: 'Male' },
            {
              value: 'FEMALE',
              text: 'Female',
            },
            {
              value: 'OTHER',
              text: 'Other',
            },
            {
              value: 'DECLINE_TO_SPECIFY',
              text: 'Decline to specify',
            },
          ]}
        />
        <Input
          error={fieldsErrors.email}
          label="Email"
          name="email"
          onChange={(e, { name, value }) => setField(name, value)}
        />
      </Layout>

      <Layout horizontal stackable>
        <Input
          error={fieldsErrors.firstName}
          label="First Name"
          name="firstName"
          onChange={(e, { name, value }) => setField(name, value)}
        />

        <Input
          error={fieldsErrors.lastName}
          label="Last Name"
          name="lastName"
          onChange={(e, { name, value }) => setField(name, value)}
        />
      </Layout>

      {status.error && <p>{status.error.message}</p>}

      <Button
        loading={status.loading}
        nueblue
        transparent
        long
        border
        onClick={() => handleOnSubmit()}>
        Submit
      </Button>
    </Dialog>
  );
};
