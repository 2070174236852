import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { screen } from 'helpers';
import { request } from 'utils/api';
import { PUBLIC_TOKEN } from 'utils/env';

import {
  Button,
  Layout,
  Section,
  IconArrowLeft,
  PlanSelected,
  SignupStepContainer,
  ErrorDialog,
  LeadDialog,
  BirthDateInput,
  StateSelect,
  Stepper,
} from 'components';

import { GlobalContext } from 'context';

import logo from 'assets/logo.svg';
import './start.less';
import classNames from 'classnames';
import states from 'utils/us-states';
import createValidation from 'yup/lib/util/createValidation';
import { Input } from 'components';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { Grid, InputLabel, TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

function getState({ context }) {
  const startInfo = context.startInfo;
  return {
    dateOfBirth: startInfo.dateOfBirth
      ? startInfo.dateOfBirth
      : "",//: dayjs('2000-01-01'),
    state: startInfo?.state,
    locked: false,
    zipCode: startInfo?.zipCode || '',
    loading: false,
    error: false,
  };
}

const BackButton = ({ flow, entryOrigin }) => {
  const backhref =
    window.location.host === 'nue.network' &&
    entryOrigin.startsWith('https://nue.network/')
      ? entryOrigin
      : '/';

  return (
    <Button
      secondary
      pill
      color={flow === 'checkout' ? 'wellness' : 'changeyellow'}
      as={'a'}
      href={backhref}>
      <IconArrowLeft />
    </Button>
  );
};

@screen
export default class Start extends Component {
  static contextType = GlobalContext;
  static title = 'Talk with Us | Ketamine Therapy Consultation | Nue Life';
  static description =
    'Talk to one of our experts to help you identify which Nue Life ketamine therapy program is the best fit to start your journey to mental wellness.';

  state = getState(this);

  componentDidMount() {
    window.scrollTo({ top: 0 });
    this.context.loadFlagQuestions();
    window?.gtag('event', 'view_start_form');

    try {
      const params = new URL(document.location).searchParams;

      var email = params.get('email') ? params.get('email') : '';
      var code = params.get('code') ? params.get('code') : '';
      var practice = params.get('practice') ? params.get('practice') : '';

      this.context.setEmail(email);
      this.context.setCheckoutCode(code);
      this.context.setCheckoutPractice(practice);
    } catch (e) {}

    if (this.props.flow === 'checkout' && email !== '' && code !== '') {
      this.setState({
        ...this.state,
        loading: true,
      });
      setTimeout(() => {
        this.fetchValidationData(email, code);
      }, '3000');
    }
  }

  fetchValidationData = async (email, code) => {
    try {
      const { content } = await request({
        method: 'POST',
        path: '/v1/web/auth/validate',
        headers: {
          Authorization: `Basic ${PUBLIC_TOKEN}`,
        },
        body: {
          email: email,
          id: code,
        },
      });

      this.context.loadAvailableStates();

      this.setState({
        dateOfBirth: new Date(content.dateOfBirth),
        state: content.state,
        locked: true,
      });

      this.context.setAccount({
        email: content.email,
        firstName: content.firstName,
        lastName: content.lastName,
        gender: content.gender,
        phone: content.phoneNumber,
        dob: content.dateOfBirth,
        acceptedTerms: true,
      });

      this.setState({
        ...this.state,
        loading: false,
      });
      this.context.setLockCheckoutOptions(true);
      const planId = this.props.planId;
      this.props.history.push(`/checkout/${planId}/confirm`);
    } catch (e) {
      this.setState({
        ...this.state,
        loading: false,
        error: true,
      });
      this.context.resetCheckoutSettings();
      this.props.history.push(window.location.path); //redirect to the online purchase flow
    }
  };

  handleOnSubmit = (e) => {
    e.preventDefault();

    const { dateOfBirth, state } = this.state;
    //REMOVED AGE VALIDATION, WE'RE USING DATEPICKERS VALIDATION
    /*if (!this.isValidAge()) {
      window?.gtag('event', 'age_warning_triggered');
      this.setState({ showAgeDialog: true });
      return;
    }*/

    if (!this.isValidState()) {
      window?.gtag('event', 'state_warning_triggered');
      this.setState({ showStateDialog: true });
      return;
    }

    this.context.setStartInfo({ dateOfBirth, state });
    this.context.setAccount({
      ...this.context.account,
      zipCode: this.state.zipCode,
    });
    this.props.onDone(this.state);
  };

  isValidState() {
    return this.context.availableStates.includes(this.state.state);
  }
/*
  isValidAge() {
    const birthDate = this.state.dateOfBirth || new Date();
    const minDate = new Date(
      birthDate.getFullYear() + 18,
      birthDate.getMonth(),
      birthDate.getDate()
    );
    return minDate <= new Date();
  }
*/
  isValidZipCode() {
    return this.state.zipCode?.length === 5;
  }

  render() {
    //const hasRequiredFields = this.isValidState() && this.isValidAge() && this.isValidZipCode();
    const { flow, planId, stepHeader } = this.props;
    const plan = this.context.plans.find((c) => c.id === planId);
    const availableStates = this.context.availableStates;

    const entryOrigin = window.localStorage.getItem('entry_origin') || '';

    return (
      <div className={classNames('stepStart', flow)}>
        <h1 className="offscreen">
          Talk with Us: Ketamine Therapy Consultation
        </h1>
        <div className="header">
          <Layout
            spread
            horizontal
            center
            style={
              this.state.loading
                ? {
                    flexDirection: 'row',
                    display: 'flex',
                    justifyContent: 'center',
                  }
                : {}
            }>
            {!this.state.loading && (
              <BackButton flow={flow} entryOrigin={entryOrigin} />
            )}
            <Link to="/home">
              <img src={logo} />
            </Link>
            <div></div>
          </Layout>
        </div>
        {this.state.error && (
          <ErrorDialog
            title="Something went wrong"
            message={'Something went wrong'}
            onClose={() => this.setState({ error: undefined })}
          />
        )}
        <Section
          className={'signUpSection'}
          color={this.props.flow === 'checkout' ? 'wellness' : 'changeyellow'}
          flat>
          <div>{this.state.loading && <Stepper current={1} steps={4} />}</div>
          <SignupStepContainer
            title={
              flow === 'checkout'
                ? !this.state.loading
                  ? 'you chose the following program:'
                  : 'redirecting to checkout'
                : 'book your free evaluation today.'
            }
            header={this.props.stepHeader}>
            {flow === 'checkout' && plan && (
              <>
                <Section>
                  <PlanSelected plan={plan} accordion buttons={false} />
                </Section>
              </>
            )}

            <div className="stepStart__text left-space">
              {flow === 'checkout' && !this.state.loading && (
                <p className="bodycopy1">
                  We’re excited for you to begin. your Nue Life journey.
                </p>
              )}

              {flow !== 'checkout' && (
                <p className="bodycopy1">
                  Talk to one of our experts to help you identify which Nue Life
                  program is the best fit to start your journey to mental
                  wellness.
                </p>
              )}
              {!this.state.loading && (
                <p className="bodycopy2">
                  We are only accepting clients 18+. Our therapies are currently
                  available for residents in{' '}
                  {[...availableStates]
                    .slice(0, -1)
                    .map((c) => states[c])
                    .join(', ')}{' '}
                  and {states[availableStates[availableStates.length - 1]]}.
                </p>
              )}
              {this.state.showAgeDialog && (
                <ErrorDialog
                  title="AGE REQUIREMENT"
                  message="Sorry, we are only accepting clients 18+."
                  onClose={() => {
                    this.setState({
                      showAgeDialog: false,
                    });
                    this.context.setStartInfo({ dateOfBirth: '' });
                  }}
                />
              )}

              {this.state.showStateDialog && (
                <LeadDialog
                  onClose={() =>
                    this.setState({
                      showStateDialog: false,
                    })
                  }
                  dateOfBirth={this.state.dateOfBirth}
                  state={this.state.state}
                />
              )}

              <form onSubmit={this.handleOnSubmit} style={{display:"flex", flexDirection:'column', justifyContent:'left'}}>
                {!this.state.loading && (
                  <>
                    {/*<Layout horizontal stackable spread>
                      <BirthDateInput
                    label="Date of Birth"
                    name="dateOfBirth"
                    value={this.state.dateOfBirth}
                    disabled={this.state.locked}
                    onChange={(e, { name, value }) => {
                      this.setState({
                        [name]: value,
                      });
                    }}
                  />

                  <StateSelect
                    required
                    prefered={this.context.availableStates}
                    label="State"
                    name="state"
                    value={this.state.state}
                    disabled={this.state.locked}
                    onChange={(e, { name, value }) => {
                      this.setState({
                        [name]: value,
                      });
                    }}
                  />
                </Layout>
                <Layout horizontal stackable spread>
                  <div style={{ width: '40%' }}>
                    <Input
                      required
                      label="Zip Code"
                      type="text"
                      name="zip"
                      value={this.state.zipCode}
                      onChange={(e, { name, value }) =>
                        this.setState({
                          ...this.state,
                          zipCode: value
                        })
                      }
                    />
                    </div>*/}
                      <Grid
                        container
                        justifyContent={'space-between'}
                        columnGap={0.6}
                        rowGap={0.6}
                        className="date_state_container">
                        <Grid item md={5.8} xs={9} >
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <InputLabel
                                sx={{
                                  position: 'absolute',
                                  paddingTop: '15px',
                                  paddingLeft: '20px',
                                  fontFamily: 'Gotham',
                                  color: '#0d345c',
                                  zIndex: 1,
                                  fontSize: '10px',
                                  opacity: '0.7',
                                }}>
                                Date of Birth
                              </InputLabel>
                              <DatePicker
                                value={this.state.dateOfBirth}
                                onChange={(newValue) => {
                                  this.setState({
                                    ...this.state,
                                    dateOfBirth: newValue,
                                  });
                                }}
                                disableFuture
                                maxDate={dayjs().subtract(18, 'year').toDate()}
                                openTo={'day'}
                                inputFormat="MM/DD/YYYY"
                                renderInput={(params) => (

                                    <TextField
                                      {...params}
                                      sx={{
                                        backgroundColor: '#F1F1F1',
                                        opacity: '0.8',
                                        paddingTop: '15px',
                                        paddingLeft: '7px',
                                        color: '#0d345c',
                                        '& .MuiInputBase-input': {
                                          fontFamily: 'Gotham',
                                          fontSize: '0.9em',
                                          opacity: '1',
                                        },
                                      }}
                                    />

                                )}
                              />

                          </LocalizationProvider>
                        </Grid>
                        <Grid item md={5.8} xs={9} className="state_container">
                          <StateSelect
                            required
                            label="State"
                            name="state"
                            value={this.state.state}
                            disabled={this.state.locked}
                            onChange={(e, { name, value }) => {
                              this.setState({
                                [name]: value,
                              });
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={5.8} />
                        <Grid item md={5.8} xs={9} className="state_container">
                          <Input
                            required
                            label="Zip Code"
                            type="text"
                            name="zip"
                            value={this.state.zipCode}
                            onChange={(e, { name, value }) =>
                              this.setState({
                                ...this.state,
                                zipCode: value,
                              })
                            }
                          />
                        </Grid>

                      </Grid>
                   {/*} </Layout>*/}
                  </>
                )}

                  <Button
                    loading={this.state.loading}
                    className="submit"
                    data-gtm="start-submit"
                    long
                    disabled={
                      !this.state?.dateOfBirth?.valueOf() ||
                      this.state.state === undefined ||
                      this.state.zipCode.length !== 5
                    }
                    nueblue>
                    Continue
                  </Button>
                  <div></div>

              </form>
            </div>
          </SignupStepContainer>
        </Section>
      </div>
    );
  }
}
