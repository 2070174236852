import React from 'react';

import { Layout, Video } from 'components';

const defaultTitle =
  'Nue Life - A mental health wellness company offers online ketamine treatment and psychedelic therapy at home.';

const VideoSection = ({ title = defaultTitle, sectionRef }) => {
  return (
    <>
      <h3
        ref={sectionRef}
        className="title"
        style={{ textAlign: 'left', width: 'auto' }}>
        {title}
      </h3>

      <div className="discover">
        <Layout horizontal stackable>
          <div className="discover__video">
            <Video
              video={{
                poster:
                  'https://embed-fastly.wistia.com/deliveries/94c62e9797d7464aa17f854d978df263b785c7f3.jpg',
                playbackUrl:
                  'https://embed-fastly.wistia.com/deliveries/c2bd4733bff9bb6a43267fd376919c9df36739e6.m3u8',
              }}
            />
          </div>
          <div className="discover__content left-space">
            <div className="text-container">
              <p className="bodytext">
                We’ve created a therapeutic ecosystem of ketamine therapy, an
                interactive companion app, and virtual aftercare programs to
                help you heal your most important relationship:
                <br />
                <span className="italic">the one you have with yourself.</span>
              </p>
              <div className="qoute bodytext">
                “ I had depression, anxiety, and PTSD. My first ketamine
                experience gave me a sense of peace and well-being I had not
                felt in years. ”
                <br />
              </div>
              <br />
              Michael G.
            </div>
          </div>
        </Layout>
      </div>
    </>
  );
};

export default VideoSection;
