import React from "react";
import './styles.less';
import { Button } from "components";
import { StatementBanner } from "../components/StatementBanner";


export default function End({history, prevRoute, nextRoute }) {
    const submit = () => {
        history.push(nextRoute || '/talk-with-us/account');
    }
    
    return (
        <StatementBanner 
            title={'Congratulations! Based on your responses, Nue Life could be a great fit for you.'}
            subtitle={'Let\'s set up your account to book a call with our welcome team!'}
            hideNext
            onBack={() => history.push(prevRoute)}
        >
            <Button
                primary
                onClick={submit}
                className={'schedule_button'}
            >
                Proceed to schedule
            </Button>
        </StatementBanner>
    )
}