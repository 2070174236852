import React from 'react';

import './generated-question.less';
import { Radio } from '../Radio';
import { Textarea } from '../Textarea';

export const GeneratedQuestion = ({
  options = [],
  id,
  value,
  content,
  onChange,
  number,
  multi,
  otherName,
  otherValue,
  onOtherChange,
}) => {
  if (!value) {
    value = multi ? [] : '';
  }

  function handleOnChange(e, { value: inputValue, name, checked }) {
    if (multi) {
      onChange(e, {
        name,
        checked,
        value: checked
          ? [...value, inputValue]
          : [...value].filter((c) => c !== inputValue),
      });
    } else {
      onChange(e, { value: inputValue, name, checked });
    }
  }

  return (
    <div data-test={id} className="generatedQuestion">
      <div className="generatedQuestion__number statement-title">{number}.</div>

      <div className="generatedQuestion__content">
        <div className="generatedQuestion__title bodycopylong">{content}</div>

        {options.map((option) => {
          const optionName = option?.name || option;
          const optionValue = option?.value || option;

          return (
            <Radio
              required
              onChange={handleOnChange}
              checked={
                multi ? value.includes(optionName) : value === optionValue
              }
              data-test={value}
              value={optionValue}
              name={id}
              key={optionName}
              label={optionName}
              type={multi ? 'checkbox' : 'radio'}
            />
          );
        })}

        {otherName && (
          <Textarea
            label="other"
            name={id}
            value={otherValue || ''}
            onChange={onOtherChange}
          />
        )}
      </div>
    </div>
  );
};
