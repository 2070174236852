export const typeToFormErrors = (err) => {
  const errors = {};
  err.inner.forEach((item) => {
    if (!errors[item.path]) {
      errors[item.path] = {
        type: item.message.includes('required') ? 'warning' : 'error',
        message: item.message,
      };
    }
  });
  return errors;
};
