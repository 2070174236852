import React from 'react';
import './section.less';
import classNames from 'classnames';

export const Section = ({
  eggshell,
  sleep,
  changeyellow,
  calmblue,
  wellness,
  warmgrey,
  nueblue,
  style,
  children,
  flat,
  className,
  color,
}) => {
  return (
    <div
      style={style}
      className={classNames('section', className, color, {
        eggshell,
        sleep,
        warmgrey,
        changeyellow,
        calmblue,
        wellness,
        nueblue,
        flat,
      })}>
      {children}
    </div>
  );
};
