import React, { Component } from 'react';
import { screen } from 'helpers';
import {
  Section,
  Stepper,
  Button,
  SignupStepContainer,
  GeneratedQuestion,
} from 'components';

import './questions.less';

import { GlobalContext } from 'context';
import Spacer from 'components/Spacer';

import leadQuestions from 'data/leadQuestions.json';
import { omit } from 'lodash-es';

function getStateFromQuestions({ context }) {
  return {
    questions: leadQuestions.map(({ id, otherName }) => {
      const item = (context.leadQuestions || []).find((c) => c.id === id) || {};
      return {
        id,
        ref: React.createRef(),
        answer: item.answer,
        otherAnswer: item.otherAnswer,
        otherName: otherName,
      };
    }),
  };
}

@screen
export default class Questions extends Component {
  static contextType = GlobalContext;

  state = getStateFromQuestions(this);

  componentDidMount() {
    window.scrollTo({ top: 0 });
    if (!this.context.startInfo?.state) {
      window.location.href = '/';
    }
    window?.gtag('event', 'view_lead_questions');
  }

  setField = (evt, { name, value }) => {
    this.setState({
      questions: this.state.questions.map((question) => {
        return question.id === name ? { ...question, answer: value } : question;
      }),
    });
  };

  setOtherField = (event, { name, value }) => {
    this.setState({
      questions: this.state.questions.map((question) => {
        return question.id === name
          ? { ...question, otherAnswer: value }
          : question;
      }),
    });
  };

  handleOnSubmit = () => {
    if (!this.isComplete(true)) {
      return;
    }

    this.context.setLeadQuestions(
      this.state.questions.map((item) => {
        return omit(item, ['ref']);
      })
    );

    window?.gtag('event', 'lead_questions_completed');
    setTimeout(() => {
      this.props.onDone(!this.context.failedQuestions.length);
    }, 0);
  };

  isComplete = (scroll) => {
    const item = this.state.questions.find(
      ({ answer, otherAnswer }) => !(answer || otherAnswer)
    );

    if (scroll && item && item.ref.current) {
      item.ref.current.scrollIntoView({
        behavior: 'smooth',
      });
      return false;
    }
    return !item;
  };

  render() {
    const { title } = this.props;

    return (
      <div className="stepQuestions">
        <Section color={'changeyellow'} style={{ borderRadius: 0 }}>
          <Stepper
            current={this.props.step - 1}
            steps={this.props.numberOfSteps}
          />
          <SignupStepContainer title={title}>
            <form
              className="stepQuestions__form"
              onSubmit={this.handleOnSubmit}>
              <div className="stepQuestions__content">
                {leadQuestions.map((question, index) => {
                  const { ref, answer, otherAnswer } =
                    this.state.questions[index];
                  return (
                    <div key={question.id} ref={ref}>
                      <GeneratedQuestion
                        number={index + 1}
                        onChange={this.setField}
                        {...question}
                        value={answer}
                        otherValue={otherAnswer}
                        onOtherChange={this.setOtherField}
                      />
                    </div>
                  );
                })}

                <div className="stepQuestions__submit">
                  <div />
                  <Button
                    fluid
                    nueblue
                    disabled={!this.isComplete()}
                    onClick={this.handleOnSubmit}>
                    Continue
                  </Button>
                </div>

                <div className="divider"></div>
                <div className="stepQuestions__notes">
                  <div className="spacer" />
                  <div className="text">
                    <h1 className="h1">
                      Our methods make it simple to personalize treatment for
                      you.
                    </h1>
                    <Spacer size="s" />

                    <ul className="bodycopylong">
                      <li>
                        We offer free discovery calls and walk you through our
                        oral ketamine program, our unique dose discovery
                        process, and how our treatment works.
                      </li>
                      <li>
                        Our expert medical team will answer all of your medical
                        questions to safely and effectively find the right dose
                        for you to create a customized ketamine treatment
                        program.
                      </li>
                      <li>
                        Our unique discovery process finds the most effective
                        dose for you. We're here to support you each step of the
                        way, ensuring you make the most of each experience.
                      </li>
                    </ul>
                  </div>
                </div>

                <Spacer size="s" />
              </div>
            </form>
          </SignupStepContainer>
        </Section>
      </div>
    );
  }
}
