import React from "react";
import './styles.less';
import { Button, IconExternalArrow } from "components";
import { StatementBanner } from "../components/StatementBanner";
import { IOS_APP, ANDROID_APP } from "utils/env";

export default function FreeUserSignUpEnd() {

    const goToApp = () => {
      const loclaUserAgent = navigator.userAgent;
      const isIos = [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform) || (navigator.userAgent.includes("Mac") && "ontouchend" in document);

      if (isIos) {
        window.location.replace(IOS_APP);
      } else {
        window.location.replace(ANDROID_APP);
      }
    }

    return (
        <StatementBanner 
            title={'Congratulations! Your Nue Life Health account is now active.'}
            subtitle={'Welcome to the community of people striving for a healthier, more vibrant life. Explore the app and make the most out of the tools, resources, and the programs we offer.'}
            hideNext
            hideBack
        >
            <div style={{display: "flex", width: "100%", justifyContent: "center", marginTop: "1.5em"}}>
                <Button icon long border as="a" href={"https://web.nue.life/action/forceApp"}>
                    <IconExternalArrow />
                    <span className="text">Go to app</span>
                </Button>
            </div>
        </StatementBanner>
    )
}