export function scrollToTargetAdjusted(element) {
  const headerOffset = 90;
  const elementPosition = element.getBoundingClientRect().top;
  const offsetPosition =
    elementPosition - headerOffset - document.body.getBoundingClientRect().top;

  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth',
  });
}
