import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { screen } from 'helpers';
import {
  Button,
  Layout,
  Section,
  IconArrowLeft,
  SignupStepContainer,
} from 'components';

import states from 'utils/us-states';

import { GlobalContext } from 'context';

import logo from 'assets/logo.svg';
import './thankyou.less';

const options = Object.keys(states).map((value) => {
  return {
    value,
    text: states[value],
  };
});

options.unshift({
  value: '',
  text: 'Select Your State',
});

@screen
export default class TalkWithUs extends Component {
  static contextType = GlobalContext;

  handleOnSubmit = (e) => {
    e.preventDefault();
    this.props.onDone();
  };

  render() {
    return (
      <div className="stepThankYou">
        <div className="header">
          <Layout spread horizontal center>
            <Button pill secondary as={Link} to={`/talk-with-us/questions`}>
              <IconArrowLeft />
            </Button>
            <img src={logo} />
            <div></div> {/* to center logo */}
          </Layout>
        </div>
        <Section color={'changeyellow'} flat>
          <SignupStepContainer title={'thank you for your interest.'}>
            <>
              <p className="bodycopy1">
                Thank you for your interest at this time. Unfortunately, due to
                the following reasons, our programs aren’t a great fit at this
                time. Please feel free to revisit us in the future.
              </p>
              <br />
              <br />
              {this.context.failedQuestions.map((question) => {
                return (
                  <div key={question.id}>
                    <div className="bodycopy1">{question.title}</div>
                    <p className="bodycopy2">{question.response}</p>
                  </div>
                );
              })}
            </>
          </SignupStepContainer>
        </Section>
      </div>
    );
  }
}
