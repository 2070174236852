import React from 'react';
import './radio.less';
import classNames from 'classnames';

export const Radio = ({
  style,
  name,
  label,
  type = 'radio',
  onChange = () => {},
  error,
  className,
  ...props
}) => {
  return (
    <label
      data-id={name}
      className={classNames('radio', className, {
        hasValue: !!props.value,
        error: !!error,
      })}>
      <span className="label">{label}</span>
      <span className="radio__input">
        <input
          type={type}
          name={name}
          onChange={(e) => {
            onChange(e, {
              name: e.target.name,
              value: e.target.value,
              checked: e.target.checked,
            });
          }}
          {...props}
        />
        <span className="control">
          <svg
            width="13"
            height="10"
            viewBox="0 0 13 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.5352 1.75977L5.03516 9.25977C5.03516 9.25977 3.53516 7.25977 2.03516 5.25977"
              stroke="#F3F3F3"
              strokeWidth="2"
              strokeLinecap="square"
              strokeLinejoin="bevel"
            />
          </svg>
        </span>
      </span>
    </label>
  );
};
