import React, { useEffect } from 'react';

import { Page } from './Page';
import './provider.less';

import { Container, TopNavVariant } from 'components';
import VideoSection from '../sections/VideoSection';
import Logo from './header_logo';
import NueLink from './NueLink';

const TemplatePage = ({ provider }) => {
  const afterHeroRef = React.useRef(null);

  const {
    image,
    name,
    address,
    extraAddress,
    bio,
    extraBio,
    map,
    formVariant,
  } = provider;

  if (formVariant) {
    window.localStorage.setItem('form_variant', formVariant);
  }

  return (
    <Page
      navBar={<TopNavVariant LinkComponent={NueLink} ctaLink="/talk-to-us" logo={<Logo />}/>}
      sectionRef={afterHeroRef}
      videoSection={
        <VideoSection
          title="Nue Life - A mental health wellness company offers online ketamine treatment and psychedelic therapy at home."
          sectionRef={afterHeroRef}
        />
      }>
      <Container ref={afterHeroRef} className="provider-info">
        <h2 className="header">Your Provider</h2>
        <div className="two-sections">
          <div className="info-side">
            <div className="network-info">
              {image && <img src={image} />}
              <div className="info">
                <div className="name">{name}</div>
                <div className="address">{address}</div>
                {extraAddress}
              </div>
            </div>
            {extraBio}
            {bio && <p className="bio">{bio}</p>}
          </div>
          {map && (
            <div className="map-container">
              <img src={map} className="map-image" />
            </div>
          )}
        </div>
        <div className="extra-info">
          <p className="description">
            As a member of the Nue Network, your provider has demonstrated a
            commitment to safe and ethical <strong>ketamine therapy</strong> for mental health
            treatment. They receive ongoing education about best practices for
            <strong>ketamine therapy</strong> and receive the administrative and technical
            support that allows them to focus on delivering first-in-class
            patient care.
          </p>
        </div>
      </Container>
    </Page>
  );
};

const DynamicTemplatePage = ({ provider, generateMap }) => {
  const afterHeroRef = React.useRef(null);

  useEffect(() => {
    if (provider && (provider.formVariant || provider.code)) {
      window.localStorage.setItem(
        'form_variant',
        provider.formVariant || provider.code
      );
    }
  }, []);

  if (!provider) {
    return (
      <Page
        navBar={<TopNavVariant LinkComponent={NueLink} ctaLink="/talk-to-us" logo={<Logo />} />}
        sectionRef={afterHeroRef}
        videoSection={
          <VideoSection
            title="Nue Life - A mental health wellness company offers online ketamine treatment and psychedelic therapy at home."
            sectionRef={afterHeroRef}
          />
        }></Page>
    );
  }

  const { logoUrl, name, addresses, providers } = provider;

  return (
    <Page
      navBar={<TopNavVariant LinkComponent={NueLink} ctaLink="/talk-to-us" logo={<Logo />} />}
      banner={provider.code === 'fmpm'}
      sectionRef={afterHeroRef}
      videoSection={
        <VideoSection
          title="Nue Life - A mental health wellness company offers online ketamine treatment and psychedelic therapy at home."
          sectionRef={afterHeroRef}
        />
      }>
      <Container ref={afterHeroRef} className="provider-info">
        <h2 className="header">Your Provider</h2>
        <div className="two-sections">
          <div className="info-side">
            <div className="network-info">
              {logoUrl && <img src={logoUrl} />}
              <div className="info">
                <div className="name">{name}</div>
                {addresses.map((props, i) => (
                  <AddressPortion key={i} {...props} />
                ))}
              </div>
            </div>
            {providers.map(({ fullName, title, bio }, i) => {
              return (
                <>
                  <div className="small-info-block">
                    <div className="lead">Provider Name & Bio:</div>
                    <div className="text">{`${fullName}, ${title}`}</div>
                  </div>
                  {bio && <p className="bio">{bio}</p>}
                </>
              );
            })}
          </div>
          {generateMap && addresses.length > 0 && (
            <div className="map-container">
              <img
                src={generateMap(getAddressString(addresses[0]))}
                className="map-image"
              />
            </div>
          )}
        </div>
        <div className="extra-info">
          <p className="description">
            As a member of the Nue Network, your provider has demonstrated a
            commitment to safe and ethical <strong>ketamine therapy</strong> for mental health
            treatment. They receive ongoing education about best practices for
            <strong>ketamine therapy</strong> and receive the administrative and technical
            support that allows them to focus on delivering first-in-class
            patient care.
          </p>
        </div>
      </Container>
    </Page>
  );
};

const getAddressString = ({ street1, street2, city, state, zipCode }) =>
  `${street1} ${street2}, ${city}, ${state} ${zipCode}`;

const AddressPortion = ({ street1, street2, city, state, zipCode }) => {
  const addressLink = getAddressString({
    street1,
    street2,
    city,
    state,
    zipCode,
  });
  const link = `https://maps.google.com/?q=${encodeURIComponent(addressLink)}`;
  return (
    <p className="address">
      <a target="_blank" href={link}>
        {`${street1} ${street2}`}
        <br />
        {`${city}, ${state} ${zipCode}`}
      </a>
    </p>
  );
};

export { TemplatePage, DynamicTemplatePage };
