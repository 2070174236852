import React from 'react';
import './textarea.less';
import classNames from 'classnames';

export const Textarea = ({
  name,
  label,
  rows = 6,
  value = '',
  onChange = () => {},
}) => {
  return (
    <div className={classNames('textarea', { hasValue: !!value })}>
      {label && <label htmlFor={name}>{label}</label>}
      <textarea
        name={name}
        rows={rows}
        value={value}
        onChange={(e) =>
          onChange(e, { name: e.target.name, value: e.target.value })
        }
      />
    </div>
  );
};
