import React from 'react';
import { Button } from '../Button';
import { Select } from '../Select';
import { IconSelectDown, IconArrowLeft, IconSearch } from '../Icons';
import { Link, NavLink } from 'react-router-dom';
import { JournalTag } from '../JournalTag';
import { useHistory } from 'react-router-dom';
import './styles.less';
import { SearchOverlay } from '../SearchOverlay';

function getCategories(mobile) {
  const categories = sessionStorage.getItem('journal-categories');
  if (categories) {
    try {
      return JSON.parse(categories);
    }
    catch (e) {
      return [];
    }
  }
  return [];
}

export const JournalHeader = ({ noBack, category, isMobile } = {}) => {
  const [categories, setCategories] = React.useState(getCategories(isMobile));

  const history = useHistory();

  async function fetchCategories() {
    const res = await fetch(
      `https://wp-api.nue.life/wp-json/wp/v2/categories?parent=49`
    );
    let data = await res.json();
    if (!isMobile) {
      data = data.filter(function(category) {return category.id !== 105})
    }
    setCategories(data);
    sessionStorage.setItem('journal-categories', JSON.stringify(data));
  }

  React.useEffect(() => {
    fetchCategories();
  }, []);

  const healDifferent = categories.find((c) => c.id == 2);

  return (
    <>
      <div className="journal-header">
        <div className="journal-header__wrapper">
          {noBack !== true && (
            <Button className={'back'} transparent pill as={Link} to={"/journal" + (isMobile? '?mobile-journal=true' : '')}>
              <IconArrowLeft />
            </Button>
          )}
          <span className="journal-header__title">
            <Link to={"/journal" + (isMobile? "?mobile-journal=true" : "")}>{isMobile? "learn" : "journal"}</Link>
          </span>
        </div>

        <div className="journal-header__wrapper">
          <div className="only-desktop">
            <div className="journal-header__categories">
              <NavLink
                to={`/journal` + (isMobile? '?mobile-journal=true' : '')}
                className="journal-header__categories__item">
                All
              </NavLink>
              {healDifferent && <JournalTag {...healDifferent} />}
              {categories
                .filter((c) => c.id !== 2)
                .map((category) => {
                  return (
                    <Link
                      to={`/journal/categories/${category.id}/${category.slug}` + (isMobile? "?mobile-journal=true" : '')}
                      className="journal-header__categories__item"
                      key={category.id}>
                      {category.name}
                    </Link>
                  );
                })}
            </div>
          </div>
          <div className="only-mobile">
            <div className="journal-header__select">
              <Select
                icon={<IconSelectDown />}
                value={category || 'all'}
                options={[
                  {
                    text: 'All categories',
                    value: 'all',
                  },
                  ...categories.map((c) => {
                    return {
                      value: c.id,
                      text: c.name,
                    };
                  }),
                ]}
                onChange={(e, { value }) => {
                  if (value === 'all') {
                    history.push(`/journal`+ (isMobile? "?mobile-journal=true" : ''));
                  } else {
                    const category = categories.find((c) => c.id == value);
                    history.push(
                      `/journal/categories/${category.id}/${category.slug}` + (isMobile? "?mobile-journal=true" : '')
                    );
                  }
                }}
              />
            </div>
          </div>
          <SearchOverlay
            trigger={
              <Button className="journal-header__search">
                <span className="text">SEARCH</span>
                <IconSearch />
              </Button>
            }
          />
        </div>
      </div>
    </>
  );
};
