const {
  API_URL = process.env.REACT_APP_API_URL,
  APP_NAME = process.env.REACT_APP_APP_NAME,
  SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN,
  ENV_NAME = process.env.REACT_APP_ENV_NAME,
  GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY,
  PUBLIC_TOKEN = process.env.REACT_APP_PUBLIC_TOKEN,
  CHILI_PEPER_APP = process.env.REACT_APP_CHILI_PEPER_APP,
  CHILI_PEPER_ROUTER = process.env.REACT_APP_CHILI_PEPER_ROUTER,
  COMPANY_DOMAIN = process.env.REACT_APP_COMPANY_DOMAIN,
  IOS_APP = process.env.REACT_APP_IOS_APP,
  ANDROID_APP = process.env.REACT_APP_ANDROID_APP,
  RECAPTCHA = process.env.REACT_APP_RECAPTCHA,
  WEB_PORTAL = process.env.REACT_APP_WEB_PORTAL,
  APP_URL = process.env.REACT_APP_APP_URL,
  RECAPTCHA_KEY = process.env.RECAPTCHA_KEY
} = window.__ENV__ || {};

export {
  API_URL,
  APP_NAME,
  SENTRY_DSN,
  ENV_NAME,
  GOOGLE_API_KEY,
  PUBLIC_TOKEN,
  CHILI_PEPER_APP,
  CHILI_PEPER_ROUTER,
  COMPANY_DOMAIN,
  APP_URL,
  IOS_APP,
  ANDROID_APP,
  RECAPTCHA,
  WEB_PORTAL,
  RECAPTCHA_KEY
};
