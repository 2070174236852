import React, { useState } from 'react';
import classNames from 'classnames';
import './plan-selected.less';
import { Button } from '../Button';
import { PlanPrice } from '../PlanPrice';
import { IconArrowDown, IconArrowUp } from '../Icons';
import { Link } from 'react-router-dom';

export const PlanSelected = ({
  style,
  className,
  plan,
  accordion,
  buttons = true,
  prices = false,
}) => {
  const { id, name, type, description, features, action } = plan;

  const [open, setOpen] = useState(false);

  return (
    <div
      key={id}
      style={style}
      className={classNames('planSelected', className)}>
      <div className="planSelected__top">
        <div className="planSelected__type subtitle">{type} program</div>
        <div
          className="planSelected__name"
          onClick={() => {
            setOpen(!open);
            return false;
          }}>
          <span className="name">
            nue.<span className="italic">{name.replace('nue.', '')}</span>
          </span>
          {accordion && (
            <span className="details">
              <span className="text">Details</span>
              {open ? <IconArrowUp /> : <IconArrowDown />}
            </span>
          )}
        </div>
      </div>

      {open && (
        <>
          <p className="planSelected__description">{description}</p>

          <ul className="planSelected__features">
            {features.map((feature) => {
              <li key={feature.name} className="plan__feature">
                {feature.name}
              </li>;
            })}
          </ul>
          {prices && <PlanPrice plan={plan} />}
          {buttons && action === 'checkout' && (
            <Button to={`/checkout/${id}`} as={Link}>
              Choose this plan
            </Button>
          )}

          {buttons && action === 'contact' && (
            <Button to="/talk-with-us" as={Link}>
              Contact us to sign up
            </Button>
          )}
        </>
      )}
    </div>
  );
};
