import React from 'react';

import './styles.less';
import { Button } from '../Button';
import readingImage from './images/reading.png';
import { Link } from 'react-router-dom';

const defaultTitle = (
  <>
    Have you made up your mind to <span>change your mind?</span>
  </>
);
const defaultContent = (
  <>
    <span className="bold">Need further advice?</span>
    <br />
    Speak with our Welcome team.
  </>
);

export const BannerCTA = ({
  ctaContent = defaultContent,
  ctaButton = 'Get Started',
  title = defaultTitle,
  bgColor = 'wellness',
  textColor = 'nueblue',
  link = '/talk-to-us',
  image = readingImage,
  children,
}) => {
  const useTitle = !children;

  return (
    <div
      className="bannerCTA"
      style={{
        backgroundColor: `var(--color-${bgColor})`,
        color: `var(--color-${textColor})`,
      }}>
      <img src={image} />
      {useTitle && <h3 className="title">{title}</h3>}
      {children}
      <div className="divider"></div>
      <div className="cta-wrapper">
        <p>{ctaContent}</p>
        <Button
          secondary
          className="cta"
          as={Link}
          onClick={() => {
            window?.gtag('event', 'cta_talk_with_us_clicked');
          }}
          to={link}>
          {ctaButton}
        </Button>
      </div>
    </div>
  );
};
