import React, { useRef } from 'react';
import {
  SnapList,
  SnapItem,
  useVisibleElements,
  useScroll,
} from 'react-snaplist-carousel';
import { Container } from 'components';
import { Feature } from './Feature';
import { Button, IconArrowLeft, IconArrowRight } from 'components';

import cn from './feature.module.less';
import welcomeOfferImg from '../../assets/welcome_offer.png';
import appOfferImg from '../../assets/nue_app_offer.png';
import portalOfferImg from '../../assets/portal_offer_2.png';

import classNames from 'classnames';

const ENTRIES = [
  {
    header: 'for patients',
    image: welcomeOfferImg,
    kicker: undefined,
    items: [
      'Relief from treatment-resistant depression, PTSD & anxiety',
      'Support & guidance through specialist-led integration groups',
      'Individual wellness planning with one-on-one health coaching',
      'Coordinated care via our AI-enhanced companion app.',
    ],
  },
  {
    header: 'for care providers',
    image: portalOfferImg,
    kicker: '',
    items: [
      'Advanced safety protocols for at-home treatment',
      'Comprehensive patient care through multi-modal treatment plans',
      'Easy feedback and facilitation through our HIPAA-compliant app.',
    ],
  },
  {
    header: 'world class digital companion app',
    image: appOfferImg,
    kicker: 'A personalized, confidential patient-centered platform powered by our Patient Graph and AI.',
    items: [
      'A secure, intuitive onboarding experience',
      'Curated playlists for ketamine experiences',
      'Mood tracking and voice notes features',
      'Post-experience feedback capabilities for personalizing treatment plans',
      'Telemedicine access, outcome tracking, and safety and compliance protocols',
    ],
  },
  {
    header: 'leading psychedelic care platform',
    image: portalOfferImg,
    kicker: 'A state-of-art digital platform for enhanced at-home and hybrid oral ketamine patient management.',
    items: [
      'Streamlined comprehensive medical documentation',
      'Secure, HIPAA-compliant messaging',
      'Patient self-scheduling system with calendar integration',
      'E-prescribe and prescription monitoring programs',
    ],
  },
];

const Controls = ({ next, prev, prevDisabled, nextDisabled, className }) => (
  <div className={classNames(cn.controls, className)}>
    <Button disabled={prevDisabled} pill onClick={prevDisabled ? null : prev}>
      <IconArrowLeft />
    </Button>
    <Button disabled={nextDisabled} pill onClick={nextDisabled ? null : next}>
      <IconArrowRight />
    </Button>
  </div>
);

const WhatWeOfferCarousel = () => {
  const snapList = useRef(null);

  const goToSnapItem = useScroll({ ref: snapList });
  const centerEl = useVisibleElements(
    { debounce: 50, ref: snapList },
    (_, center) => center
  );

  return (
    <div className={classNames('features', cn.appFeatures)}>
      <SnapList ref={snapList}>
        {ENTRIES.map((e, i) => (
          <SnapItem key={i}>
            <Feature
              key={i}
              step={i + 1}
              controls={
                <Controls
                  next={() => goToSnapItem(i + 1)}
                  prev={() => goToSnapItem(i - 1)}
                  prevDisabled={i === 0}
                  nextDisabled={ENTRIES.length === i + 1}
                />
              }
              header={e.header}
              image={e.image}
              name={e.kicker}>
              <ul>
                {e.items.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </Feature>
          </SnapItem>
        ))}
      </SnapList>
      <Controls
        className={cn.controlBottom}
        next={() => goToSnapItem(centerEl + 1)}
        prev={() => goToSnapItem(centerEl - 1)}
        prevDisabled={centerEl === 0}
        nextDisabled={ENTRIES.length === centerEl + 1}
      />
    </div>
  );
};

export default WhatWeOfferCarousel;