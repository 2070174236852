import React from 'react';
import './button.less';
import classNames from 'classnames';

export const Button = ({
  style,
  children,
  type,
  icon,
  transparent,
  border,
  disabled,
  secondary,
  as: Component = 'button',
  className,
  eggshell,
  long,
  warmblue,
  wellness,
  nueblue,
  fluid,
  pill,
  compact,
  color,
  loading,
  calmblue,
  magenta,
  ...props
}) => {
  return (
    <Component
      style={style}
      type={type}
      data-test="button"
      disabled={disabled}
      className={classNames('button', className, color, {
        nueblue,
        icon,
        transparent,
        border,
        fluid,
        secondary,
        disabled,
        long,
        pill,
        eggshell,
        wellness,
        warmblue,
        compact,
        calmblue,
        magenta
      })}
      {...props}>
      {loading && (
        <div className="loading">
          <span className="dot" />
          <span className="dot" />
          <span className="dot" />
          <span className="dot" />
          <span className="dot" />
        </div>
      )}
      {!loading && children}
    </Component>
  );
};
