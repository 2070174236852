import React from 'react';
import './plan-price-upgrade.less';

export const PlanPriceVarientForUpgrade = ({ plan }) => {
  let { prices } = plan;

  return (
    <div className="planPriceVariantUpgrade">
      <ul className="planPriceVariant" key={plan.id}>
        {prices.map(({ amount, type }, index) => {
          return (
            <li
              className={`planPriceVariant__item ${type}`}
              key={`${amount}_${type}_${plan.id}`}>
              <div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <span className="planPriceVariant__currency">$</span>
                  <span className="planPriceVariant__amount">{amount}</span>
                </div>
                <span className="planPriceVariant__type">{type}</span>
              </div>
              {index !== prices.length - 1 && (
                <span className="planPriceVariant__divider" />
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
