// react-hot-loader needs to be imported
// before react and react-dom
import 'react-hot-loader';

import 'styles/reset.less';
import 'styles/fonts.less';
import 'styles/atomic.less';
import 'styles/base.less';

import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { GlobalProvider } from 'context';
import App from './App';

if (window.location.port !== '2200') {
  Sentry.init({
    dsn: 'https://841e12e60cc4453aabbb40e925cdbf65@o282569.ingest.sentry.io/6172718',
    environment: window.location.host === 'nue.life' ? 'production' : 'staging',
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

try {
  const searchParams = new URL(document.location).searchParams;
  searchParams.get('utm_source') &&
    window.localStorage.setItem('utm_source', searchParams.get('utm_source'));
  searchParams.get('utm_medium') &&
    window.localStorage.setItem('utm_medium', searchParams.get('utm_medium'));
  searchParams.get('utm_term') &&
    window.localStorage.setItem('utm_term', searchParams.get('utm_term'));
  searchParams.get('utm_content') &&
    window.localStorage.setItem('utm_content', searchParams.get('utm_content'));
  searchParams.get('utm_campaign') &&
    window.localStorage.setItem(
      'utm_campaign',
      searchParams.get('utm_campaign')
    );
  searchParams.get('gclid') &&
    window.localStorage.setItem('gclid', searchParams.get('gclid'));

  if (!window.localStorage.getItem('entry_origin')) {
    window.localStorage.setItem(
      'entry_origin',
      window.location.href.replace(window.location.search, '')
    );
  }

} catch (e) {}

const Wrapper = () => (
  <BrowserRouter>
    <HelmetProvider>
      <GlobalProvider>
        <App />
      </GlobalProvider>
    </HelmetProvider>
  </BrowserRouter>
);

ReactDOM.render(<Wrapper />, document.getElementById('root'));
