import React, { useState, useRef, useEffect } from 'react';

import classNames from 'classnames';

import './birthdate-input.less';

function getState(value) {
  return {
    mm: value ? (value.getMonth() + 1).toString() : '',
    yyyy: value ? value.getFullYear().toString() : '',
    dd: value ? value.getDate().toString() : '',
  };
}

export const BirthDateInput = ({
  name,
  label,
  error,
  disabled,
  onChange = () => {},
  value,
}) => {
  const refmm = useRef();
  const refdd = useRef();
  const refyyyy = useRef();

  const [state, setState] = useState(getState(value));
  const [checked, setChecked] = useState(false);

  const hasValue = state.mm || state.dd || state.yyyy;

  useEffect(() => {
    if (
      state.mm.length === 2 &&
      state.yyyy.length === 4 &&
      state.dd.length === 2 &&
      !checked
    ) {
      onChange(undefined, {
        value: new Date(state.yyyy, state.mm - 1, state.dd),
        name,
      });
      setChecked(true);
    }
  }, [state]);

  useEffect(() => {
    if (value && checked) {
      setState(getState(value));
    }
  }, [value]);

  return (
    <div
      className={classNames('birthdateInput', {
        hasValue: !!hasValue,
        error: !!error,
      })}>
      <div className="birthdateInput__container">
        {label && <label htmlFor={name}>{label}</label>}

        <div className="birthdateInput__inputs">
          <input
            ref={refmm}
            size={2}
            placeholder="mm"
            minLength="2"
            maxLength="2"
            pattern="[0-9]*"
            inputMode="numeric"
            value={state.mm}
            onChange={(e) => {
              const value = e.target.value.replace(/\D/g, '');
              if (value.length === 2) {refdd.current.focus();}
              setChecked(false);
              setState({ ...state, mm: value });
            }}
            disabled={disabled}
          />
          <span className="seperate">-</span>
          <input
            ref={refdd}
            size={2}
            placeholder="dd"
            minLength="2"
            maxLength="2"
            pattern="[0-9]*"
            inputMode="numeric"
            value={state.dd}
            disabled={disabled}
            onChange={(e) => {
              const value = e.target.value.replace(/\D/g, '');
              if (value.length === 2) {refyyyy.current.focus();}
              setChecked(false);
              setState({ ...state, dd: value });
            }}
          />
          <span className="seperate">-</span>
          <input
            ref={refyyyy}
            size={4}
            value={state.yyyy}
            placeholder="yyyy"
            minLength="4"
            maxLength="4"
            min="1900"
            pattern="[0-9]*"
            disabled={disabled}
            inputMode="numeric"
            onChange={(e) => {
              let value = e.target.value.replace(/\D/g, '');
              if (e.target.value.length === 4 && Number(value) < 1900) {
                value = '1900';
              }
              setChecked(false);
              setState({ ...state, yyyy: value });
            }}
          />
        </div>
      </div>
    </div>
  );
};
