import React from 'react';
import { startCase } from 'lodash';
import { Helmet } from 'react-helmet-async';
import bem from 'helpers/bem';

const layouts = {};

export function useLayout(Component, name) {
  layouts[name] = Component;
}

const defaultDescription =
  'We’re a next-gen mental wellness company that believes in the power of psychedelics to catalyze lasting change. Now offering online ketamine treatments!';

export default function (Component) {
  const screenTitle = startCase(Component.name.replace(/Screen$/, ''));

  Component = bem(Component);

  return class Screen extends React.PureComponent {
    render() {
      const Layout = layouts[Component.layout] || nullLayout;

      const { title: metatitle, description: metadescription } =
        this.props.meta || {};
      const title = metatitle || Component.title || screenTitle;

      const description =
        metadescription || Component.description || defaultDescription;

      const url = `https://${location.hostname}${location.pathname}`;

      return (
        <React.Fragment>
          <Helmet>
            <title>{title}</title>
            <link rel="canonical" href={url} />;
            <meta name="title" content={title} />
            <meta name="description" content={description} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={url} />
            <meta
              property="og:title"
              content="Nue Life - It’s your right to feel good."
            />
            <meta property="og:description" content={description} />
            <meta property="og:image" content="/assets/landing-hero-og.jpg" />
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content={url} />
            <meta property="twitter:title" content={title} />
            <meta property="twitter:description" content={description} />
            <meta
              property="twitter:image"
              content="/assets/landing-hero-og.jpg"
            />
          </Helmet>
          <Layout>
            <Component {...this.props} />
          </Layout>
        </React.Fragment>
      );
    }
  };
}

function nullLayout(props) {
  return props.children;
}
