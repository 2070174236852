import React, { Component } from 'react';
import './main_page.less';
import { screen } from 'helpers';
import classNames from 'classnames';
import {
  Container,
  Section,
  Footer,
  TopNavVariant,
  NewsEntries,
  BannerCTA,
  Button,
  IconArrow,
  Carousel,
  IconExternalArrow,
} from 'components';

import { Link } from 'react-router-dom';

import { GlobalContext } from 'context';
import Logo from './components/header_logo';
import statsImg from './assets/stats.svg';
import OfferSection from './sections/OfferSection';
import TeamSection from './sections/TeamSection';
import NueLink from './components/NueLink';
import hero from './assets/hero.png';
import refer from './assets/refer.jpg';
import safety from './assets/safety.png';
import quote from './assets/quote.png'
import { Step } from './sections/Step';
import Modal from 'components/Modal';
import WhatWeOfferCarousel from "./sections/whatWeOffer/WhatWeOfferCarousel";

@screen
export default class ProvidersMainPage extends Component {
  static contextType = GlobalContext;
  static title = 'The Nue Network | Powered by Nue Life';

  state = {
    isJotformModalOpen: false,
  };

  componentDidMount() {
    window.scrollTo({ top: 0 });
    this.initializeJotformIframe();
  }

  initializeJotformIframe() {
    var ifr = document.getElementById("JotFormIFrame-231626053493152");
    if (ifr) {
      var src = ifr.src;
      var iframeParams = [];
      if (window.location.href && window.location.href.indexOf("?") > -1) {
        iframeParams = iframeParams.concat(window.location.href.substr(window.location.href.indexOf("?") + 1).split('&'));
      }
      if (src && src.indexOf("?") > -1) {
        iframeParams = iframeParams.concat(src.substr(src.indexOf("?") + 1).split("&"));
        src = src.substr(0, src.indexOf("?"))
      }
      iframeParams.push("isIframeEmbed=1");
      ifr.src = src + "?" + iframeParams.join('&');
    }

    window.handleIFrameMessage = function(e) {
      if (typeof e.data === 'object') { return; }
      var args = e.data.split(":");
      if (args.length > 2) { iframe = document.getElementById("JotFormIFrame-" + args[(args.length - 1)]); } else { iframe = document.getElementById("JotFormIFrame"); }
      if (!iframe) { return; }
      switch (args[0]) {
        case "scrollIntoView":
          iframe.scrollIntoView();
          break;
        case "setHeight":
          iframe.style.height = args[1] + "px";
          if (!isNaN(args[1]) && parseInt(iframe.style.minHeight) > parseInt(args[1])) {
            iframe.style.minHeight = args[1] + "px";
          }
          break;
        case "collapseErrorPage":
          if (iframe.clientHeight > window.innerHeight) {
            iframe.style.height = window.innerHeight + "px";
          }
          break;
        case "reloadPage":
          window.location.reload();
          break;
        case "loadScript":
          if( !window.isPermitted(e.origin, ['jotform.com', 'jotform.pro']) ) { break; }
          var src = args[1];
          if (args.length > 3) {
              src = args[1] + ':' + args[2];
          }
          var script = document.createElement('script');
          script.src = src;
          script.type = 'text/javascript';
          document.body.appendChild(script);
          break;
        case "exitFullscreen":
          if      (window.document.exitFullscreen)        window.document.exitFullscreen();
          else if (window.document.mozCancelFullScreen)   window.document.mozCancelFullScreen();
          else if (window.document.mozCancelFullscreen)   window.document.mozCancelFullScreen();
          else if (window.document.webkitExitFullscreen)  window.document.webkitExitFullscreen();
          else if (window.document.msExitFullscreen)      window.document.msExitFullscreen();
          break;
      }
      var isJotForm = (e.origin.indexOf("jotform") > -1) ? true : false;
      if(isJotForm && "contentWindow" in iframe && "postMessage" in iframe.contentWindow) {
        var urls = {"docurl":encodeURIComponent(document.URL),"referrer":encodeURIComponent(document.referrer)};
        iframe.contentWindow.postMessage(JSON.stringify({"type":"urls","value":urls}), "*");
      }
    };

    window.isPermitted = function(originUrl, whitelisted_domains) {
      var url = document.createElement('a');
      url.href = originUrl;
      var hostname = url.hostname;
      var result = false;
      if( typeof hostname !== 'undefined' ) {
        whitelisted_domains.forEach(function(element) {
            if( hostname.slice((-1 * element.length - 1)) === '.'.concat(element) ||  hostname === element ) {
                result = true;
            }
        });
        return result;
      }
    };

    if (window.addEventListener) {
      window.addEventListener("message", handleIFrameMessage, false);
    } else if (window.attachEvent) {
      window.attachEvent("onmessage", handleIFrameMessage);
    }
  }

  render() {
    return (
      <div className="main-page-network">
        <div className="hero_background" />

        <TopNavVariant
          ctaLink="/contact"
          useLink
          LinkComponent={NueLink}
          logo={<Logo />}
          showNueLifeButtons={false}
          showNetworkButtons={true}
          hasLightBg={true}
          enableModal={()=>this.setState({ isJotformModalOpen: true })}
        />

        <Container className='firstContainer'>
          <div className="container_on_hero">
          <div className="hero">
            <h2>
              <div className='translated'>
                Join the First Network of Psychedelic Practitioners
              </div>
            </h2>
            <Button primary long as={Link} to={`/contact`}>
              I want to Prescribe
            </Button>
          </div>
          <div className="hero">
            <img className="img1" src={hero}/>
          </div>

          </div>
        </Container>

          <Section eggshell>
          <div className="carouselStepper">
            <div className='carouselTitle'>
              <h3>Why Join Nue Network?</h3>
            </div>
            <div className="steps">
            <div className="container">
                    <div className='step-landing'>
                      <p className='step-title'>Psychedelic Entrepreneurship</p>
                    <p className='inner-step'>Create your own Psychedelic Medicine Practice</p>
                    </div>
                    <div className='step-landing'>
                    <p className='step-title'>Network Referrals</p>
                    <p className='inner-step'>Receive Referrals from our network of patients</p>
                     </div>
                     <div className='step-landing'>
                     <p className='step-title'>Ketamine Training</p>
                     <p className='inner-step'>Learn how to screen, dose, and safely manage a ketamine experience</p>
                     </div>
                     <div className='step-landing'>
                     <p className='step-title'>Access Care Services</p>
                     <p className='inner-step'>Patients receive care during ketamine, therapy, HC, integration</p>
                     </div>
                     </div>
            </div>
            {/*<div className="steps">
                      <div className="container">
                          <Step color="wellness" title="Psychedelic Entrepreneurship">
                            <span className='inner-step'>Create your own Psychedelic Medicine Practice</span>
                          </Step>
                          <Step color="wellness" dark title="Network Referrals">
                          <span className='inner-step'>Receive Referrals from our network of patients</span>
                          </Step>
                          <Step color="wellness" title="Ketamine Training">
                          <span className='inner-step'>Learn how to screen, dose, and safely manage a ketamine experience</span>
                          </Step>
                          <Step color="wellness" title="Access Care Services">
                          <span className='inner-step'>Patients receive care during ketamine, therapy, HC, integration</span>
                          </Step>
                      </div>
                    </div>
                    */}
                    </div>


{/*
 // REMOVED CARROUSEL, ADDED SIMPLE CARDS
        <Section sleep>
          <div className="carouselStepper">
            <div className='carouselTitle'>
              <h3>Why Join Nue Network?</h3>
            </div>
            <div className='steps'>
            <div className="container">
              <Step
                color="wellness"
                title={""}
                >
                <p>Create your own Psychedelic Medicine Practice</p>
              </Step>
              <Step
                color="wellness"
                title={""}
                >
                <p>Receive Referrals from our network of patients</p>
              </Step>
              <Step
                color="wellness"
                title={""}
                >
                <p>Training<br/>Learn how to screen, dose, and safely manage a ketamine experience</p>
              </Step>
              <Step
                color="wellness"
                title={""}
                >
                <p>Access to Virtual Care<br/>Patients receive care during ketamine, therapy, HC, integration</p>
              </Step>
</div></div>
          </div>
        </Section>
    */}


          <div className="classResults" style={{padding: '3em'}}>
            <div className="classResultsTop">
              Be a Part of a Proven Network
            </div>
            <div className="classResultsBottom">
              <div>
                <div className="classResultsNumber">10k +</div>
                <div className="classResultsText">
                  Patients have gone through our at-home ketamine programs
                </div>
              </div>
              <div>
                <div className="classResultsNumber">80k +</div>
                <div className="classResultsText">
                  At-home experiences have been conducted through the Nue Life program
                </div>
              </div>
              <div>
                <div className="classResultsNumber">65%</div>
                <div className="classResultsText">
                  of our Treatment Resistant Patients have shown a 50% reduction in symptoms of anxiety and depression.<br/>
                  <div className='link_wrapper'><a
                    rel="noreferrer"
                    target="_blank"
                    href="https://nue.life/journal/nue-life-publishes-first-peer-reviewed-study-in-frontiers-in-psychiatry"
                    className="link">
                    Peer-reviewed Study <IconExternalArrow />
                  </a></div>
                </div>
              </div>
            </div>
            <Button primary long as={Link} to={`/contact`}>
              I want to Prescribe
            </Button>
          </div>
        </Section>



        <Container>
        <div className="successTestimonials">
            <div className="testimonialsTitle">
              Provider testimonials
            </div>
            <div className="testimonialsContainer">
              <div className="testimonialsInd">
                <img src={quote}/>
                <div className="testimonialsText">
                  "Partnering with Nue Life is a pleasant experience.
                  Your questions will be answered in a timely manner and they truly go above and beyond to
                  help you address any problems that may arise. Highly recommended."
                </div>
              </div>
              <div className="testimonialsInd">
                <img src={quote}/>
                <div className="testimonialsText">
                  "I partnered with Nue Life to offer my patients an alternative, holistic pathway to healing.
                  Too often our chronic diseases, specifically involving mental health, can be treated with a
                  different approach than conventionally used. Open minded and compassionate treatments are so
                  important and I believe Nue Life is empowers me to offer this to my patients."
                </div>
              </div>
            </div>
          </div>
          </Container>


        <Section eggshell >
        <div className="hero_background_2"/>
        <Container>

          <div className="container_on_hero">
            <div className="hero" >
              <h2>Refer a Patient to Nue Life</h2>
              <div className='referSubtitle'>The safe, effective, and legal ketamine program you and your patients can trust</div>
              <Button primary long onClick={() => this.setState({ isJotformModalOpen: true })}>
                I want to refer a patient
              </Button>
              <Modal
                isOpen={this.state.isJotformModalOpen}
                handleClose={() => this.setState({ isJotformModalOpen: false })}
                buttonClose={false}
                border={false}
              >
                <iframe
                  width={800}
                  height={600}
                  id="JotFormIFrame-231626053493152"
                  title="Nue Life Health Patient Referral Form"
                  onLoad={() => window.parent.scrollTo(0,0)}
                  allowTransparency={true}
                  allowFullScreen={true}
                  allow="geolocation; microphone; camera"
                  src="https://form.jotform.com/231626053493152"
                  frameBorder="0"
                  style={{minWidth: "100%", maxWidth: "100%", height: 539, border: "none"}}
                  scrolling="yes"
                />
              </Modal>
            </div>
            <div className="hero">
              <img className="img2" src={refer}/>
            </div>
          </div>
        </Container>

        </Section>

        <Container>
          <div className="carouselStepper">
          <div className='carouselTitle'>
              <h3>Nue Life Program {'>'} Ketamine</h3>
              <h4>Our program includes non-clinical high touch support for every single patient</h4>
              </div>
                <div className="steps">
                  <div className="container">
                    <div className='step-landing'>
                      <p className='inner-step no-title' >One-on-One Health Coaching</p>
                    </div>
                    <div className='step-landing'>
                      <p className='inner-step no-title' style={{textAlign: 'center'}}>Unlimited Preparation and Integration Support</p>
                     </div>
                     <div className='step-landing'>
                       <p className='inner-step no-title' style={{textAlign: 'center'}}>Daily mental health tracking of mood and clinical outcomes</p>
                     </div>
                   </div>
                </div>
              </div>
        </Container>
        {/* HIDDEN CAROUSEL AGAIN
        <Section eggshell>
          <div className='carouselStepper'>
            <div className='carouselTitle'>
              <h3>Nue Life Program {'>'} Ketamine</h3>
              <h4>Our program includes non-clinical high touch support for every single patient</h4>
            </div>

          <Carousel controls={false}>
              <Step
                color="prosperity"
                title={""}
                >
                <p>One-on-One Health Coaching</p>
              </Step>
              <Step
                color="change"
                title={""}
                >
                <p>Unlimited Preparation and Integration Support</p>
              </Step>
              <Step
                color="love"
                title={""}
                >
                <p>Daily mental health tracking of mood and clinical outcomes</p>
              </Step>
          </Carousel>
          </div>

  </Section>*/}

        <Section eggshell >
        <div className="successTestimonials">
            <div className="testimonialsTitle">
              Patient testimonials
            </div>
            <div className="testimonialsContainer">
              <div className="testimonialsInd">
                <img src={quote}/>
                <div className="testimonialsText">
                “One reason I want to do this program is that it feels personal - not manufactured or synthetic.
                It doesn't seem cookie cutter, conveyor belt - there's a genuine aspect to this. You don't find that elsewhere,
                even in similar spaces, which is why I want to move forward here.”
                </div>
              </div>
              <div className="testimonialsInd">
              <img src={quote}/>
                <div className="testimonialsText">
                “Life. Changing. I started my program 6 weeks ago feeling depressed and broken. I was holding onto and
                ruminating in my depression and past traumas. The staff have been incredible, the app is supportive, and
                the benefits are mind blowing. In the short 6 weeks I have a confidence I never knew I had.
                My depression is almost non-existent and my anxiety is nothing. I look in the mirror and
                feel love for myself for the first time in a long time.”
                </div>
              </div>
              <div className="testimonialsInd">
              <img src={quote}/>
                <div className="testimonialsText">
                “This is a very valuable asset to providers and patients. I speak from lived experience.
                I’m a veteran with PTSD and also in recovery from a lifetime of substance use disorder.
                I reached a place with traditional antidepressants where I felt trapped. Today I am in the best space of my life.
                I am nearing the end of my treatment with Nue Life Health but my path to wellness has been launched
                into a whole new level.”
                </div>
              </div>
            </div>
          </div>
        </Section>




        <Container>
          <div className='safety__desktop'>
            <div className="safetyTitle">Safety is our #1 Priority</div>
            <div className="safetySubTitle">We have the safest and most strict protocols of anyone in the industry</div>
          </div>
        <div className="hero_background_3"/>
        <Container>
          <div className="container_on_hero">
            <div className="hero">
              <img className="img3" src={safety}/>
            </div>
            <div className="hero">
            <div className='safety__mobile'>
              <div className="safetyTitle">Safety is our #1 Priority</div>
              <div className="safetySubTitle">We have the safest and most strict protocols of anyone in the industry</div>

            <div className="safetyItems">
                  <p>&#x2713; We never ship all of the doses at one time</p><br/>
                  <p>&#x2713; All patients are required to have a trusted friend or family member with them during their experience</p><br/>
                  <p>&#x2713; Through the Nue Life app, patients must take a photo of themselves with their sitter along with their medication prior to their experience</p><br/>
                  <p>&#x2713; If patients are not following protocol, Nue Life will delay their next shipment until they agree to comply</p><br/>
              </div>
              </div>
            </div>
          </div>
          {/*<div className="safety">
            <div className="safetyTitle">Safety is our #1 Priority</div>
            <div className="safetySubTitle">We have the safest and most strict protocols of anyone in the industry</div>
            <div className="container_on_hero">
              <div className="hero" style={{alignItems:'baseline'}}>
                <div className="safetyItems">
                  <p>{'>'} We never ship all of the doses at one time</p><br/>
                  <p>{'>'} All patients are required to have a trusted friend or family member with them during their experience</p><br/>
                  <p>{'>'} Through the Nue Life app, patients must take a photo of themselves with their sitter along with their medication prior to their experience</p><br/>
                  <p>{'>'} If patients are not following protocol, Nue Life will delay their next shipment until they agree to comply</p><br/>
              </div>
              <div className="hero">
                <img className="img3" src={safety}/>
              </div>
            </div>
            </div>
    </div>*/}
        </Container>
        </Container>
          <Section className="offerSection" style={{backgroundColor: "white"}} wellness >
            <WhatWeOfferCarousel />
          </Section>
          <div className="teamSection">
            <TeamSection />
          </div>
          <Footer LinkComponent={NueLink} />
      </div>
    );
  }
}

