import React, { useState, useEffect, useRef } from 'react';

import './styles.less';

import { request } from 'utils/api';
import { PUBLIC_TOKEN } from 'utils/env';
import { IconArrowDown, IconArrowUp } from 'components/Icons';
import { Loader } from 'components/Loader';
import { Button } from 'components/Button';
import classNames from 'classnames';
import { scrollToTargetAdjusted } from 'utils/scroll';

const formatter = new Intl.DateTimeFormat('en-US', { dateStyle: 'full' });

import { motion, AnimatePresence } from 'framer-motion';

export const CalendarDay = ({ date, queueId, onOpen, open, bookSlot }) => {
  const [loading, setLoading] = useState(false);
  const [slots, setSlots] = useState([]);
  const [selected, setSelected] = useState(false);
  const buttonRef = useRef(null);

  async function getSlots() {
    setLoading(true);

    const { content } = await request({
      method: 'GET',
      path: `/v1/scheduler/availability/queue/detail/${queueId}`,
      params: {
        date,
      },
      headers: {
        Authorization: `Basic ${PUBLIC_TOKEN}`,
      },
    });

    setSlots(content);
    setLoading(false);
  }

  useEffect(() => {
    if (open) {
      getSlots();
    }
    setSelected(false);
  }, [open]);

  const offset = new Date().getTimezoneOffset() * 60000;

  return (
    <div className="calendarDay">
      <AnimatePresence>
        <div className="calendarDay__top" onClick={() => onOpen(!open)}>
          <div className="date">
            {formatter.format(new Date(new Date(date).valueOf() + offset))}
          </div>
          <div className="arrow">
            {!open && <IconArrowDown />}
            {open && <IconArrowUp />}
          </div>
        </div>

        {open && (
          <motion.div
            key={date}
            initial={{ opacity: 0, height: 0 }}
            animate={{
              opacity: 1,
              height: 'auto',
              transition: {
                duration: 0.07,
              },
            }}
            exit={{ opacity: 0, height: 0 }}>
            <div
              ref={buttonRef}
              className={classNames('calendarDay__slots', { loading })}>
              {loading && <Loader />}
              {!loading &&
                slots.map((slot) => {
                  const date = new Date(slot.utcDateTime);

                  return (
                    <div
                      key={slot.utcDateTime}
                      className={classNames('calendarDay__slot', {
                        selected: slot === selected,
                      })}
                      onClick={(e) => {
                        setSelected(slot);
                        scrollToTargetAdjusted(buttonRef.current);
                      }}>
                      {date.toLocaleTimeString('en-US', { timeStyle: 'short' })}
                    </div>
                  );
                })}
            </div>

            <Button onClick={() => bookSlot(selected)} disabled={!selected}>
              Schedule Call
            </Button>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
