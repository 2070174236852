import React, { useState, useEffect, useContext } from "react";
import { StatementBanner } from "../components/StatementBanner";
import leadQuestions from '../data/leadQuestions.json';
import Question from "./Question";
import { GlobalContext } from 'context';

export default function Questionnaire({history, prevRoute, scheduleRoute, congratRoute}) {
    const [questions, setQuestions] = useState(leadQuestions);
    const [answers, setAnswers] = useState([]);
    const [currentQuestion, setCurrentQuestion] = useState({});
    const [questionIndex, setQuestionIndex] = useState(0);
    const context = useContext(GlobalContext);

    useEffect(() => {
        
        if (context.leadQuestions === undefined || context.leadQuestions === null || context.leadQuestions.length === 0) {
            setCurrentQuestion(questions[0]);
        } else {
            let question = questions.find(q => q.id === context.leadQuestions[context.leadQuestions.length - 1].id);
            setCurrentQuestion(question);
        }
        setAnswers(context.leadQuestions || []);
    }, []);

    useEffect(() => {
        if (currentQuestion !== undefined && currentQuestion !== null) {
            setQuestionIndex(questions.indexOf(currentQuestion));
        }
    }, [currentQuestion]);
    
    const nextStep = () => {
        if (questions.indexOf(currentQuestion) < questions.length - 1) {
            setCurrentQuestion(questions[questions.indexOf(currentQuestion) + 1]);
        }
        if (questions.indexOf(currentQuestion) === questions.length - 1) { //if last question
            if (window.sessionStorage.getItem('flow')?.includes('checkout') && currentQuestion.checkoutIfPositive) { //if flow is checkout and question is checkoutIfPositive
                let lastAnswer = answers.find(a => a.id === currentQuestion.id) || {};
                if (lastAnswer.answer && currentQuestion.negativeValues.some(n => lastAnswer.answer.includes(n))) { //if red flags, go to schedule call
                    history.push(scheduleRoute);
                } else {
                    history.push(congratRoute); //if no red flags, go to checkout
                }
            } else {
                history.push(scheduleRoute);
            }
        }
    }
    
    const previousStep = () => {
        if (questions.indexOf(currentQuestion) > 0) {
            setCurrentQuestion(questions[questions.indexOf(currentQuestion) - 1]);
        }
        if (questions.indexOf(currentQuestion) === 0) {
            history.push(prevRoute);
        }
    }
    
    return (
        (currentQuestion.type === "banner") ? (
            <StatementBanner
                title={currentQuestion.content}
                subtitle={currentQuestion.subtitle !== undefined ? currentQuestion.subtitle : ''}
                onBack={previousStep}
                onNext={nextStep}
            />
        ) : (
            <Question
                question={currentQuestion}
                answers={answers}
                setAnswers={setAnswers}
                onBack={previousStep}
                onNext={nextStep}
                questionIndex={questionIndex}
            />
        )
    );
}