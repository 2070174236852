import React from 'react';
import classNames from 'classnames';
import './stepper.less';

export const Stepper = ({ steps, current }) => {
  const stepsArray = [...new Array(steps)];
  return (
    <div className="stepper">
      {stepsArray.map((_, index) => (
        <div
          key={index}
          className={classNames('stepper__item', {
            done: current >= index,
          })}>
          <div className="stepper__dot"></div>
          {index !== stepsArray.length - 1 && (
            <div className="stepper__line"></div>
          )}
        </div>
      ))}
    </div>
  );
};
