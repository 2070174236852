import React, { Component } from "react"
import './styles.less';
import { Grid } from '@mui/material';
import { screen } from 'helpers';


@screen
export default class ThankYou extends Component {
    render() {
        return (
            <div className='blue_background'>
                <div className='title_container'>
                    <Grid container>
                        <Grid item xs={12} flexDirection={"column"}>
                            <h2 className="label white thanks_title">
                                Thank you for your interest in Nue Life.
                            </h2>
                            <p className="thanks_bodycopy white">
                                We'll be in touch as soon as we're available in your state.
                            </p>
                            <h2 className="label white thanks_title">In the meantime...</h2>
                            <p className="thanks_bodycopy white">
                                Read our most <a href="/faq">frequently asked questions</a> to
                                learn more about the journey.
                                <br />
                                <br />
                                Learn more about <strong>ketamine therapy</strong> ketamine therapy and mental health from our 
                                <a href="/journal/search?query=ketamine">Journal</a> posts.
                                <br />
                                <br />
                                Visit our <a href="/company">Company</a> page to learn more about
                                Nue Life and our team of experienced clinicians.
                            </p>
                        </Grid>
                    </Grid>
                </div>
            </div>
        )
    }
}