import React from 'react';

import { Helmet } from 'react-helmet-async';
import { Button, Container } from 'components';
import './styles.less';

export default class PageTest extends React.Component {
  render() {
    return (
      <div className="page-test">
        <div className="hero-background" />
        <Helmet>
          <title>Test Page</title>
          <meta name="prerender-status-code" content="404" />
          <meta name="robots" content="noindex" />
        </Helmet>

        <Container>
          <div className="hero">
            <h2>Test Page.</h2>
            <br />
            <p>
              form_variant:{' '}
              <code data-id="form_variant">
                {window.localStorage.getItem('form_variant') || 'NOT SET'}
              </code>
            </p>
            <p dat>
              utm_source:{' '}
              <code data-id="utm_source">
                {window.localStorage.getItem('utm_source') || 'NOT SET'}
              </code>
            </p>
            <p>
              utm_medium:{' '}
              <code data-id="utm_medium">
                {window.localStorage.getItem('utm_medium') || 'NOT SET'}
              </code>
            </p>
            <p>
              utm_term:{' '}
              <code data-id="utm_term">
                {window.localStorage.getItem('utm_term') || 'NOT SET'}
              </code>
            </p>
            <p>
              utm_campaign:{' '}
              <code data-id="utm_campaign">
                {window.localStorage.getItem('utm_campaign') || 'NOT SET'}
              </code>
            </p>
            <p>
              gclid:{' '}
              <code data-id="gclid">
                {window.localStorage.getItem('gclid') || 'NOT SET'}
              </code>
            </p>
            <p>
              entry_origin:{' '}
              <code data-id="gclid">
                {window.localStorage.getItem('entry_origin') || 'NOT SET'}
              </code>
            </p>
            <br />
            <br />
            <Button
              onClick={() => {
                window.localStorage.clear();
                window.sessionStorage.clear();
              }}>
              Clear storage
            </Button>
          </div>
        </Container>
      </div>
    );
  }
}
