import React, { Component } from "react";
import { screen } from "helpers";
import './styles.less';
import { AssementFooter } from "../components/AssessmentFooter";
import { Grid, TextField } from "@mui/material";
import * as yup from 'yup';
import { typeToFormErrors } from 'utils/validate';
import { GlobalContext } from "context";
import { Input } from "components";
import { ProgressBar } from 'components/ProgressBar';

function getState( { context }) {
    const { startInfo = {}, account = {} } = context;
    const state = {
        email: account.email || '',
        loading: false,
        fieldsErrors: {}
    };
    return state;
}

const schema = yup.object().shape({
    email: yup.string().email().required('Email is Required'),
});

@screen
export default class YourEmail extends Component {
    static contextType = GlobalContext;
    state = getState(this);

    componentDidMount() {
        this.context.setLeadQuestions([]);
    }

    done = async () => {
        try {
            await schema.validate(this.state, { abortEarly: false });
        } catch (e) {
            this.setState({
                fieldsErrors: typeToFormErrors(e),
            });
            return;
        }

        this.context.setAccount({...this.context.account, email: this.state.email});

        this.props.history.push(this.props.nextRoute)
    }
    back = () => { this.props.history.push(this.props.prevRoute) }

    render() {
        return (
        <div>
            {/*<ProgressBar
                questionIndex={3}
        />*/}
            <div className='background'>
                <div className='title_container'>
                    <h2 className='label start_title'>
                        {'Where can we send our welcome email and information about the Nue Life program?'}
                    </h2>
                    <Grid container sx={{marginTop: "2em"}}>
                        <Grid item xs={0} md={3}/>
                        <Grid item xs={12} md={6}>
                            <Input
                                value={this.state.email}
                                onChange={(e) => {
                                    this.setState({
                                        ...this.state,
                                        email: e.target.value
                                    });
                                }}
                                label='Email Address'
                                error={this.state.fieldsErrors.email}
                            />
                        </Grid>
                        <Grid item xs={0} md={3}/>
                    </Grid>
                </div>
                <AssementFooter
                    onBack={this.back}
                    onNext={this.done}
                    secondary
                />
            </div>
        </div>
        );
    }
}