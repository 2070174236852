import React, { useEffect, useState } from 'react';

import { request } from 'utils/api';
import ipsLogo from './assets/ips_logo.jpg';
import fmpmLogo from './assets/fmpm_logo.png';

import { TemplatePage, DynamicTemplatePage } from './components/template';
import { GlobalContext } from 'context';

function getMapUrl(address) {
  return `https://maps.googleapis.com/maps/api/staticmap?center=${encodeURIComponent(
    address
  )}&zoom=17&size=472x448&key=AIzaSyDsP7zmQU0iJQvoQNzqWyzhK_DmKn93Pjk&markers=${encodeURIComponent(
    address
  )}&scale=2`;
}

const ips = {
  image: ipsLogo,
  name: 'Integrative Psychiatric Services',
  address: (
    <a href="mailto:karla@integrativepsychiatric.com">
      karla@integrativepsychiatric.com
    </a>
  ),
  extraAddress: (
    <>
      <p className="address">
        <a target="_blank" href="https://goo.gl/maps/SD6qWLkbp9JuvfXe9">
          64 E Broadway ste 200 <br />
          Tempe, AZ 85282
        </a>
      </p>
      <p className="address">
        <a target="_blank" href="https://goo.gl/maps/R7CUnYmTj193gjuU6">
          1250 E Baseline ste 103
          <br />
          Tempe, AZ 85283
        </a>
      </p>
    </>
  ),
  bio: null,
  extraBio: (
    <>
      <div className="small-info-block">
        <div className="lead">Provider(s) Name & Bio:</div>
        <div className="text">Karla Lamley FNPC, PMHNP</div>
      </div>
      <div className="small-info-block">
        <div className="lead">Support Staff Name, Title, and Bio:</div>
        <div className="text">Katia Aguirre - Office Manager</div>
      </div>
    </>
  ),
  map: getMapUrl('64 E Broadway ste 200, Tempe, AZ 85282'),
  formVariant: 'ips',
};

const IPSProvider = () => <TemplatePage provider={ips} />;

const hadas = {
  name: 'Hadas Maimon, MD',
  bio: 'Dr. Maimon is a Board Certified Psychiatrist. She completed her undergraduate studies at Florida State University and obtained her MD from Morehouse School of Medicine in Atlanta, Georgia. She completed her Psychiatric residency at Mount Sinai Beth Israel Medical Center in New York City where she served as Chief Resident in the outpatient clinic. Dr Maimon is highly dedicated to improving access to and quality of mental health care. She has experience treating individuals of all ages, backgrounds and diagnoses. She will work in partnership with you to create an individualized treatment plan focused on improving your symptoms and daily life. Dr. Maimon resides in south Florida, she enjoys spending time at the beach, visiting art museums, and knitting.',
  address: <a href="mailto:maimon.hadas@gmail.com">maimon.hadas@gmail.com</a>,
  formVariant: 'drmaimon',
  map: getMapUrl('64 E Broadway ste 200, Tempe, AZ 85282'),
  extraAddress: (
    <>
      <p className="address">
        <a target="_blank" href="https://goo.gl/maps/bwXgSf6GhYcGJZzz6">
          88 Lindsay Lane Unit C,
          <br />
          St. Marys, GA 31558
        </a>
      </p>
    </>
  ),
  extraBio: (
    <>
      <div className="small-info-block">
        <div className="lead">Provider(s) Name & Bio:</div>
        <div className="text">Hadas Maimon, MD</div>
      </div>
    </>
  ),
};

const HadasProvider = () => <TemplatePage provider={hadas} />;

const fmpm = {
  image: fmpmLogo,
  formVariant: 'fmpm',
  name: 'Florida Medical Pain Management',
  address: <a href="mailto:shassan@fmpm.com">shassan@fmpm.com</a>,
  extraAddress: (
    <>
      <p className="address">
        <a target="_blank" href="https://goo.gl/maps/vUWWQT5DnuL1DBud8">
          6333 54th Ave <br />
          St. Petersburg, FL 33709
        </a>
      </p>
      <p className="address">
        <a target="_blank" href="https://goo.gl/maps/1vyBTd6V7y1A7fJy8">
          5270 Applegate
          <br />
          Dr. Spring Hill, FL 34606
        </a>
      </p>
      <p className="address">
        <a target="_blank" href="https://goo.gl/maps/5Apw3ZD7yWsVs4W39">
          8139 FL-54, <br />
          New Port Richey, FL 34655
        </a>
      </p>
    </>
  ),
  bio: null,
  extraBio: (
    <div className="small-info-block">
      <div className="lead">Provider(s) Name & Bio:</div>
      <div className="text">Brian Mavretich, DO</div>
      <div className="text">Kazi Hassan, MD</div>
      <div className="text">Sardha Perera, MD</div>
      <div className="text">Algery Alhafi, PA</div>
      <div className="text">Brenda Giles, DNP</div>
    </div>
  ),
  map: getMapUrl('6333 54th Ave NSt. Petersburg, FL 33709'),
};

const FMPMProvider = () => <TemplatePage provider={fmpm} />;

const API_FMPM = {
  externalId: 'dadfa0d7-f012-42b5-8b9b-f005b403c99a',
  name: 'Florida Medical Pain management',
  logoUrl:
    'https://prod-practice-logos.s3.amazonaws.com/prod/practice-logos/672258dd-47cd-4fba-a1b7-d5be023a253f',
  code: 'fmpm',
  statesAvailable: ['FL'],
  addresses: [
    {
      externalId: '23f1eb36d5cf4608bc415e971ad387dd',
      street1: '6333 54th Ave',
      street2: '',
      city: 'St. Petersburg',
      state: 'FLORIDA',
      zipCode: '33709',
    },
    {
      externalId: '2b874004e2fb41758829c99107a5e60f',
      street1: '5270 Applegate',
      street2: '',
      city: 'Dr. Spring Hill',
      state: 'FLORIDA',
      zipCode: '34606',
    },
    {
      externalId: 'f1d1e8da70ef4c9dae4b24b0718349fd',
      street1: '8139 FL-54',
      street2: '',
      city: 'New Port Richey',
      state: 'FLORIDA',
      zipCode: '34655',
    },
  ],
  providers: [
    {
      fullName: 'Sardha Perera',
      avatarUrl:
        'https://prod-nuelife-playlist-covers.s3.amazonaws.com/prod/profile-pictures/20d0062f-83bd-4d24-9e5a-fe13234f6106',
      title: 'M.D.',
      type: 'PROVIDER',
      bio: 'Sardha M. Perera, M.D. practices at Florida Medical Pain Management, serving patients in St. Petersburg, New Port Richey, and Spring Hill, Florida. Dr. Perera received his medical education at the University of Ibadan in Nigeria. He then completed a surgical research fellowship in transplant surgery at Albany Medical Center Hospital in Albany New York.',
      firstName: 'Sardha',
      lastName: 'Perera',
      id: 16954211,
      externalId: '58669570cc024a6bad6c89b430e5647d',
    },
    {
      fullName: 'Algery Alhafi',
      avatarUrl:
        'https://prod-nuelife-playlist-covers.s3.amazonaws.com/prod/profile-pictures/f27fb0b9-8e7a-4621-8829-89d6f4af8769',
      title: 'PA',
      type: 'PROVIDER',
      bio: 'Algery Al Hafi has over 10 years of experience in the medical field. She earned her Master of Science in Nursing degree from Chamberlain University and is Board Certified as a Family Nurse Practitioner through the American Association of Nurse Practitioners. She received her undergraduate degree with a Bachelor of Science in Nursing through the University of South Florida and Rasmussen College. Algery is proud to be in a Nurse Practitioner, where she has the opportunity to greatly and positively impact the lives of her patients. She has received various awards in recognition of her clinical excellence throughout her career in the medical field. Her dedication to her patients is conveyed through her caring, compassionate, and professional delivery of care.',
      firstName: 'Algery',
      lastName: 'Alhafi',
      id: 16905137,
      externalId: '401e8bf840e04b1d9c10368e5efa2b96',
    },
    {
      fullName: 'Brian Mavretich',
      avatarUrl:
        'https://prod-nuelife-playlist-covers.s3.amazonaws.com/prod/profile-pictures/30217745-0836-4002-94f5-aa2deb225cf5',
      title: 'DO',
      type: 'PROVIDER',
      bio: 'Brian Mavretich, D.O. serves patients at Florida Medical Pain Management at the St. Petersburg, Florida location. He completed his undergraduate education at Michigan State University, and he received his medical degree from Michigan State University College of Osteopathic Medicine. His intern year was completed at Henry Ford Allegiance Health in Jackson, Michigan. His primary specialty is Physical Medicine and Rehabilitation from Mercy Hospital, part of Catholic Health Services, in Rockville Centre, New York. His fellowship training in Interventional Pain Management was completed with National Spine and Pain Centers in Orlando, Florida.',
      firstName: 'Brian',
      lastName: 'Mavretich',
      id: 17225738,
      externalId: '0839e05c71e047a49b3396793be19dac',
    },
    {
      fullName: 'Brenda Giles',
      avatarUrl:
        'https://prod-nuelife-playlist-covers.s3.amazonaws.com/prod/profile-pictures/b65e8a94-14ee-4b70-a43b-508393dbefcf',
      title: 'DNP',
      type: 'PROVIDER',
      bio: 'Dr. Brenda Giles earned her Doctorate in Nursing Practice Summa Cum Laude at Touro University. She also earned her Master’s Degree in Nursing Summa Cum Laude at Herzing University and is Board Certified as a family nurse practitioner through the American Association of Nurse Practitioners. She earned her Bachelor’s in Nursing Magna Cum Laude at Remington College of Nursing.',
      firstName: 'Brenda',
      lastName: 'Giles',
      id: 17226251,
      externalId: 'b1bef4106f7d426991430934a3ce6080',
    },
    {
      fullName: 'Kazi Hassan',
      avatarUrl:
        'https://prod-nuelife-playlist-covers.s3.amazonaws.com/prod/profile-pictures/f74c9ee0-d4de-4ea3-bf34-ab0605623074',
      title: 'M.D.',
      type: 'PROVIDER',
      bio: 'Kazi Hassan, M.D. is board certified, specializing in Anesthesiology with a sub-specialty in Interventional Pain Management, by the American Board of Anesthesiology. Dr. Hassan currently serves patients at Florida Medical Pain Management, with locations in St. Petersburg, New Port Richey, and Spring Hill, Florida. He completed his undergraduate education at the University of New York, Buffalo.',
      firstName: 'Kazi',
      lastName: 'Hassan',
      id: 16954261,
      externalId: 'e8b73c634fca4c1e94d9b3719815c2b4',
    },
  ],
  virtual: true,
};

const FMPMDynamicProvider = () => <DynamicTemplatePage provider={API_FMPM} />;

const natalie = {
  name: 'Natalie Li',
  formVariant: 'natalie.agapepsychiatry',
  address: (
    <a href="mailto:natalieli@agapepsychiatrypractice.com">
      natalieli@agapepsychiatrypractice.com
    </a>
  ),
  extraAddress: (
    <>
      <p className="address">
        <a target="_blank" href="https://goo.gl/maps/vh54cbuADaefTMbN8">
          4441 Purves St <br />
          LIC, NY 11101
        </a>
      </p>
    </>
  ),
  bio: 'Natalie Li is a psychiatric nurse practitioner with experience in psychopharmacology and medication management. She is familiar with integrative psychiatric treatments and focused on establishing a trusting and supportive relationship. She is fascinated by psychedelics, and completed trainings in ketamine provider training and psychedelic assisted psychotherapy respectively through Integrative Psychiatry Institute.',
  map: getMapUrl('4441 Purves St, LIC, NY 11101'),
};

const NatalieProvider = () => <TemplatePage provider={natalie} />;

const ProviderPage = ({ match }) => {
  const { slug } = match?.params;
  const [providerData, setProviderData] = useState();

  useEffect(() => {
    const fetchProvider = async () => {
      try {
        const { success, content } = await request({
          method: 'GET',
          path: `/v1/web/practice/${slug}`,
        });

        if (success === true) {
          //we need to filter providers that aren't active
          let filteredProviders = content.providers?.filter(p => p.active);
          content.providers = filteredProviders;
          setProviderData(content);
          window.sessionStorage.setItem('practiceCode', slug);          
        }
      } catch (error) {
        window.location.href = '/';
      }
    };

    fetchProvider();
  }, [slug]);

  if (!providerData) {
    return null;
  }

  return <DynamicTemplatePage isLoading={false} provider={providerData} />;
};

export {
  HadasProvider,
  IPSProvider,
  FMPMProvider,
  FMPMDynamicProvider,
  NatalieProvider,
  ProviderPage,
};
