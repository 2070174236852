import React from 'react';
import { screen } from 'helpers';
import { GlobalContext } from 'context';
import {
  Section,
  Stepper,
  Layout,
  ErrorDialog,
  Container,
  SignupStepContainer,
} from 'components';

import './cpcall.less';

import { CHILI_PEPER_APP, CHILI_PEPER_ROUTER } from 'utils/env';
import { loadScript } from 'utils/script';
import states from 'utils/us-states';
import { trackOnce } from 'utils/tracking';
import { getFormVariant, getVariationId } from 'utils/optimize';

function formatValue(value) {
  if (Array.isArray(value)) {
    return value.join(', ');
  }
  return value;
}

@screen
export default class Call extends React.Component {
  static contextType = GlobalContext;

  calendarRef = React.createRef();

  state = {
    startDate: new Date(),
    loading: true,
    error: undefined,
  };

  componentDidMount() {
    window.scrollTo({ top: 0 });
    window?.gtag('event', 'view_call');
    this.runChiliPeper();
  }

  async runChiliPeper() {
    const { account, leadQuestions, startInfo } = this.context;

    try {
      if (!window.ChiliPiper) {
        await loadScript('https://js.na.chilipiper.com/marketing.js');
      }

      const answers = {};

      leadQuestions.map(({ id, answer, otherName, otherAnswer }) => {
        answers[id] = formatValue(answer);

        if (otherName) {
          answers[otherName] = otherAnswer;
        }
      });

      const flagQuestions = this.context.flagQuestions.filter(
        (c) => c.answer?.length
      );

      const redQuestions = {};
      flagQuestions.forEach((c) => {
        redQuestions[c.description.slice(0, 70)] = c.answer;
      });

      const [month, day, year] = new Intl.DateTimeFormat('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      })
        .format(startInfo.dateOfBirth)
        .split('/');

      console.log(
        JSON.stringify({
          /* Account */
          we_are_only_accepting_patients_18_what_is_your_date_of_birth: `${year}-${month}-${day}`,
          pronouns: account.gender,
          first_name: account.firstName,
          last_name: account.lastName,
          lastName: account.lastName,
          email: account.email,
          phone: account.phone,
          // account_password: account.password,
          billing_street_address_1: account.billingAddress?.street1,
          billing_street_address_2: account.billingAddress?.street2,
          billing_city: account.billingAddress?.city,
          billing_state: states[account.billingAddress?.state],
          billing_zip_code: account.billingAddress?.zipCode,
          shipping_street_address_1: account.shippingAddress?.street1,
          shipping_street_address_2: account.shippingAddress?.street2,
          shipping_city: account.shippingAddress?.city,
          shipping_zip_code: account.shippingAddress?.zipCode,
          shipping_state: states[account.shippingAddress?.state],

          utm_source: window.localStorage.utm_source,
          utm_medium: window.localStorage.utm_medium,
          utm_term: window.localStorage.utm_term,
          utm_content: window.localStorage.utm_content,
          utm_campaign: window.localStorage.utm_campaign,
          gclid: window.localStorage.gclid,

          form_variants: getVariationId() || 'Nue life lead form',
          /* Answers */
          ...answers,
          ...redQuestions,
        })
      );

      window.ChiliPiper.submit(CHILI_PEPER_APP, CHILI_PEPER_ROUTER, {
        lead: {
          /* Account */
          we_are_only_accepting_patients_18_what_is_your_date_of_birth: `${year}-${month}-${day}`,
          pronouns: account.gender,
          first_name: account.firstName,
          last_name: account.lastName,
          email: account.email,
          phone: account.phone,
          // account_password: account.password,
          billing_street_address_1: account.billingAddress?.street1,
          billing_street_address_2: account.billingAddress?.street2,
          billing_city: account.billingAddress?.city,
          billing_state: states[account.billingAddress?.state],
          billing_zip_code: account.billingAddress?.zipCode,
          shipping_street_address_1: account.shippingAddress?.street1,
          shipping_street_address_2: account.shippingAddress?.street2,
          shipping_city: account.shippingAddress?.city,
          shipping_zip_code: account.shippingAddress?.zipCode,
          shipping_state: states[account.shippingAddress?.state],
          state: states[account.shippingAddress?.state],

          utm_source: window.localStorage.utm_source,
          utm_medium: window.localStorage.utm_medium,
          utm_term: window.localStorage.utm_term,
          utm_content: window.localStorage.utm_content,
          utm_campaign: window.localStorage.utm_campaign,
          gclid: window.localStorage.gclid,

          ...answers,
          ...redQuestions,
          form_variants: getVariationId() || 'Nue life lead form',
        },
        domElement: '#calendar',
        map: true,

        closeOnOutside: false,
        injectRootCss: true,

        onError: () => {
          this.setState({
            error: new Error('Failed to open chilipiper, contact support'),
          });
        },
        onRouted: () => {
          setTimeout(() => {
            this.setState({
              loading: false,
            });
            if (this.calendarRef?.current) {
              this.calendarRef.current.scrollIntoView({ behavior: 'smooth' });
            }
          }, 500);
        },
        onSuccess: (options = {}) => {
          if (options.actionType === 'create') {
            window?.fbq('track', 'Schedule');
            trackOnce('scheduled');
            window?.gtag('event', 'conversion', {
              send_to: 'AW-743375642/rVgBCMmx17QDEJqGvOIC',
            });
          }
        },
        onClose: () => {
          console.log('onClose');
        },
      });
    } catch (error) {
      this.setState({ error });
    }
  }

  render() {
    const { loading } = this.state;
    return (
      <div className="stepCPCall">
        {this.state.error && (
          <ErrorDialog
            title="Oops something went wrong"
            message={this.state.error.message}
            onClose={() =>
              this.setState({
                error: undefined,
              })
            }
          />
        )}

        <Section changeyellow style={{ borderRadius: 0 }}>
          {loading && (
            <>
              <Stepper
                current={this.props.step - 1}
                steps={this.props.numberOfSteps}
              />

              <SignupStepContainer title="last step: schedule a call with our sales experts">
                <Container>
                  <Layout stackable>
                    <div
                      style={{ marginTop: '100px' }}
                      className="stepScheduleAppointment__content left-space">
                      {/*
                      <h1 className="h1">
                        Please select the day and time that is the most
                        convenient for you.
                      </h1>
                      */}
                      <p className="bodycopylong">A call lasts 30 minutes.</p>
                      <div className="divider"></div>
                      {loading && (
                        <Layout center>
                          <div>Loading...</div>
                        </Layout>
                      )}
                    </div>
                  </Layout>
                </Container>
              </SignupStepContainer>
            </>
          )}
          <div style={loading ? { visibility: 'hidden' } : {}} id="calendar" />
        </Section>
      </div>
    );
  }
}
