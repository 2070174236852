import React from 'react';
import PropTypes from 'prop-types';
import bem from 'helpers/bem';

import './spacer.less';

@bem
export default class Spacer extends React.Component {

  getModifiers() {
    return [this.props.size];
  }

  render() {
    return <div className={this.getBlockClass()} />;
  }
}

Spacer.propTypes = {
  size: PropTypes.oneOf(['xxs', 'xs', 's', 'm', 'l', 'xl', 'xxl']),
};

Spacer.defaultProps = {
  size: 'm',
};
