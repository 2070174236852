import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { screen } from 'helpers';
import {
  Button,
  Layout,
  Section,
  IconArrowLeft,
  PlanSelected,
  SignupStepContainer,
} from 'components';

import states from 'utils/us-states';

import { GlobalContext } from 'context';

import logo from 'assets/logo.svg';
import './start.less';

const options = Object.keys(states).map((value) => {
  return {
    value,
    text: states[value],
  };
});

options.unshift({
  value: '',
  text: 'Select Your State',
});

@screen
export default class TalkWithUs extends Component {
  static contextType = GlobalContext;

  handleOnSubmit = (e) => {
    e.preventDefault();
    this.props.onDone();
  };

  render() {
    const { planId } = this.props;
    const plan = this.context.plans.find((c) => c.id === planId);

    return (
      <div className="stepStart">
        <div className="header">
          <Layout spread horizontal center>
            <Button
              pill
              secondary
              as={Link}
              to={`/checkout/${plan.id}/questions`}>
              <IconArrowLeft />
            </Button>
            <img src={logo} />
            <div></div> {/* to center logo */}
          </Layout>
        </div>

        <Section color={'changeyellow'} flat>
          <SignupStepContainer title={'talk with a sales coordinator.'}>
            <>
              <Section>
                <PlanSelected plan={plan} accordion buttons={false} />
              </Section>

              <p className="bodycopy2">
                Your answers indicate that it’s essential that you talk with one
                of our experts. They’ll help you identify which Nue Life program
                is the best fit to begin your mental wellness journey.
              </p>
            </>
            <Button
              className="submit"
              long
              nueblue
              as={Link}
              to="/talk-with-us/questions">
              Talk to a Sales Expert
            </Button>
          </SignupStepContainer>
        </Section>
      </div>
    );
  }
}
