import React from 'react';
import PropTypes from 'prop-types';
import VideoStream from './Stream';

import './adaptive.less';

export default class AdaptiveVideo extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  componentDidMount() {
    const el = this.getVideoElement();
    if (el) {
      el.addEventListener('durationchange', this.props.onDurationChange);
      el.addEventListener('timeupdate', this.props.onTimeUpdate);
    }
  }

  componentWillUnmount() {
    const el = this.getVideoElement();
    if (el) {
      el.removeEventListener('durationchange', this.props.onDurationChange);
      el.removeEventListener('timeupdate', this.props.onTimeUpdate);
    }
  }

  getVideoElement() {
    let el = this.ref.current;
    if (el?.ref) {
      el = el.ref.current;
    }
    return el;
  }

  play() {
    this.ref.current.play();
  }

  setTime(time) {
    const el = this.getVideoElement();
    if (el) {
      if (!isNaN(el.duration)) {
        // Small offset to prevent the video from unloading
        time = Math.max(0, Math.min(time, el.duration - 0.001));
        el.currentTime = time;
      }
    }
  }

  jump(t) {
    const el = this.getVideoElement();
    if (el) {
      this.setTime(el.currentTime + t);
    }
  }

  toggle() {
    const el = this.getVideoElement();
    if (el) {
      if (el.paused) {
        el.play();
      } else {
        el.pause();
      }
    }
  }

  render() {
    const { loading } = this.props;
    return (
      <div className="adaptive-video">
        {loading && <div>loading</div>}
        {this.renderVideo()}
      </div>
    );
  }

  renderVideo() {
    const { video, loader, controls, autoPlay } = this.props;

    return (
      <VideoStream
        video={video}
        loader={loader}
        controls={controls}
        autoPlay={autoPlay}
        onStart={this.props.onStart}
        onEnd={this.props.onEnd}
        ref={this.ref}
      />
    );
  }
}

AdaptiveVideo.propTypes = {
  controls: PropTypes.bool,
};

AdaptiveVideo.defaultProps = {
  controls: false,
  onTimeUpdate: () => {},
  onDurationChange: () => {},
};
