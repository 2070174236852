import classNames from 'classnames';
import { Button } from 'components';
import React from 'react';

import styles from './banner.module.less';

const Banner = ({ inner, style, button }) => {
  return (
    <div
      className={classNames(
        styles.wrapper,
        inner && styles.inner,
        !inner && styles.nav
      )} style={{zIndex:20, ...style}}> {/*hotFix for Prod*/}
      <div className={styles.banner}>
        <div className={styles.text}>
          <span className={styles.normal}>
            Learn more about our exclusive two week program with next day medical consultation
          </span>
        </div>
        {button? <a href={'https://nue.life/talk-with-us'}><Button secondary wellness >
        Talk to Us
      </Button></a> : <a style={{height: 40}}></a>}
      </div>

    </div>
  );
};

export default Banner;
