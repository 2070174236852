import React from 'react';
import './input.less';
import classNames from 'classnames';

export const Input = ({
  style,
  name,
  label,
  type = 'text',
  error,
  icon,
  onChange = () => {},
  onBlur = () => {},
  ...props
}) => {
  return (
    <div
      className={classNames('input', {
        hasValue: !!props.value,
        error: !!error,
        disabled: props.disabled,
      })}>
      <div className="input__container">
        {label && <label htmlFor={name}>{label}</label>}
        <input
          data-id={name}
          onChange={(e) =>
            onChange(e, { name: e.target.name, value: e.target.value })
          }
          onBlur={(e) => onBlur(e, { name: e.target.name })}
          type={type}
          name={name}
          style={style}
          {...props}
        />
        {icon}
      </div>
      {error?.type == 'error' && (
        <div className={'input__error'}>{error.message}</div>
      )}
      {error && typeof error == 'string' && (
        <div className={'input__error'}>{error}</div>
      )}
    </div>
  );
};
