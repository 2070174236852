import React, { Component } from 'react';
import { Grid } from '@mui/material';
import { Button } from 'components';
import './styles.less';
import { IconLeft } from '../Icons';

export const AssementFooter = ({
    onNext = () => {},
    onBack = () => {},
    primary = false,
    secondary = false,
    disableNext = false,
    hideNext = false,
    hideBack = false,
}) => {
    return (
        <Grid container className={'footer_container'} alignItems={'center'}>
            <Grid item xs={6} md={3} textAlign={'center'}>
              {!hideBack && 
                <Button
                    transparent
                    onClick={onBack}
                    className={'back_button'}
                >
                    <Grid container justifyContent={'space-evenly'}>
                        <Grid item xs={3} className={'back_icon_container'}>
                            <IconLeft height={"2em"} width={"2em"}/>
                        </Grid>
                        <Grid item xs={5} alignSelf={"center"}>
                            {"Back"}
                        </Grid>
                    </Grid>
                </Button>
              }
            </Grid>
            <Grid item xs={0} md={6}/>
            <Grid item xs={6} md={3} textAlign={'center'}>
                {!hideNext &&
                    <Button
                        onClick={onNext}
                        primary={primary}
                        secondary={secondary}
                        disabled={disableNext}
                        style={{width: '50%'}}
                    >
                        Next
                    </Button>
                }
            </Grid>
        </Grid>
    )
}