import React from 'react';
import './container.less';
import classNames from 'classnames';

export const Container = ({ style, children, className }) => {
  return (
    <div style={style} className={classNames('container', className)}>
      {children}
    </div>
  );
};
