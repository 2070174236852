import classNames from 'classnames';
import React, { useState, useEffect, useRef } from 'react';
import './step.less';
import { Button } from 'components';

import Spacer from 'components/Spacer';

export const Step = ({ step, title, style, className, children, color }) => {
  const [open, setOpen] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  const dRef = useRef();
  useEffect(() => {
    const { current } = dRef;
    //setHasMore(current.scrollHeight > current.clientHeight);
  }, []);

  function getHeight() {
    const { current } = dRef;
    if (!current || !open) {
      return '100px';
    } else {
      return `${current.scrollHeight}px`;
    }
  }

  return (
    <div
      style={style}
      className={classNames('step', className, { [`bg-${color}`]: true })}>
      <div className="step__top">
        {/* <div className="subtitle">Step {step}</div>*/}
        <h3 className="title">{title}</h3>
      </div>

      <div className="step__bottom">
        <div
          className={classNames('step__textWrapper', {
            'step__textWrapper--fade': hasMore && !open,
          })}>
          <p
            ref={dRef}
            style={
              {
                // height: getHeight(),
              }
            }
            className="bodytext">
            {children}
          </p>
        </div>

        {hasMore && (
          <>
            <Spacer size="s" />
            <Button
              onClick={() => {
                setOpen(!open);
              }}
              className="readmore"
              fluid
              transparent
              icon
              border>
              Read {open ? 'less' : 'more'}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};
