import { hot } from 'react-hot-loader/root';

import React from 'react';

import { Switch, Route, Redirect, useHistory } from 'react-router-dom';

import { ProviderPage } from 'screens/Providers/ProviderPage';
import ProvidersMainPage from 'screens/Providers/MainPage';
import Contact from 'screens/Providers/Contact';
import Test from 'screens/Test';
import TalkToUs from 'screens/TalkToUs/TalkToUs';
import Checkout from 'screens/SignupFlows/Checkout';
import TalkWithUs from 'screens/SignupFlows/TalkWithUs';
import AppOnlySignUp from 'screens/TalkToUs/FreeUserSignUp';

function trackPageView() {
  //window?.fbq('track', 'PageView');
}
const App = () => {
  const history = useHistory();

  React.useEffect(() => {
    history.listen(trackPageView); // To track the subsequent pageviews
  }, [history]);

  return (
    <Switch>
      <Route path="/" component={ProvidersMainPage} exact />
      <Route path="/contact/:step?" component={Contact} exact />
      <Route path="/talk-to-us" component={TalkToUs}/>
      <Route path="/talk-with-us/:step?" component={TalkWithUs} exact/>
      <Route path="/free-user/sign-up/:step?" component={AppOnlySignUp} exact/>
      <Route path="/:slug" component={ProviderPage} exact />
      <Route path="/checkout/:plan/:step?" component={Checkout} exact />
      <Route path="/__test" component={Test} exact />
      
      <Redirect to="/" />
    </Switch>
  );
};

export default hot(App);
