import React, { Component } from 'react';
import './error-dialog.less';
import { Button } from '../Button';
import { Dialog } from '../Dialog';

export class ErrorDialog extends Component {
  render() {
    const { message, title, ...props } = this.props;
    return (
      <Dialog {...props} className="errorDialog">
        <h1>{title}</h1>
        <p className="h2">{message}</p>
        <div>
          <Button fluid onClick={() => this.props.onClose()}>
            Go Back
          </Button>
        </div>
      </Dialog>
    );
  }
}
