import { Container } from 'components';
import React from 'react';
import styles from './team.module.less';

import ben from '../assets/team/ben_medrano.png';
import FrankDrummond from '../../Company/images/profiles/frank-drummond.png';
import BrandonLozano from '../../Company/images/profiles/brandon-lozano.jpg';
import LinkedinIcon from '../assets/linkedin_icn.svg?react';
import NueLink from '../components/NueLink';

const teamMembers = [
  {
    linkedIn: 'https://www.linkedin.com/in/dr-frank-drummond-b313621/',
    image: FrankDrummond,
    name: 'Dr.Frank Drummond',
    title: (
      <>
        Chief Medical Officer
      </>
    ),
  },
  {
    linkedIn: 'https://www.linkedin.com/in/benjamin-medrano-md-4725a0244/',
    image: ben,
    name: 'Ben Medrano, MD',
    title: (
      <>
        Medical Director of Nue Life Medical Group. <br /> Former Medical
        Director of Field Trip Health & member of the American Society of
        Ketamine Physicians
      </>
    ),
  },
  {
    linkedIn: 'https://www.linkedin.com/in/benjamin-medrano-md-4725a0244/',
    image: BrandonLozano,
    name: 'Brandon Lozano',
    title: (
      <>
        Senior Partnerships & Investor Relations Manager
      </>
    ),
  },
  
];

const TeamSection = () => {
  return (
    <Container className={styles.container}>
      <h3>Our team</h3>
      <h5>Our medical directors and advisors include:</h5>
      <div className={styles.teamList}>
        {teamMembers.map((member, index) => (
          <div key={index} className={styles.teamMember}>
            <div className={styles.teamMember__image}>
              <img src={member.image} alt={member.name} />
              <a
                className={styles.linkedin}
                href={member.linkedIn}
                target="_blank">
                <LinkedinIcon />
              </a>
            </div>
            <div className={styles.teamMember__info}>
              <div>
                <h4>{member.name}</h4>
                <p>{member.title}</p>
              </div>
              <a
                className={styles.linkedin}
                href={member.linkedIn}
                target="_blank">
                <LinkedinIcon />
              </a>
            </div>
          </div>
        ))}
      </div>
      <p className={styles.teamCommitment}>
        we are committed to the ethical use of AI and patient data to constantly
        evolve the safety and efficacy of evidence-based therapies.
      </p>
      <p className={styles.teamCommitment}>
        to learn more about our approach to treatment and our commitment to
        ethical <strong>ketamine therapy</strong>, please read our{' '}
        <NueLink to="/impact">Impact Statement</NueLink>.
      </p>
    </Container>
  );
};

export default TeamSection;
