import React, { Component } from 'react';
import { screen } from 'helpers';
import './styles.less';
import { GlobalContext } from 'context';
import dayjs from 'dayjs';
import { StateSelect } from 'components';

import { Grid, InputLabel } from '@mui/material';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AssementFooter } from '../components/AssessmentFooter';
import { Input } from 'components';
import { ProgressBar } from 'components/ProgressBar';


function getState({ context }) {
  const { startInfo = {} } = context;

  const state = {
    state: startInfo.state,
    dateOfBirth: startInfo.dateOfBirth || "",
    zipCode: startInfo.zipCode || "",
  };
  return state;
}

@screen
export default class DateAndState extends Component {
  static contextType = GlobalContext;
  state = getState(this);

  persistInfo() {
    this.context.setStartInfo(
      {
        ...this.context.startInfo,
        dateOfBirth: this.state.dateOfBirth,
        state: this.state.state,
        zipCode: this.state.zipCode
      });
  }

  componentWillUnmount() {
    this.persistInfo();
  }

  next = () => {
    this.persistInfo();
    if (this.context.availableStates.includes(this.state.state)) {
      this.props.history.push(this.props.availableRoute);
    } else {
      this.props.history.push(this.props.unavailableRoute);
    }
  }


  render() {
    return (
    <div>
      {/*<ProgressBar
        questionIndex={1}
    />*/}
      <div className='background'>
        <div className='title_container'>
          <Grid container flexDirection={"column"}>
            {this.props.textContent ||
              <h2 className='blue label state_title'>
                Before we begin,
                let's confirm we're available on your area
              </h2>
            }
          </Grid>
          <Grid container justifyContent={'space-around'} className='date_state_container'>
            <Grid item xs={6} className='date_picker_container'>
              {/*<LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Date of birth"
                  value={this.state.dateOfBirth}
                  onChange={(newValue) => {
                    this.setState({
                      ...this.state,
                      dateOfBirth: newValue
                    })
                  }}
                  openTo={'year'}
                  disableFuture
                  maxDate={dayjs().subtract(18, 'years')}
                  renderInput={(params) =>
                   <TextField sx={{backgroundColor: "#F3F3F3", width: "95%", fontFamily: "Gotham", fontWeight: "400", fontSize: "10px", lineHeight: "20px"}}
                    {...params}
                    />
                  }
                />
                </LocalizationProvider>*/}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <InputLabel
                                sx={{
                                  position: 'absolute',
                                  paddingTop: '15px',
                                  paddingLeft: '20px',
                                  fontFamily: 'Gotham',
                                  zIndex: 1,
                                  fontSize: '10px',
                                  opacity: '0.7',
                                  color: '#0d345c',
                                }}>
                                Date of Birth
                              </InputLabel>
                              <DatePicker
                                value={this.state.dateOfBirth}
                                onChange={(newValue) => {
                                  this.setState({
                                    ...this.state,
                                    dateOfBirth: newValue,
                                  });
                                }}
                                disableFuture
                                maxDate={dayjs().subtract(18, 'year').toDate()}
                                openTo={'day'}
                                inputFormat="MM/DD/YYYY"
                                renderInput={(params) => (

                                    <TextField
                                      {...params}
                                      sx={{
                                        backgroundColor: '#F1F1F1',
                                        opacity: '0.8',
                                        paddingTop: '15px',
                                        paddingLeft: '7px',
                                        color: 'black',
                                        '& .MuiInputBase-input': {
                                          fontFamily: 'Gotham',
                                          fontSize: '0.9em',
                                          opacity: '1',
                                        },
                                      }}
                                    />

                                )}
                              />

                          </LocalizationProvider>
            </Grid>
            <Grid item xs={6} className='state_container'>
              <StateSelect
                required
                //{['FL', 'CT', 'GA', 'MA', 'CO']}
                prefered={this.context.availableStates}
                label="State"
                name="state"
                value={this.state.state}
                disabled={this.state.locked}
                onChange={(e, { name, value }) => {
                  this.setState({
                    [name]: value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={1} md={6}/>
            <Grid item xs={10} md={6} className='state_container'>
                <Input
                  required
                  label="Zip Code"
                  type="text"
                  name="zip"
                  value={this.state.zipCode}
                  onChange={(e, { name, value }) =>
                    this.setState({
                      ...this.state,
                      zipCode: value
                    })
                  }
                />
            </Grid>
            <Grid item xs={1} md={0}/>
          </Grid>
        </div>
        <AssementFooter
          onBack={() => this.props.history.push(this.props.prevRoute)}
          onNext={this.next}
          disableNext={this.state.dateOfBirth === (undefined || "") || this.state.state === undefined || this.state.zipCode.length !== 5}
          secondary
        />
      </div>
    </div>
    );
  }
}