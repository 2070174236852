import React, { Component } from "react";
import { screen } from "helpers";
import './styles.less';
import { Grid } from "@mui/material";


@screen
export default class NotQualified extends Component {
    render() {
        return (
            <div className='blue_background'>
                <div className='title_container'>
                    <Grid container>
                        <Grid item xs={12} flexDirection={'column'} textAlign={'center'}>
                            {this.props.message.map((item, index) => {
                                if (item.type == 'paragraph') {
                                    return (
                                    <h1 className='white label not_qualified_statement'>
                                        {item.value}
                                    </h1>);
                                } else if (item.type === 'url') {
                                    return (
                                        <a className='not_qualified_statement not_qualified_url' href={item.value}>{item.value}</a>
                                    );
                                } else {
                                    return <></>;
                                }
                            })}
                            
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    }
}