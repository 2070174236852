import React from "react";
import './styles.less';
import { Button } from "components";
import { StatementBanner } from "../components/StatementBanner";

export default function Congratulations({ history, nextRoute }) {
    const next = () => {
        if (sessionStorage.getItem('plan') != undefined && sessionStorage.getItem('plan') != null) {
          const route = nextRoute || '/checkout/{plan}/account';
            history.push(route.replace('{plan}', window.sessionStorage.getItem('plan')));
        } else {
            history.push('/talk-to-us/schedule-call');
        }
    }   


    return (
        <StatementBanner 
            title={'Congratulations! Based on your responses, Nue Life could be a great fit for you.'}
            subtitle={'Let\'s set up your account to get started!'}
            onBack={() => history.push('/talk-to-us/questions')}
            onNext={next}
        />
    )
}