import React, { Component } from 'react';
import { screen } from 'helpers';
import './styles.less';
import { Grid, TextField } from '@mui/material';
import { GlobalContext } from 'context';
import { Button } from 'components';
import { IconRight } from '../components/Icons';
import { request } from 'utils/api';
import { PUBLIC_TOKEN } from 'utils/env';
import { Input } from "components";
import * as yup from 'yup';
import { Select } from 'components';



function getState( { context }) {
    const { startInfo = {}, account = {} } = context;
    const state = {
        email: account.email || '',
        firstName: account.firstName || '',
        lastName: account.lastName || '',
        gender: account.gender || '',
        loading: false,
        fieldsErrors: {}
    };
    return state;
}

const schema = yup.object().shape({
    email: yup.string().email().required('Email is Required'),
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    gender: yup.string().required('Gender is required'),
});

@screen
export default class OutOfState extends Component {
    static contextType = GlobalContext;
    state = getState(this);

    submit = async () => {
        this.setState({
            ...this.state,
            loading: true
        });

        try {
            let practiceCode = window.sessionStorage.getItem('practiceCode');
            let falsyValues = [null,undefined,"null","undefined"]; 
            await request({
              method: 'POST',
              path: '/v1/web/profile/create-lead',
              body: {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                gender: this.state.gender,
                email: this.state.email,
                state: this.context.startInfo.state,
                dateOfBirth: this.context.startInfo.dateOfBirth,
                formVariant: '[TTU_V2] Nue life Out of State' + (falsyValues.contains(practiceCode) ? " [practice: " + practiceCode + "]" : ""),
                practiceCode: practiceCode
              },
              headers: {
                Authorization: `Basic ${PUBLIC_TOKEN}`,
              },
            });
            window?.gtag('event', 'out_of_state_completed');
            this.props.history.push(this.props.nextRoute);
        } catch (e) {
            console.log(e);
        }

        this.setState({
            ...this.state,
            loading: false
        });
    }

    renderForm() {
      return (
        <>
        <Grid item xs={12} md={3} justifyContent={"center"}>
              <Input 
                  value={this.state.firstName} 
                  onChange={(e) => {
                      this.setState({
                          ...this.state,
                          firstName: e.target.value
                      });
                  }}
                  label='First Name'
                  error={this.state.fieldsErrors.firstName}
              />
          </Grid>
          <Grid item xs={12} md={3} justifyContent={"center"}>
              <Input 
                  value={this.state.lastName} 
                  onChange={(e) => {
                      this.setState({
                          ...this.state,
                          lastName: e.target.value
                      });
                  }}
                  label='Last Name'
                  error={this.state.fieldsErrors.lastName}
              />
          </Grid>
          <Grid item xs={0} md={3} padding={'0 0 0 0'}/>
          <Grid item xs={0} md={3} padding={'0 0 0 0'}/>
          <Grid item xs={12} md={3} justifyContent={"center"}>
              <Input 
                  value={this.state.email} 
                  onChange={(e) => {
                      this.setState({
                          ...this.state,
                          email: e.target.value
                      });
                  }}
                  label='Email Address'
                  error={this.state.fieldsErrors.email}
              />
          </Grid>
          <Grid item xs={12} md={3} justifyContent={"center"}>
              <Select
                  label="Gender"
                  required
                  name="gender"
                  error={this.state.fieldsErrors.gender}
                  onChange={(e, { name, value }) => this.setState({
                      ...this.state,
                      gender: e.target.value
                  })}
                  options={[
                      { value: '', text: 'Your gender' },
                      { value: 'MALE', text: 'Male' },
                      {
                      value: 'FEMALE',
                      text: 'Female',
                      },
                      {
                      value: 'OTHER',
                      text: 'Other',
                      },
                      {
                      value: 'DECLINE_TO_SPECIFY',
                      text: 'Decline to specify',
                      },
                  ]}
              />
          </Grid>
        </>
      )
    }
    
    render() {
        return (
            <div className='blue_background'>
                <div className='title_container'>
                    <Grid container spacing={2}>
                        <Grid item xs={12} flexDirection={"column"} marginBottom={"1em"}>
                                <h2 className='white label out_of_state_statement'>
                                    Unfortunately we are not available in your area at this<br/> time.
                                    Would you like to be notified when we expand to<br/> your state?
                                </h2>
                        </Grid>
                        <Grid item xs={0} md={3}/>
                        {!this.props.hideForm && this.renderForm()}
                        <Grid item xs={0} md={3}/>
                        <Grid container justifyContent={'center'} marginTop={'1em'}>
                            <Grid item xs={10} md={3}>
                                <Button
                                    onClick={this.submit}
                                    secondary
                                    style={{width: '100%'}}
                                >
                                    {this.props.hideForm ? "Yes, notify me" : "Submit"}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
        )
    }
}