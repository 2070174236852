import React from 'react';
import './plan-price.less';

export const PlanPrice = ({ plan, variant }) => {
  let { prices } = plan;

  return (
    <ul className="planPrice" key={plan.id}>
      {prices.map(({ amount, type }, index) => {
        return (
          <li
            className={`planPrice__item ${type}`}
            key={`${amount}_${type}_${plan.id}`}>
            <div>
              <span className="planPrice__currency bodycopy1">$</span>
              <span className="planPrice__amount">{amount}</span>
              <span className="planPrice__type">{type}</span>
            </div>
            {index !== prices.length - 1 && (
              <span className="planPrice__divider" />
            )}
          </li>
        );
      })}
    </ul>
  );
};
