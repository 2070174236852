import React, { useRef } from 'react';
import {
  SnapList,
  SnapItem,
  useVisibleElements,
  useScroll,
} from 'react-snaplist-carousel';
import { Container } from 'components';
import { Feature } from './Feature';
import { Button, IconArrowLeft, IconArrowRight } from 'components';

import cn from './feature.module.less';

import feature1Img from './assets/feature-1.png';
import feature2Img from './assets/feature-2.png';
import feature3Img from './assets/feature-3.png';
import feature4Img from './assets/feature-4.png';
import feature5Img from './assets/feature-5.png';
import classNames from 'classnames';

const ENTRIES = [
  {
    header: 'The most advanced mental wellness companion app.',
    image: feature1Img,
    kicker: 'EXPERIENCE COMPANION AND TRACKING',
    text: 'Mood tracking, voice notes, telemedicine support, and more - at your fingertips.',
  },
  {
    header: 'Your personalized wellness evaluation system.',
    image: feature2Img,
    kicker: 'nue.score',
    text: 'Nue Care includes Nue Score, a proprietary tracking system that offers you a holistic snapshot of your progress.',
  },
  {
    header: 'Healing... by the numbers.',
    image: feature3Img,
    kicker: 'nue.life dashboard',
    text: 'Our collection of mental wellness assessments helps you see your progress in real-time.',
  },
  {
    header: 'Your soundtrack to healing.',
    image: feature4Img,
    kicker: 'custom playlists',
    text: 'Our musicologist-designed playlists allow you to create the optimal setting for your Nue Life experience.',
  },
  {
    header: 'Exclusive content supports you in your healing journey.',
    image: feature5Img,
    kicker: 'Exclusive content',
    text: 'The latest news in health and wellness helps you build your self-care toolkit.',
  },
];

const Controls = ({ next, prev, prevDisabled, nextDisabled, className }) => (
  <div className={classNames(cn.controls, className)}>
    <Button disabled={prevDisabled} pill onClick={prevDisabled ? null : prev}>
      <IconArrowLeft />
    </Button>
    <Button disabled={nextDisabled} pill onClick={nextDisabled ? null : next}>
      <IconArrowRight />
    </Button>
  </div>
);

const AppFeatureCarousel = () => {
  const snapList = useRef(null);

  const goToSnapItem = useScroll({ ref: snapList });
  const centerEl = useVisibleElements(
    { debounce: 50, ref: snapList },
    (_, center) => center
  );

  return (
    <div className={classNames('features', cn.appFeatures)}>
      <Container>
        <h3 className="title">
          Nue Life app is now available for free to all users{' '}
        </h3>
      </Container>
      <SnapList ref={snapList}>
        {ENTRIES.map((e, i) => (
          <SnapItem key={i}>
            <Feature
              key={i}
              step={i + 1}
              controls={
                <Controls
                  next={() => goToSnapItem(i + 1)}
                  prev={() => goToSnapItem(i - 1)}
                  prevDisabled={i === 0}
                  nextDisabled={ENTRIES.length === i + 1}
                />
              }
              header={e.header}
              image={e.image}
              name={e.kicker}>
              {e.text}
            </Feature>
          </SnapItem>
        ))}
      </SnapList>
      <Controls
        className={cn.controlBottom}
        next={() => goToSnapItem(centerEl + 1)}
        prev={() => goToSnapItem(centerEl - 1)}
        prevDisabled={centerEl === 0}
        nextDisabled={ENTRIES.length === centerEl + 1}
      />
    </div>
  );
};

export default AppFeatureCarousel;
