import React from 'react';


export const  CheckoutCongrats = ({plan}) => {

    if (['reset', 'you'].includes(plan.id)) {
        return (
            <>
                <p className="bodycopy2">
                    Your mental health journey need not wait!
                    We have flexible Buy Now, Pay Later options waiting for you.
                </p>
                {/*<p className="bodycopy2">*/}
                {/*    Get started with as low as {plan.id === 'reset' ? '$27' : '$58'}/week* for qualifying clients*/}
                {/*</p>*/}
                {/*<p className="bodycopy3">*/}
                {/*    *Paid monthly for 12 months and may vary depending on the financing approval. Total Cost of the program is $1399*/}
                {/*    All payment options are powered by Walnut*/}
                {/*</p>*/}
            </>
        );
    } else {
        return (
            <></>
        );
    }

}