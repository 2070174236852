import React, { Component } from "react";
import { screen } from "helpers";
import { StatementBanner } from "../components/StatementBanner";


@screen
export default class QuestionnaireStart extends Component {
    done = () => {
        this.props.history.push(this.props.nextRoute);
    }

    back = () => {
        this.props.history.push(this.props.prevRoute);
    }
    
    render(){
        return (
            <StatementBanner
                onNext={this.done}
                onBack={this.back}
                title={'Over 90% of Nue life patients report improvements in their anxiety, depression and PTSD symptoms after treatment.'}
                subtitle={'This brief (2 minute) assessment will help our clinicians determine if Nue Life is right for you.'}
            />
        );
    }
}