import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import './plan.less';
import { IconCheckmark, IconArrowDown, IconArrow } from '../Icons';
import { Link } from 'react-router-dom';
import { Button } from '../Button';
import { PlanPriceVarient } from '../PlanPriceVarient';
import useOnScreen from 'utils/hooks/onScreen';

import Spacer from 'components/Spacer';

const highlightPlan = (text, pattern) => {
  const splitText = text.split(pattern);

  if (splitText.length <= 1) {
    return text;
  }

  const matches = text.match(pattern);

  return splitText.reduce(
    (arr, element, index) =>
      matches[index]
        ? [
            ...arr,
            element,
            <span key={index} className="italic">
              {matches[index]}
            </span>,
          ]
        : [...arr, element],
    []
  );
};

export const Plan = ({
  style,
  className,
  plan,
  variant,
  isSecondary=false,
  isIPP=false,
  LinkComponent = Link,
  showPurchase = true
}) => {
  const { name, description, features } = plan;

  //const { variant } = useGlobal();
  const ref = useRef();
  const isVisible = useOnScreen(ref);
  const [triggered, setTriggered] = useState(false);
  const dRef = useRef();
  const [open, setOpen] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [focusedDescription, setFocusedDescription] = useState(null);

  useEffect(() => {
    const { current } = dRef;
    setHasMore(current.scrollHeight > current.clientHeight);
  }, []);

  useEffect(() => {
    if (isVisible && !triggered) {
      setTriggered(true);
      window.gtag('event', 'view_item', {
        currency: 'USD',
        value: plan.price,
        items: [
          {
            item_id: plan.id,
            item_name: plan.name,
            price: plan.price,
            quantity: 1,
          },
        ],
      });
    }
  }, [isVisible]);

  function getHeight() {
    const { current } = dRef;
    if (!current || !open) {
      return '100px';
    } else {
      return `${current.scrollHeight}px`;
    }
  }

  function onCheckoutClick() {
    window?.fbq('track', 'AddToCart');

    window?.gtag('event', 'plan_buy_clicked');
    window?.gtag('event', 'add_to_cart', {
      currency: 'USD',
      value: plan.price,
      items: [
        {
          item_id: plan.id,
          item_name: plan.name,
          price: plan.price,
          quantity: 1,
        },
      ],
    });

    window.sessionStorage.setItem('flow', 'checkout');
    window.sessionStorage.setItem('plan', plan.id);

    window?.gtag('event', 'conversion', {
      send_to: 'AW-743375642/WityCNul6_4CEJqGvOIC',
    });
  }

  return (
    <div
      key={plan.id}
      style={{
        ...style,
        backgroundColor: plan.color,
        '--backgroundColor': plan.color,
      }}
      ref={ref}
      className={classNames('plan', className)}>
      <div className="plan__top">
        <h3 className="plan__name title signup2">
          nue.<span className="emp">{name.replace('nue.', '')}</span>
        </h3>
        <h6 className="plan__type">{plan.subscription}</h6>
        <div className="plan__leftSpace">
          <div
            className={classNames({
              plan__textWrapper: true,
              'plan__textWrapper--fade': hasMore && !open,
            })}>
            <div
              ref={dRef}
              style={{
                height: getHeight(),
              }}
              className="plan__description">
              {description.split('\n').map((text) => {
                return (
                  <div key={text}>
                    {text}
                    <div style={{ marginTop: '1em' }} />
                  </div>
                );
              })}
            </div>
          </div>
          {hasMore && (
            <React.Fragment>
              <Spacer size="s" />
              <Button
                onClick={() => {
                  setOpen(!open);
                }}
                className="readmore"
                fluid
                transparent
                icon
                border>
                Read {open ? 'less' : 'more'}
              </Button>
            </React.Fragment>
          )}
        </div>
      </div>
      <ul className="plan__features">
        {features.map((feature, index) => {
          const descriptionFocused = focusedDescription === index;
          return (
            <li
              key={index}
              className={feature.highlight ? 'highlight' : undefined}>
              <div className={'plan__itemWrapper'}>
                {!feature.image && (
                  <div className="left">
                    <IconCheckmark />
                    <div className="text">
                      {highlightPlan(
                        feature.name,
                        /\.reset|\.life|\.360°|\.concierge/im
                      )}
                    </div>
                  </div>
                )}
                {feature.image && (
                  <div className="left">
                    <img height="150" src={feature.image} />
                    <div className="text">
                      {highlightPlan(
                        feature.name,
                        /\.reset|\.life|\.360°|\.concierge/im
                      )}
                    </div>
                  </div>
                )}
                <a className="cta">
                  {feature.description && !descriptionFocused && (
                    <span
                      onClick={() => {
                        setFocusedDescription(index);
                      }}>
                      <span className="only-desktop">Details</span>
                      <IconArrowDown />
                    </span>
                  )}
                </a>
              </div>
              {descriptionFocused && (
                <React.Fragment>
                  <p className="plan__featureDescription">
                    {feature.description}
                  </p>
                  <Spacer size="s" />
                </React.Fragment>
              )}
              <div className="divider"></div>
            </li>
          );
        })}
      </ul>

      <div className="plan__bottom">
        <PlanPriceVarient plan={plan} variant={variant} />
            { showPurchase ?
              <Button
                onClick={onCheckoutClick}
                eggshell={!isSecondary}
                secondary={isSecondary}
                icon
                long
                as={LinkComponent}
                to={isIPP ? '/talk-to-us?flow=checkout&plan='+plan.id : `/checkout/${plan.id}`}>
                <IconArrow />
                <span className="text">Choose this Program</span>
              </Button>
            : ''}
      </div>
    </div>
  );
};
