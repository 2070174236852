import React, { Component } from 'react';
import { screen } from 'helpers';
import {
  Section,
  Stepper,
  GeneratedQuestion,
  SignupStepContainer,
  Button,
} from 'components';

import './questions.less';

import { GlobalContext } from 'context';

function getState({ context }) {
  const questions = context.flagQuestions.map((item) => {
    return {
      ref: React.createRef(),
      answer: '',
      options: [
        {
          name: 'Yes',
          value: 'Yes',
        },
        {
          name: 'No',
          value: 'No',
        },
      ],
      ...item,
    };
  });
  return {
    questions,
  };
}

@screen
export default class Questions extends Component {
  static contextType = GlobalContext;

  state = getState(this);

  componentDidMount() {
    window.scrollTo({ top: 0 });
    this.context.loadFlagQuestions();
    window?.gtag('event', 'view_redflag_questions');
  }

  setField = (evt, { name, value }) => {
    this.setState({
      questions: this.state.questions.map((question) => {
        return question.id === name
          ? { id: name, ...question, answer: value }
          : question;
      }),
    });
  };

  handleOnSubmit = () => {
    if (!this.isComplete(true)) {
      return;
    }

    // do stuff
    this.context.setFlagQuestions(
      this.state.questions.map(({ ref, ...item }) => item)
    );

    const success = this.state.questions.every(({ answer, id }) => {
      if (id === 'R1') {
        return answer === 'Yes';
      } else {
        return answer === 'No';
      }
    });

    window?.gtag(
      'event',
      success ? 'redflag_questions_completed' : 'redflag_questions_failed'
    );

    this.props.onDone(success);
  };

  isComplete = (scroll) => {
    const item = this.state.questions.find(({ answer }) => !answer);
    if (scroll && item && item.ref.current) {
      item.ref.current.scrollIntoView({
        behavior: 'smooth',
      });
      return false;
    }
    return !item;
  };

  render() {
    return (
      <div className="stepQuestions">
        <Section color={'wellness'} style={{ borderRadius: 0 }}>
          <Stepper
            current={this.props.step - 1}
            steps={this.props.numberOfSteps}
          />
          <SignupStepContainer title="first, we’d like to ask you a few questions.">
            <form
              className="stepQuestions__form"
              onSubmit={this.handleOnSubmit}>
              {this.state.loading && <div>Loading....</div>}

              {!this.state.loading && (
                <>
                  <div className="stepQuestions__content">
                    {this.state.questions.map(
                      ({ ref, description, ...question }, index) => (
                        <div key={description} ref={ref}>
                          <GeneratedQuestion
                            number={index + 1}
                            onChange={this.setField}
                            value={question.answer}
                            content={description}
                            {...question}
                          />
                        </div>
                      )
                    )}
                  </div>
                  <div className="stepQuestions__submit">
                    <div />
                    <Button
                      fluid
                      nueblue
                      disabled={!this.isComplete()}
                      onClick={this.handleOnSubmit}>
                      Continue
                    </Button>
                  </div>
                </>
              )}
            </form>
          </SignupStepContainer>
        </Section>
      </div>
    );
  }
}
