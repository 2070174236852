import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './dialog.less';
import { Button } from '../Button';
import { IconClose } from '../Icons';
import classNames from 'classnames';

export class Dialog extends Component {
  componentDidMount() {
    document.body.style.overflow = 'hidden';
    document.addEventListener('keydown', this.onKeyDown, false);
  }

  componentWillUnmount() {
    document.body.style.overflow = 'unset';
    document.removeEventListener('keydown', this.onKeyDown, false);
  }

  onKeyDown = (event) => {
    if (event.keyCode === 27) {
      this.props.onClose();
    }
  };

  onClose = () => {
    this.props.onClose();
  };

  renderDialog() {
    return (
      <div
        onClick={(e) => {
          if (e && e?.target?.className?.match('dialog__background')) {
            this.onClose();
          }
        }}
        className={classNames('dialog__background', this.props.className)}>
        <Button pill eggshell onClick={this.onClose} className="dialog__close">
          <IconClose />
        </Button>
        <div className="dialog__container">{this.props.children}</div>
      </div>
    );
  }

  render() {
    return ReactDOM.createPortal(this.renderDialog(), document.body);
  }
}
