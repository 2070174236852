import React from 'react';

import styles from './feature.module.less';

export const Feature = ({ step, image, name, header, children, controls }) => {
  return (
    <div className={styles.feature}>
      <h4>{header}</h4>
      <div className={styles.content}>
        <div className={styles.textContent}>
          <div className={styles.stepNumber}>{step}.</div>
          <div>
            <div className={styles.name}>{name}</div>
            <p className={styles.description}>{children}</p>
          </div>
          {controls}
        </div>
        <div className={styles.image}>
          <img src={image} />
        </div>
      </div>
    </div>
  );
};
