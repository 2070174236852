import React from 'react';


export const IconLeft = (
    props
) => {
    let width = props.width || 55;
    let height = props.height || 24;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={"0 0 " + {width} + " " + {height}} fill="none" display={'block'}>
            <path d="M0.939343 10.9393C0.353558 11.5251 0.353558 12.4749 0.939342 13.0607L10.4853 22.6066C11.0711 23.1924 12.0208 23.1924 12.6066 22.6066C13.1924 22.0208 13.1924 21.0711 12.6066 20.4853L4.12132 12L12.6066 3.51472C13.1924 2.92893 13.1924 1.97918 12.6066 1.39339C12.0208 0.807608 11.0711 0.807608 10.4853 1.39339L0.939343 10.9393ZM55 10.5L2 10.5L2 13.5L55 13.5L55 10.5Z" fill="#0D345C"/>
        </svg>
    )
}

export const IconRight = (
    props
) => {
    let width = props.width || 55;
    let height = props.height || 24;
    return (
        <svg width={width} height={height} viewBox={"0 0 " + {width} + " " + {height}} fill="none" xmlns="http://www.w3.org/2000/svg" display={'block'}>
            <path d="M54.0607 13.0607C54.6464 12.4749 54.6464 11.5251 54.0607 10.9393L44.5147 1.3934C43.9289 0.807611 42.9792 0.807611 42.3934 1.3934C41.8076 1.97919 41.8076 2.92893 42.3934 3.51472L50.8787 12L42.3934 20.4853C41.8076 21.0711 41.8076 22.0208 42.3934 22.6066C42.9792 23.1924 43.9289 23.1924 44.5147 22.6066L54.0607 13.0607ZM0 13.5L53 13.5V10.5L0 10.5L0 13.5Z" fill="#FFD886"/>
        </svg>
    )
}