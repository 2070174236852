import React from 'react';

import { Select } from '../Select';
import states from 'utils/us-states';

const options = Object.keys(states).map((value) => {
  return {
    value,
    text: states[value],
  };
});

export const StateSelect = ({
  label = 'State',
  name,
  value,
  onChange = () => {},
  filter,
  error,
  disabled,
  prefered = [],
}) => {
  let sortedOptions = [...options];
  if (prefered.length) {
    sortedOptions = [
      { value: '', text: '' },
      ...options.filter((c) => prefered.includes(c.value)),
      {
        key: '---',
        value: '',
        text: '-----------------------',
        disabled: true,
      },
      ...options.filter((c) => !prefered.includes(c.value)),
    ];
  } else {
    sortedOptions.unshift({
      key: 'something',
      value: '',
      text: '',
    });
  }

  return (
    <Select
      required
      label={label}
      error={error}
      options={filter ? filter(sortedOptions) : sortedOptions}
      name={name}
      disabled={disabled}
      value={value || ''}
      onChange={onChange}
    />
  );
};
