import React, { Component } from 'react';
import { screen } from 'helpers';

import { Redirect, withRouter } from 'react-router';
import { Switch, Route } from 'react-router-dom';

import Start from './steps/Start';
import FlagQuestions from './steps/FlagQuestions';
import Account from './steps/Account';

import Confirm from './steps/Confirm';
import { GlobalContext } from 'context';
import TalkWithUs from './steps/TalkWithUs';
import Plans from 'screens/Plans';
import PlansDrawer from 'screens/Plans';

const metaPerPlan = {
  'reset': {
    title: "Nue Life Reset Mental Health Program | Nue Life",
    description: "nue.reset is ideal if you are exploring psychedelic therapy for the first time. Try our 1 month mental health program with online ketamine treatments today!"
  },
  'you': {
    title: "Nue You Mental Health Program | Nue Life",
    description: "In addition to the features of our one-month program, nue.you includes three months of maintenance support. 4 month mental health program with online ketamine treatments"
  }
};

@withRouter
@screen
export default class SignupStart extends Component {
  static contextType = GlobalContext;

  state = {
    isAvailable: true,
    personalInfoFields: {
      acceptedTerms: false,
      email: '',
      firstName: '',
      gender: '',
      lastName: '',
      phone: '',
      dob: '',
      state: '',
    }
  };

  componentDidMount() {
    let formVariant = window.localStorage.getItem('form_variant');
    //let variant = window.localStorage.getItem('variant') ||  window.sessionStorage.getItem('practiceCode') || undefined;
    let variant = window.localStorage.getItem('variant') || undefined;

    if (variant != undefined && formVariant != undefined && !formVariant.includes('variant:')) {
      window.localStorage.setItem('form_variant', formVariant + '[variant:' + variant + ']');
    } else if (variant != undefined) {
      window.localStorage.setItem('form_variant', 'Nue life digital sale [variant:' + variant + ']');
    } else if (formVariant != undefined) {
      if (formVariant.includes('IPP') && !formVariant.includes('digital sale')) {
        window.localStorage.setItem('form_variant', 'Nue life Lead form IPP');
      } else if (formVariant && formVariant.includes('IPP')) {
        window.localStorage.setItem('form_variant', 'Nue life digital sale IPP');
      } else {
        window.localStorage.setItem('form_variant', 'Nue life digital sale');
      } 
    }
    else {
      window.localStorage.setItem('form_variant', 'Nue life digital sale');
    }
    
    let practiceCode = window.sessionStorage.getItem('practiceCode');
    if (practiceCode != 'nuelife' && practiceCode != undefined){
      let newFormVariant = window.localStorage.getItem('form_variant') + " [practice: " + practiceCode + "]";
      window.localStorage.setItem('form_variant', newFormVariant);
    }
    
    

  }

  render() {
    const planId = this.props.match.params?.plan;
    const plan = this.context.plans.find((c) => c.id === planId);

    return (
      <Switch>
        <Route
          path="/checkout/:plan/questions"
          render={() => (
            <FlagQuestions
              isAvailable={this.state.isAvailable}
              step={1}
              planId={planId}
              numberOfSteps={3}
              title="first, let’s see if ketamine therapy is right for you."
              onDone={(passed) => {
                if (passed) {
                  this.props.history.push(`/checkout/${planId}/account`);
                } else {
                  this.props.history.push(`/checkout/${planId}/talk-with-us`);
                }
              }}
            />
          )}
          exact
        />
        <Route
          path="/checkout/:plan/account"
          component={() => (
            <Account
              flow="checkout"
              step={2}
              numberOfSteps={4}
              createLead
              setPersonalInfoFields={(fields) => this.setState(fields)}
              plan={plan}
              onDone={(personalInfoFields) => {
                this.setState({ ...this.state, personalInfoFields: {...this.state.personalInfoFields, ...personalInfoFields} });
                this.props.history.push(`/checkout/${planId}/confirm`);
              }}
            />
          )}
          exact
        />
        <Route
          path="/checkout/:plan/confirm"
          component={() => (
            <Confirm 
              step={3} 
              numberOfSteps={4} 
              plan={plan} 
              planId={planId} 
              personalInfo={this.state.personalInfoFields}
            />
          )}
          exact
        />

        <Route
          path="/checkout/:plan/talk-with-us"
          component={() => (
            <TalkWithUs
              planId={planId}
              onDone={() => {
                this.props.history.push(`/talk-with-us`);
              }}
            />
          )}
          exact
        />
        <Route
          path="/checkout/select"
          render={() => (
            <PlansDrawer
              flow="checkout"
            />
          )}
        />
        <Route
          path="/checkout/:plan"
          render={() => (
            <Start
              flow="checkout"
              planId={planId}
              history={this.props.history}
              onDone={(personalInfoFields) => {
                window.sessionStorage.setItem('startCompleted', true);
                this.setState({ ...this.state, personalInfoFields: {...this.state.personalInfoFields, ...personalInfoFields} })
                if (this.context.email !== "" && this.context.checkoutCode !== "") {
                  this.props.history.push(`/checkout/${planId}/account`);
                } else {
                  this.props.history.push(`/checkout/${planId}/questions`);
                }
              }}
              meta={metaPerPlan[planId]}
            />
          )}
          exact
        />
        <Redirect to={`/checkout/${planId}`} />
      </Switch>
    );
  }
}
