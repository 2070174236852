import React from 'react';
import { screen } from 'helpers';
import { GlobalContext } from 'context';
import { request } from 'utils/api';
import {
  Section,
  Stepper,
  Layout,
  ErrorDialog,
  SignupStepContainer,
  Loader,
  Button,
} from 'components';
import { trackOnce } from 'utils/tracking';

// import { IconArrowRight, IconArrowLeft } from 'components/Icons';

import './call.less';

import { PUBLIC_TOKEN, RECAPTCHA } from 'utils/env';
import { getFormVariant, getVariationId } from 'utils/optimize';
import { CalendarDay } from 'components/CalendarDay';
import { camelCase } from 'lodash';

import { withRouter } from 'react-router';

const params = new URLSearchParams(window.location.search);

@withRouter
@screen
export default class Call extends React.Component {
  static contextType = GlobalContext;

  calendarRef = React.createRef();

  state = {
    startDate: new Date(),
    loading: true,
    error: undefined,
    intentId: params.get('intent'),
  };

  componentDidMount() {
    window.scrollTo({ top: 0 });
    window?.gtag('event', 'view_call');
    window?.gtag('event','submitted_account_form');

    this.state.intentId ? this.getExistingQueue() : this.getQueue();
  }

  loadCaptcha = (callback) => {
    console.log('captcha')
    window.grecaptcha.ready(() => {
      window.grecaptcha
        ?.execute(RECAPTCHA, {
          action: 'submit',
        })
        .then((token) => {
          this.state.intentId ? this.getExistingQueue() : this.getQueue(token);
        });
    });
  };

  getExistingQueue = async () => {
    try {
      const items = await this.getDailyAvailabilityForQueue(
        this.state.intentId
      );
      this.setState({
        externalId: this.state.intentId,
        selected: items[0]?.date,
        items,
        loading: false,
        action: 'schedule',
      });
    } catch (e) {
      this.setState({
        error: e,
      });
    }
  };

  mapmultiSalesforceToSingle = (questions) => {
    let result = [];
    questions.forEach((question) => {
      let options = question.options;
      options.forEach((op) => {

        let value = question.answer.includes(op.value) ? op.salesforceNegative : op.salesforcePositive;

        if (op.salesforceId) {
          result.push({
            flag: op.salesforceId,
            answer: value,
            salesforceId: op.salesforceId
          });
        }

      });
    });

    return result;
  }

  async getQueue() {
    const { account, leadQuestions, startInfo } = this.context;
    const flagQuestions = this.context.flagQuestions.filter(
      (c) => c.answer?.length
    );

    let ttuVersion = (window.localStorage.getItem('ttu_version') || 'v1').includes('v2'); //true means v2, false v1
    let questions = [];

    if (ttuVersion) {
      let singleQuestions = leadQuestions.filter(q => q.multiSalesforce === false || q.multiSalesforce === undefined);
      questions = singleQuestions.map((c) => {
        return {
          flag: c.id,
          answer: Array.isArray(c.answer) ? c.answer.join(', ') : c.answer,
          salesforceId: c.salesforceId
        };
      });
      let multiQuestions = leadQuestions.filter(q => q.multiSalesforce === true);

      this.mapmultiSalesforceToSingle(multiQuestions).forEach((c) => {
        questions.push(c);
      });
    } else {
      questions = leadQuestions.map((c) => {
        return {
          flag: c.id,
          answer: Array.isArray(c.answer) ? c.answer.join(', ') : c.answer
      }});
    }

    let variant = window.localStorage.getItem('variant') || undefined;
    let formVariant = window.localStorage.getItem('form_variant') || 'Nue life lead form';
    let practiceCode = window.sessionStorage.getItem('practiceCode') || 'nuelife';

    try {
      const { content } = await request({
        method: 'POST',
        path: '/v1/router/web-form',
        body: {
          gender: account.gender,
          firstName: account.firstName,
          idmeGroup: account.idmeGroup,
          idmeUuid: account.idmeUuid,
          idmeVerificationAttempt: account.idmeVerificationAttempt,
          lastName: account.lastName,
          email: account.email.toLowerCase().trim(),
          phone: account.phone,
          dateOfBirth: startInfo.dateOfBirth,
          billingAddress: account.billingAddress,
          createLead: true,
          shippingAddress: account.shippingAddress,
          zipCode: account.zipCode,

          entry_origin: window.sessionStorage.entry_origin,
          utmSource: window.localStorage.utm_source,
          utmMedium: window.localStorage.utm_medium,
          utmTerm: window.localStorage.utm_term,
          utmContent: window.localStorage.utm_content,
          utmCampaign: window.localStorage.utm_campaign,
          gclid: window.localStorage.gclid,
          state: startInfo.state,

          formVariants: `${formVariant}` + (variant != undefined ? ` [variant: ${variant}]` : '') + (practiceCode != 'nuelife' ? ` [practice: ${practiceCode}]` : ''),
          /* Answers */
          //...answers,
          redFlagAnswers: [],
          leadQuestions: questions,
          recaptchaToken: 'empty',
          v2: ttuVersion,
          practiceCode: practiceCode
        },
        headers: {
          Authorization: `Basic ${PUBLIC_TOKEN}`,
        },
      });

      const parsedUrl = new URL(content);
      const intentId = parsedUrl.searchParams.get('intent');
      if (!intentId) {
        throw new Error('API: No queue found');
      }

      const items = await this.getDailyAvailabilityForQueue(intentId);

      const newState = {
        intentId: intentId,
        selected: items[0]?.date,
        items,
        loading: false,
      };

      if (intentId) {
        window.sessionStorage.setItem('intent', intentId);
      }

      if (!(await this.getIsAvailableNow())) {
        newState.action = 'schedule';
      }

      this.setState(newState);
    } catch (e) {
      this.setState({
        error: e,
      });
    }
  }

  getIsAvailableNow = async () => {
    const { content } = await request({
      method: 'GET',
      path: `/v1/scheduler/availability/call-now`,
      headers: {
        Authorization: `Basic ${PUBLIC_TOKEN}`,
      },
    });
    return content;
  };

  getDailyAvailabilityForQueue = async (intentId) => {
    const { content } = await request({
      method: 'GET',
      path: `/v1/scheduler/availability/queue/${intentId}`,
      headers: {
        Authorization: `Basic ${PUBLIC_TOKEN}`,
      },
    });
    const items = (content.availability || content).filter((c) => c.available);

    return items;
  };

  bookSlot = async (slot) => {
    this.setState({ loading: true });
    try {
      const { content } = await request({
        method: 'POST',
        path: '/v1/scheduler/availability/schedule-appointment',
        body: {
          intentId: this.state.intentId,
          slotExternalIds: [slot.doctorSlotList[0].slotExternalId],
          date: slot.date,
          clientZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone
        },
        headers: {
          Authorization: `Basic ${PUBLIC_TOKEN}`,
        },
      });

      trackOnce('scheduled');
      this.props.history.push(
        `/call/booked?timeslot=${Date.parse(slot.utcDateTime)}`
      );
    } catch (e) {
      this.setState({ error: e, loading: false });
    }
  };

  triggerCallNow = async () => {
    this.setState({
      action: 'call-now',
      loading: true,
    });

    try {
      const { success, content } = await request({
        method: 'POST',
        path: '/v1/scheduler/availability/call-now',
        body: {
          phone: this.context.account.phone,
          email: this.context.account.email,
        },
        headers: {
          Authorization: `Basic ${PUBLIC_TOKEN}`,
        },
      });

      if (!success || !content.success) {
        this.setState({
          action: 'schedule',
          loading: false,
        });
        return;
      }

      this.setState({
        callId: content.callId,
        loading: false,
      });
      if (content.callId) {
        this.startQueryCallStatus();
      }
    } catch (e) {
      this.setState({
        error: e,
        loading: false,
      });
    }
  };

  startQueryCallStatus = () => {
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.timer = setInterval(() => this.queryCallStatus(), 5000);
  };

  queryCallStatus = async () => {
    try {
      const { content } = await request({
        method: 'GET',
        path: `/v1/scheduler/availability/call-status`,
        params: {
          callId: this.state.callId,
        },
        headers: {
          Authorization: `Basic ${PUBLIC_TOKEN}`,
        },
      });

      if (['Success', 'Finished'].includes(content.callStatus)) {
        this.props.history.push('/call/success');
        return;
      } else if (content.callStatus !== 'InProgress') {
        clearInterval(this.timer);
        this.setState({
          message:
            'We are not able to reach you at the moment, please schedule a call to talk to us.',
          schedule: 'schedule',
        });
      }
    } catch (e) {
      clearInterval(this.timer);
      this.setState({
        error: e,
        message:
          'We are not able to reach you at the moment, please schedule a call to talk to us.',
        schedule: 'schedule',
      });
    }
  };

  triggerSchedule = () => {
    this.setState({
      action: 'schedule',
    });
  };

  render() {
    const { loading, items, intentId } = this.state;
    return (
      <>
      <div className="stepCall">
        {this.state.error && (
          <ErrorDialog
            title="Oops something went wrong"
            message={this.state.error.message}
            onClose={() =>
              this.setState({
                error: undefined,
              })
            }
          />
        )}

        <Section changeyellow style={{ borderRadius: 0 }}>
          <>
            {!(window.localStorage.getItem('ttu_version') || '').includes('v2') ?
              <Stepper
                current={this.props.step - 1}
                steps={this.props.numberOfSteps}
              /> :
              <div style={{paddingBottom: "2em"}}></div>
            }
            <SignupStepContainer title="last step: talk with our sales experts">
              <Layout stackable>
                {loading && (
                  <Layout center className="loader-wrapper">
                    <Loader />
                  </Layout>
                )}

                {!this.state.action && !loading && (
                  <Layout center>
                    <div className="connectForm">
                      <h2 className="bodycopy1">
                        Thank you{' '}
                        {this.context.account && (
                          <>
                            {this.context.account.firstName}{' '}
                            {this.context.account.lastName}
                          </>
                        )}
                        .
                      </h2>
                      <p className="bodycopy2">
                        How would you like to connect with us.
                      </p>

                      <div>
                        <Button fluid onClick={() => this.triggerSchedule()}>
                          Schedule a call
                        </Button>
                      </div>
                      <div>
                        <Button fluid onClick={() => this.triggerCallNow()}>
                          Call now
                        </Button>
                      </div>
                    </div>
                  </Layout>
                )}

                {!loading && this.state.action === 'call-now' && (
                  <div className="callNowForm">
                    <p>
                      One of our sales experts is about to start the call.
                      Please wait ...
                    </p>

                    <Layout center className="loader-wrapper">
                      <Loader />
                    </Layout>
                  </div>
                )}

                {!loading && this.state.action === 'schedule' && (
                  <div className="stepScheduleAppointment__content left-space">
                    <h1 className="h1">
                      Please select the day and time that is the most convenient
                      for you.
                    </h1>

                    {this.state.message && (
                      <p className="bodycopylong">{this.state.message}</p>
                    )}

                    <p className="bodycopylong">A call lasts 15 minutes.</p>

                    <div>
                      {items?.length === 0 && (
                        <p className="bodycopylong">No slots available</p>
                      )}
                      {(items || []).map((item) => {
                        return (
                          <CalendarDay
                            onOpen={(open) => {
                              this.setState({
                                selected: open ? item.date : null,
                              });
                            }}
                            bookSlot={this.bookSlot}
                            queueId={intentId}
                            key={item.date}
                            open={item.date === this.state.selected}
                            {...item}
                          />
                        );
                      })}
                    </div>
                  </div>
                )}
              </Layout>
            </SignupStepContainer>
          </>
        </Section>
      </div>
      </>
    );
  }
}
