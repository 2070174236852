import React, { useEffect, useState } from "react";
import './styles.less';
import { AssementFooter } from "../components/AssessmentFooter";
import { Checkbox, FormGroup, Grid, FormControlLabel, useRadioGroup } from "@mui/material";
import RadioButtonUncheckedTwoToneIcon from '@mui/icons-material/RadioButtonUncheckedTwoTone';
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';
import { update } from "lodash";
import { GlobalContext } from 'context';
import { useContext } from "react";
import NotQualified from "./NotQualified";
import { ReadMore } from "../components/ReadMore";
import { useWindowSize } from "usehooks-ts";
import { ProgressBar } from "components/ProgressBar";


export default function Question({
    question = {},
    answers = [],
    setAnswers = () => {},
    onBack = () => {},
    onNext = () => {},
    questionIndex = 0
}) {
    const context = useContext(GlobalContext);
    const [selected, setSelected] = useState([]);
    const [disquaified, setDisqualified] = useState(false);
    const [moveNext, setMoveNext] = useState(false);
    const screenSize = useWindowSize();

    useEffect(() => {
        if (answers !== undefined && answers !== null) {
            setSelected(answers.filter((item) => item.id === question.id)[0]?.answer || []);
        }
    }, [question])

    useEffect(() => {
        if (moveNext) {
            onNext();
            setMoveNext(false);
        }
    }, [moveNext])


    const updateQuestions = () => {
        let questionWithAnswer = question;
        questionWithAnswer.answer = selected;

        let prevAnswers = answers;
        answers = prevAnswers.filter((item) => item.id !== question.id);
        setAnswers([...answers, questionWithAnswer]);
        context.setLeadQuestions([...answers, questionWithAnswer])
    }

    const back = () => {
        updateQuestions();
        onBack();
    }

    const validateDisqualifying = (selected, disqualifyngValues) => {
        return selected.some((item) => disqualifyngValues.includes(item));
    }

    const next = () => {
        updateQuestions();
        if (question.disqualifying === true && validateDisqualifying(selected, question.disqualifyingValue)) {
            setDisqualified(true);
            return;
        }
        setMoveNext(true);
    }

    if (question !== null && question !== undefined && !disquaified) {
        return (
        <div>
            {/*<ProgressBar
                questionIndex={questionIndex + 3}
        />*/}
            <div className='background'>      
                <Grid container justifyContent={'center'} alignContent={'center'} className='question_container'>
                    <Grid item xs={0} md={2}/>
                    <Grid item xs={12} md={8}>
                        <div className='question_title_container'>
                            <h1 className='label question_upper_title'>
                                {question.upperTitle}
                            </h1>
                            <h1 className='label question_title'>
                                {question.content}
                            </h1>
                            <h1 className='label question_subtitle'>
                                {(question.subtitle !== undefined && question.subtitle.length >= 150 && screenSize.width < 600) ? (
                                    <ReadMore limit={150}>
                                        {question.subtitle}
                                    </ReadMore>)
                                    : <div style={{textAlign: 'center'}}>{question.subtitle}</div>
                                }
                            </h1>
                        </div>
                    </Grid>
                    <Grid item xs={0} md={2}/>
                    <Grid item xs={0} md={2}/>
                    <Grid item xs={10} md={8} height={"50vh"}>
                        <div className='question_option_container'>
                            <FormGroup sx={{alignContent: 'center'}}>
                                {question.options?.map((option, index) => {
                                    return (
                                        <FormControlLabel
                                            key={index + option}
                                            control={<Checkbox value={option.value}
                                                icon={<RadioButtonUncheckedTwoToneIcon/>}
                                                checkedIcon={<CheckCircleOutlineTwoToneIcon/>}
                                                checked={selected.includes(option.value)}
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        if (e.target.value === "None of the above") {
                                                            setSelected([e.target.value]);
                                                            return;
                                                        }
                                                        setSelected([...selected, option.value]);
                                                    } else {
                                                        setSelected(selected.filter((item) => item !== option.value));
                                                    }
                                                }}
                                                sx={{ padding: '0 0.5em 0.5em 0'}}
                                                disabled={((question.multi === false || question.multi === undefined) && selected.length > 0 && !selected.includes(option.value)) || (selected.includes("None of the above") && option.value !== "None of the above")}
                                            />}
                                            label={option.name}
                                            sx={{
                                                textAlign: 'left',
                                                wordBreak: 'break-word',
                                                alignItems: 'flex-start',
                                                paddingLeft: '1em'
                                            }}
                                            className={'form_option_label'}
                                        />
                                    )
                                })}
                            </FormGroup>
                        </div>
                    </Grid>
                    <Grid item xs={0} md={2}/>
                </Grid>
                <AssementFooter
                    onBack={back}
                    onNext={next}
                    disableNext={selected.length === 0 && question.skippable !== true}
                    secondary
                />
            </div>
        </div>
        );
    } else if (disquaified) {
      return (
        <NotQualified
            message={question.disqualifyngText} />
      )
    } else
        return <></>;
    }