import React, { Component } from 'react';
import { screen } from 'helpers';
import { Redirect, withRouter } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import Start from './steps/Start';
import DateAndState from './steps/DateAndState';
import OutOfState from './steps/OutOfState';
import ThankYou from './steps/ThankYou';
import QuestionnaireStart from './steps/QuestionnaireStart';
import YourEmail from './steps/YourEmail';
import Questionnaire from './steps/Questionnaire';
import End from './steps/End';
import { StatementBanner } from './components/StatementBanner';
import Congratulations from './steps/Congratulations';


@withRouter
@screen
export default class TalkToUs extends Component {

  componentDidMount() {
    window?.gtag('event', 'digital_lead_begin');
    try {
      const searchParams = new URL(document.location).searchParams;

      if (searchParams != null){
        let flow = searchParams.get('flow') == "checkout";
        let plan = searchParams.get('plan');
        let practiceCode = searchParams.get('practice_code');
        let isValid = true;
        flow 
          ? window.sessionStorage.setItem('flow', 'checkout') 
          : isValid = false;
        (plan == "reset" || plan == "you") 
            ? window.sessionStorage.setItem('plan', plan) 
            : isValid = false; 
        window.sessionStorage.setItem('validQueryParams',isValid);
        if(practiceCode != undefined){
          window.sessionStorage.setItem('practiceCode', practiceCode);
        }
            
            
                 
        if (flow) {
          this.localStorage.setItem('form_variant', 'Nue life digital sale');
        }
      }
    } catch {}
    
  }

  render() {
    return (
      <Switch>
        <Route path="/talk-to-us/3" component={() => 
          <QuestionnaireStart history={this.props.history} nextRoute={'/talk-to-us/email'} prevRoute={'/talk-to-us/state'} />} 
        exact />
        <Route path="/talk-to-us/email" component={() => 
          <YourEmail history={this.props.history} prevRoute={'/talk-to-us/3'} nextRoute={'/talk-to-us/questions'} />} 
        exact />
        <Route path="/talk-to-us/questions" component={() => 
          <Questionnaire history={this.props.history} prevRoute={'/talk-to-us/3'} scheduleRoute={'/talk-to-us/schedule-call'} congratRoute={'/talk-to-us/congratulations'} />} 
        exact />
        <Route path="/talk-to-us/thank-you" component={() => 
          <ThankYou />} exact />
        <Route path="/talk-to-us/out-of-state" component={() => 
          <OutOfState history={this.props.history} nextRoute={'/talk-to-us/thank-you'} />} 
        exact />
        <Route path="/talk-to-us/state" component={() => 
          <DateAndState history={this.props.history} availableRoute={"/talk-to-us/available"} unavailableRoute={"/talk-to-us/out-of-state"} prevRoute={"/talk-to-us"} />} 
        exact />
        <Route path="/talk-to-us/schedule-call" component={() => 
          <End history={this.props.history} prevRoute={'/talk-to-us/questions'} />}  
        exact />
        <Route path="/talk-to-us/congratulations" component={() => 
          <Congratulations history={this.props.history}/> }
        exact />
        <Route path="/talk-to-us/available" 
          component={() => 
            <StatementBanner
                title={'Great! We are available in your area.'}
                subtitle={'Let\'s get you on your way to feeling better.'}
                onBack={() => { this.props.history.push('/talk-to-us/state'); }}
                onNext={() => { this.props.history.push('/talk-to-us/3'); }}
            />} 
          exact />        
        <Route path="/talk-to-us" component={() => 
          <Start history={this.props.history}/>} 
        exact />
        <Redirect to="/talk-to-us" />
      </Switch>
    );
  }
}