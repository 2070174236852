function getCookieValue(cookieName) {
  var result = document.cookie.match(
    '(^|;)\\s*' + cookieName + '\\s*=\\s*([^;]+)'
  );

  return result ? result.pop() : '';
}

export function getVariationId() {
  var cookie = getCookieValue('_gaexp');
  if (cookie == undefined) {
    return undefined;
  } else {
    var fields = cookie.split('.');
    return fields[4];
  }
}
