import React from 'react';

import { APP_URL } from 'utils/env';

export default ({ to, children, ...props }) => {
  const searchParams = new URLSearchParams();
  searchParams.set('flow','checkout');
  searchParams.set('plan','reset');
       
  if (window.localStorage.getItem('form_variant')) {
    searchParams.set(
      'form_variant',
      window.localStorage.getItem('form_variant')
    );
  }

  if (window.localStorage.getItem('entry_origin')) {
    searchParams.set(
      'entry_origin',
      window.localStorage.getItem('entry_origin')
    );
  }

  if (window.localStorage.getItem('utm_source')) {
    searchParams.set('utm_source', window.localStorage.getItem('utm_source'));
  }
  if (window.localStorage.getItem('utm_medium')) {
    searchParams.set('utm_medium', window.localStorage.getItem('utm_medium'));
  }
  if (window.localStorage.getItem('utm_term')) {
    searchParams.set('utm_term', window.localStorage.getItem('utm_term'));
  }
  if (window.localStorage.getItem('utm_content')) {
    searchParams.set('utm_content', window.localStorage.getItem('utm_content'));
  }

  if (window.localStorage.getItem('utm_campaign')) {
    searchParams.set(
      'utm_campaign',
      window.localStorage.getItem('utm_campaign')
    );
  }
  if (window.localStorage.getItem('gclid')) {
    searchParams.set('gclid', window.localStorage.getItem('gclid'));
  }

  return (
    <a
      {...props}
      href={`${APP_URL}${to}${
        searchParams.toString().length ? `?${searchParams.toString()}` : ''
      }`}>
      {children}
    </a>
  );
};
