import React, { Component, useEffect } from 'react';
import { screen } from 'helpers';
import { merge, set, omit, get } from 'lodash';
import { Grid } from '@mui/material';
import {
  Section,
  Stepper,
  Input,
  Select,
  Layout,
  StateSelect,
  SignupStepContainer,
  ErrorDialog,
  Button,
  Radio
} from 'components';

import { captureError } from 'utils/sentry';

import states from 'utils/us-states';

import { GlobalContext } from 'context';

import './account.less';

import { request } from 'utils/api';
import { PUBLIC_TOKEN, COMPANY_DOMAIN, RECAPTCHA } from 'utils/env';
import { typeToFormErrors } from 'utils/validate';
import { trackOnce } from 'utils/tracking';
import * as yup from 'yup';
import ReCAPTCHA from "react-google-recaptcha";
import { RECAPTCHA_KEY } from "utils/env";

const billingAddress = yup
  .object()
  .shape({
    city: yup.string().required(),
    state: yup.string().required(),
    street1: yup.string().required(),
    street2: yup.string(),
    zipCode: yup.string().required(),
  })
  .required();

const accountSchema = yup
  .object()
  .shape({
    acceptedTerms: yup
      .boolean()
      .oneOf([true], 'This field must be checked')
      .required(),
    textMessageOptIn: yup
      .boolean()
      .oneOf([true], 'This field must be checked')
      .required(),
    email: yup.string().email().required(),
    firstName: yup.string().required(),
    gender: yup.string().required(),
    lastName: yup.string().required(),
    phone: yup
      .string()
      .required()
      .matches(
        ///^\+?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4,6}$/im,
        /^(?!(0))^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        {
          message: 'Must be a valid US phone number.',
          excludeEmptyString: true
        }
      )
      .min(10)
      .max(10),
});

const freeUserSignUpAccountSchema = accountSchema.concat(yup
  .object()
  .shape({
    password: yup.string()
      .matches(
        /^(?=.*[A-Z])(?=.*\d).{8,}$/,
        '8 characters, a number and an uppercase char'
      )
      .required(),
    confirmPassword: yup.string()
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
    captcha: yup.string().required("Please complete the captcha to continue.")
  }));

const freeUserCheckoutSchema = yup
  .object()
  .shape({
    shippingAddress: yup
      .object()
      .shape({
        city: yup.string()
          .trim()
          .required("City is required"),
        state: yup.string()
          .trim()
          .required("State is required"),
        street1: yup.string()
          .trim()
          .required("Street 1 is required"),
        street2: yup.string(),
        zipCode: yup.string()
          .trim()
          .required("Zip code is required"),
      })
      .required()
  })
  .when('$sameAsBilling', (sameAsBilling, schema) =>
    !sameAsBilling
      ? schema.shape({
          billingAddress,
        })
      : schema
);

function getState({ context }) {
  const { startInfo = {} } = context;

  const state = {
    error: undefined,
    fields: {
      acceptedTerms: false,
      email: '',
      firstName: '',
      gender: '',
      lastName: '',
      phone: '',
      idmeUuid: '',
      idmeGroup: '',
      idmeVerificationAttempt: '',
      state: startInfo.state,
      shippingAddress: {
        state: startInfo.state
      },
      password: '',
      confirmPassword: '',
      captcha: '',
      ...context.account,
    },
    fieldsErrors: {},
    verifyingIDMe: false,
    IDMeVerified: false,
  };
  return state;
}

@screen
export default class Account extends Component {
  static contextType = GlobalContext;
  state = getState(this);

  async componentDidMount() {
    window.scrollTo({ top: 0 });
    window?.gtag('event', 'view_account_form');

    const params = new URLSearchParams(window.location.search);
    const IDmeAuthorizationCode = params.get('code');
    this.setField("idmeVerificationAttempt", new Date());
    try {
      if (IDmeAuthorizationCode != undefined) {
        this.setState({verifyingIDMe: true});
        const { content } = await request({
          method: 'GET',
          path: `/v1/id-me/${IDmeAuthorizationCode}`,
          headers: {
            Authorization: `Basic ${PUBLIC_TOKEN}`,
          },
        });
        const response = JSON.parse(content);

        if (response['access_token'] != undefined) {
          const attributes = await request({
            method: 'GET',
            path: `/v1/id-me/attributes/${response['access_token']}`,
            headers: {
              Authorization: `Basic ${PUBLIC_TOKEN}`,
            },
          });
          this.populateFormWithIdMe(attributes['content']);
          this.setState({verifyingIDMe: false, IDMeVerified: true});
          window.history.pushState(this.state, '', '/talk-with-us/account');
        } else {
          this.setState({ error: "something went wrong", loading: false })
          window.history.pushState(this.state, '', '/talk-with-us/account');
          this.setState({verifyingIDMe: false})
        }
      }
    } catch (err) {
      window.history.pushState(this.state, '', '/talk-with-us/account');
      this.setState({verifyingIDMe: false})
      this.setState({ error: err, loading: false })
    }
  }

  populateFormWithIdMe = (payload) => {
    if (payload['attributes'] != undefined && payload['status'] != undefined) {
      payload['attributes'].forEach(a => {
        if (a['handle'] == "fname") this.setField("firstName", a['value']);
        if (a['handle'] == "lname") this.setField("lastName", a['value']);
        if (a['handle'] == "email") this.setField("email", a['value']);
        if (a['handle'] == "uuid") this.setField("idmeUuid", a['value']);
      })
      if (payload['status'][0] != undefined && payload['status'][0]['group'] != undefined) {
        this.setField("idmeGroup", payload['status'][0]['group']);
      }
    } else {
      this.setState({ error: "something went wrong", loading: false })
    }

  }

  canSubmit = () => {
    const { fields } = this.state;
    const validateFields = Object.keys(fields).filter((key) => {
      if (!fields[key].length || key == "idmeUuid" || key == "idmeGroup") {
        return true;
      }
      return false;
    });

    return validateFields.length;
  };

  mapmultiSalesforceToSingle = (questions) => {
    let result = [];
    questions.forEach((question) => {
      let options = question.options;
      options.forEach((op) => {

        let value = question.answer.includes(op.value) ? op.salesforceNegative : op.salesforcePositive;
        if (op.salesforceId) {
          result.push({
            flag: op.salesforceId,
            answer: value,
            salesforceId: op.salesforceId
          });
        }
      });
    });

    return result;
  }

  onCaptchaCompletion = (captcha) => {
    this.setField("captcha", captcha);
  }


  handleOnSubmit = async (e) => {
    e && e.preventDefault();
    const { leadQuestions } = this.context;
    const { fields } = this.state;
    const body = fields;

    try {
      let schemaToValidate = accountSchema;
      if (this.getFlowType() === "free-user-sign-up") schemaToValidate = freeUserSignUpAccountSchema;
      if (this.getFlowType() === "free-user-checkout") schemaToValidate = freeUserCheckoutSchema;

      await schemaToValidate.validate(body, {
        abortEarly: false,
        context: { sameAsBilling: this.state.sameAsBilling },
      });
    } catch (e) {
      console.log(e);
      this.setState({
        fieldsErrors: typeToFormErrors(e),
      });
      return;
    }

    this.setState({ error: undefined, loading: true });
    const cleanBody = omit({ ...body }, ['confirmPassword']);

    try {
      const flagQuestions = this.context.flagQuestions.filter(
        (c) => c.answer?.length
      );

      /*
      const recaptchaContainer = document.getElementById('recaptcha-container');
      await window.grecaptcha?.render(recaptchaContainer, {"sitekey": RECAPTCHA});

      const recaptchaToken = await window.grecaptcha?.execute(RECAPTCHA, {
        action: 'submit',
      });
      */

      let singleQuestions = leadQuestions.filter(q => q.multiSalesforce === false || q.multiSalesforce === undefined);
      let questions = singleQuestions.map((c) => {
        return {
          flag: c.id,
          answer: Array.isArray(c.answer) ? c.answer.join(', ') : c.answer,
          salesforceId: c.salesforceId
        };
      });
      let multiQuestions = leadQuestions.filter(q => q.multiSalesforce === true);

      this.mapmultiSalesforceToSingle(multiQuestions).forEach((c) => {
        questions.push(c);
      });

      const flowTypeToEndpoint = {
        "checkout": "/v1/web/auth/sign-up",
        "free-user-checkout": "/v1/free-user/checkout/information/" + window.sessionStorage.getItem('freeUserCheckoutToken'),
        "free-user-sign-up": "/v1/web/auth/free-user/sign-up"
      }

      if (flowTypeToEndpoint[this.getFlowType()]) {
        const requestBody = {
          ...cleanBody,
          gender: cleanBody.gender,
          idmeGroup: cleanBody.idmeUuid,
          idmeUuid: cleanBody.idmeGroup,
          redFlagAnswers: flagQuestions.map((c) => {
            return {
              flag: c.id,
              answer: c.answer,
            };
          }),
          leadQuestions: questions,
          dateOfBirth: this.context.startInfo.dateOfBirth,
          state: this.context.startInfo.state,
          zipCode: this.context.startInfo.zipCode,
          createLead: this.props.createLead || false,
          formVariants:
            (window.localStorage.getItem('form_variant') ||
              `Nue life lead form`),
          acceptedTerms: true,
          recaptchaToken: 'empty',
          entry_origin: window.localStorage.entry_origin,
          utm_term: window.localStorage.utm_term,
          utmSource: window.localStorage.utm_source,
          utmMedium: window.localStorage.utm_medium,
          utmContent: window.localStorage.utm_content,
          utmCampaign: window.localStorage.utm_campaign,
          gclid: window.localStorage.gclid,
          practiceCode: window.sessionStorage.getItem('practiceCode') || 'nuelife',
          v2: (window.localStorage.getItem('ttu_version') || 'v1').includes('v2'),
        };

        const { content } = await request({
          method: 'POST',
          path: flowTypeToEndpoint[this.getFlowType()],
          body: requestBody,
          headers: {
            Authorization: `Basic ${PUBLIC_TOKEN}`,
          },
        });

        window?.gtag('event','submitted_account_form');
      }

      window?.gtag('event', 'conversion', {
        send_to: 'AW-743375642/WxoiCO7_iY4DEJqGvOIC',
      });

      //window?.fbq('track', 'Lead');

      trackOnce(
        this.props.createLead
          ? 'account_created_buy_flow'
          : 'account_created_talk_to_us'
      );

      const plan = this.props.plan || this.context.plans.find((c) => c.id === this.props.planId);
      if (plan) {
        window?.gtag('event', 'add_shipping_info', {
          currency: 'USD',
          value: plan.price,
          items: [
            {
              item_id: plan.id,
              item_name: plan.name,
              price: plan.price,
              quantity: 1,
            },
          ],
        });
      }

      this.setState({ loading: false });
      this.context.setAccount(body);

      this.props.onDone(this.state.fields);
    } catch (e) {
      captureError(e);
      this.setState({
        error: e,
        loading: false,
      });
    }
  };

  setField = (name, value) => {
    this.setState({
      fields: merge({}, this.state.fields, set({}, name, value)),
      fieldsErrors: {
        ...this.state.fieldsErrors,
        [name]: undefined,
      },
    });
  };

  getFlowType = () => {
    return this.props.flow || window.sessionStorage.getItem('flow');
  }

  getFormInputs = () => {
    return (this.getFlowType() === "free-user-checkout") ? this.getFreeUserCheckoutInputs() : this.getAccountInputs();
  }

  isAllowedState = () => {
    const { availableStates } = this.context;
    return availableStates.includes(
      get(this.state.fields, 'billingAddress.state')
    );
  };

  getFreeUserCheckoutInputs = () => {
    const { fields, fieldsErrors, error } = this.state;
    const { availableStates } = this.context;

    const shippingStates = [
      ...Object.keys(states)
        .filter((state) => availableStates.includes(state))
        .map((value) => {
          return {
            value,
            text: states[value],
          };
        }),
      {
        value: 'NY',
        text: 'New York',
      },
    ];

    return (<>
    <h1 className="h1">Your shipping address</h1>
    <Layout horizontal stackable>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Input
            required
            label="Street address #1"
            name="shippingAddress.street1"
            error={get(fieldsErrors, 'shippingAddress.street1')}
            value={get(fields, 'shippingAddress.street1')}
            onChange={(e, { name, value }) =>
              this.setField(name, value)
            }
          />
        </Grid>
        <Grid item xs={6}>
          <Input
            label="Street address #2"
            name="shippingAddress.street2"
            error={get(fieldsErrors, 'shippingAddress.street2')}
            value={get(fields, 'shippingAddress.street2')}
            onChange={(e, { name, value }) =>
              this.setField(name, value)
            }
          />
        </Grid>
      </Grid>
    </Layout>
    <Layout horizontal stackable>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Input
            required
            label="City"
            name="shippingAddress.city"
            error={get(fieldsErrors, 'shippingAddress.city')}
            value={get(fields, 'shippingAddress.city')}
            onChange={(e, { name, value }) =>
              this.setField(name, value)
            }
          />
        </Grid>
        <Grid item xs={6}>
          <Input
            required
            label="Zip"
            name="shippingAddress.zipCode"
            error={get(fieldsErrors, 'shippingAddress.zipCode')}
            value={get(fields, 'shippingAddress.zipCode')}
            onChange={(e, { name, value }) =>
              this.setField(name, value)
            }
          />
        </Grid>
      </Grid>
    </Layout>
    <Select
      options={shippingStates}
      label="State"
      name="shippingAddress.state"
      error={get(fieldsErrors, 'shippingAddress.state')}
      value={get(fields, 'shippingAddress.state')}
      onChange={(e, { name, value }) => {
        this.setField(name, value);
      }}
    />
    <h1 className="h1">Your billing address</h1>

    {this.isAllowedState && (
      <Radio
        checked={this.state.sameAsBilling}
        onChange={(e, { checked }) => {
          this.setState({
            sameAsBilling: checked,
          });
        }}
        label="Same as shipping address"
        type="checkbox"
      />
    )}
    {(!this.state.sameAsBilling || !this.isAllowedState) && (
      <>
        <Layout horizontal stackable>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Input
                label="Street address #1"
                name="billingAddress.street1"
                value={get(fields, 'billingAddress.street1')}
                error={get(fieldsErrors, 'billingAddress.street1')}
                onChange={(e, { name, value }) =>
                  this.setField(name, value)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                label="Street address #2"
                name="billingAddress.street2"
                value={get(fields, 'billingAddress.street2')}
                error={get(fieldsErrors, 'billingAddress.street2')}
                onChange={(e, { name, value }) =>
                  this.setField(name, value)
                }
              />
            </Grid>
          </Grid>
        </Layout>

        <Layout horizontal stackable>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Input
                label="City"
                name="billingAddress.city"
                value={get(fields, 'billingAddress.city')}
                error={get(fieldsErrors, 'billingAddress.city')}
                onChange={(e, { name, value }) =>
                  this.setField(name, value)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                label="Zip"
                name="billingAddress.zipCode"
                value={get(fields, 'billingAddress.zipCode')}
                error={get(fieldsErrors, 'billingAddress.zipCode')}
                onChange={(e, { name, value }) =>
                  this.setField(name, value)
                }
              />
            </Grid>
          </Grid>
        </Layout>

        <StateSelect
          name="billingAddress.state"
          label="State"
          value={get(fields, 'billingAddress.state')}
          error={get(fieldsErrors, 'billingAddress.state')}
          onChange={(e, { name, value }) =>
            this.setField(name, value)
          }
        />

      </>
    )}
    </>)
  }

  getAccountInputs = () => {
    const { fields, fieldsErrors, error } = this.state;

    return (<>
    <Layout horizontal stackable>
      <Select
        label="Gender"
        required
        name="gender"
        error={fieldsErrors.gender}
        value={fields.gender}
        disabled={this.context.lockCheckoutOptions}
        onChange={(e, { name, value }) => this.setField(name, value)}
        options={[
          { value: '', text: 'Your gender' },
          { value: 'MALE', text: 'Male' },
          {
            value: 'FEMALE',
            text: 'Female',
          },
          {
            value: 'OTHER',
            text: 'Other',
          },
          {
            value: 'DECLINE_TO_SPECIFY',
            text: 'Decline to specify',
          },
        ]}
      />
      <div style={{width: "100%", marginRight: "1em"}}></div> {/*this is to center the gender select*/}
    </Layout>
    <Layout horizontal stackable>
      <Input
        required
        error={fieldsErrors.firstName}
        label="First Name"
        name="firstName"
        value={fields.firstName}
        disabled={this.context.lockCheckoutOptions}
        onChange={(e, { name, value }) => this.setField(name, value)}
      />
      <Input
        required
        label="Last Name"
        name="lastName"
        error={fieldsErrors.lastName}
        value={fields.lastName}
        disabled={this.context.lockCheckoutOptions}
        onChange={(e, { name, value }) => this.setField(name, value)}
      />
    </Layout>
    <Layout horizontal stackable>
      <Input
        required
        label="Email"
        type="email"
        name="email"
        error={fieldsErrors.email}
        value={fields.email}
        disabled={this.context.lockCheckoutOptions}
        onChange={(e, { name, value }) => this.setField(name, value)}
      />
      <Input
        required={!this.context.lockCheckoutOptions}
        disabled={this.context.lockCheckoutOptions}
        label="Phone number"
        type="tel"
        error={fieldsErrors.phone}
        name="phone"
        value={fields.phone}
        onChange={(e, { name, value }) => this.setField(name, value)}
      />
    </Layout>

    {this.props.flow === "free-user-sign-up" &&
      <Layout horizontal stackable>
        <Input
          required
          label="Password"
          type="password"
          name="password"
          error={fieldsErrors.password}
          value={fields.password}
          onChange={(e, { name, value }) => this.setField(name, value)}
        />
        <Input
          required
          label="Confirm password"
          type="password"
          error={fieldsErrors.confirmPassword}
          name="confirmPassword"
          value={fields.confirmPassword}
          onChange={(e, { name, value }) => this.setField(name, value)}
        />
      </Layout>
    }
    {/*{!this.context.lockCheckoutOptions ?*/}
    {/*  this.state.IDMeVerified ?*/}
    {/*    "Succesfully verified with ID.me"*/}
    {/*    :*/}
    {/*    <div id="idme-verification">*/}
    {/*      <p style={{ fontSize: "small", marginTop: "1em", marginBottom: "1em" }}>*/}
    {/*        Upon successful verification, book a call with our Welcome team to see if you are eligible for Nue Life and our special pricing.*/}
    {/*      </p>*/}
    {/*      <a href={`https://groups.id.me/?client_id=4f3668f329d5c2726701871a9950f9a9&redirect_uri=https://nue.life/talk-with-us/account&response_type=code&scopes=military,medical,student,responder,teacher,nurse,hospial_employee,government`}>*/}
    {/*        <img src="https://s3.amazonaws.com/idme/developer/idme-buttons/assets/img/verify.svg" height="50"/>*/}
    {/*      </a>*/}
    {/*      <p style={{ fontSize: "small", marginTop: "0.5em" }}>*/}
    {/*        <img src="https://developers.id.me/assets/buttons/lock-icon-a5b18fbb34d393a80967c08399786f95955ee818b693b97d3f37698e2bc113f2.svg" height="10" />*/}
    {/*        Verification by ID.me •*/}
    {/*        <a href="https://www.id.me/about">What is ID.me?</a>*/}
    {/*      </p>*/}
    {/*    </div>*/}
    {/*: <></>}*/}
    <h1 className="h1">please accept our terms & conditions</h1>
    <p className="bodycopy2">
      By creating an account, you agree to our{' '}
      <a
        rel="noreferrer"
        target="_blank"
        href={`${COMPANY_DOMAIN}/terms-conditions/`}>
        Terms of Service.
      </a>{' '}
      <br /> For more information about Nue life's privacy practices,
      see the{' '}
      <a
        rel="noreferrer"
        target="_blank"
        href={`${COMPANY_DOMAIN}/privacy-policy/`}>
        Nue.life Privacy Statement
      </a>
    </p>

    <Radio
      className="acceptTerms"
      error={get(fieldsErrors, 'acceptedTerms')}
      checked={fields.acceptedTerms}
      name="acceptedTerms"
      onChange={(e, { name, checked }) => {
        this.setField(name, checked);
      }}
      label={<>I agree to the terms and conditions</>}
      type="checkbox"
    />

    <p className="bodycopy2">
      By checking the box below, I authorize Nue Life and its
      affiliates to contact me by automated SMS text message. I may
      opt out anytime by choosing Opt-Out option at the end of the
      text message. Opting out of automated SMS messaging shall not
      restrict my receiving of services, subject to the{' '}
      <a
        rel="noreferrer"
        target="_blank"
        href={`${COMPANY_DOMAIN}/terms-conditions/`}>
        terms and conditions.
      </a>{' '}
      I understand that message/data rates may apply to messages sent
      by Nue Life Health or its affiliates under my cell phone plan
    </p>

    <Radio
      className="acceptTerms"
      error={get(fieldsErrors, 'textMessageOptIn')}
      checked={fields.textMessageOptIn}
      name="textMessageOptIn"
      onChange={(e, { name, checked }) => {
        this.setField(name, checked);
      }}
      label={<>I agree to SMS text messaging policy</>}
      type="checkbox"
    />
  </>)
  }

  render() {
    const { fields, fieldsErrors, error } = this.state;


    return (
      <>
      <div className="stepAccount">
        {error && (
          <ErrorDialog
            title="Something went wrong"
            message={error.message}
            onClose={() => this.setState({ error: undefined })}
          />
        )}
        <Section
          color={this.props.flow === 'checkout' ? 'wellness' : 'changeyellow'}
          style={{ borderRadius: 0 }}>
          {!(window.localStorage.getItem('ttu_version') || 'v1').includes('v2') ?
            <Stepper
              current={this.props.step - 1}
              steps={this.props.numberOfSteps}
            /> :
            <div style={{paddingBottom: "2em"}}></div>
          }

          <SignupStepContainer
            title={this.getFlowType() === "free-user-checkout" ? "finally, tell us where you live" : "next, let’s set up your account"}
            /*subtitle={
              'Congratulations!\n' +
              'Based on your responses, Nue Life could be a great fit for you.\n' +
              'Let\'s set up your account to get started!'
            }*/
          >
            <form onSubmit={this.handleOnSubmit}>
              {this.getFormInputs()}
              {this.getFlowType() === "free-user-sign-up" &&
                <>
                <div style={{ border: fieldsErrors.captcha ? '2px solid red' : 'none', width: "fit-content" }}>
                  <ReCAPTCHA sitekey={RECAPTCHA_KEY} onChange={this.onCaptchaCompletion} />
                </div>
                </>
              }
              <Layout horizontal stackable>
                <Button
                  fluid
                  nueblue
                  className="submit"
                  loading={this.state.loading}
                  disabled={this.state.loading}
                  onClick={this.handleOnSubmit}>
                  {(this.context.email !== '' && this.context.checkoutCode !== '') || (this.getFlowType() === "free-user-checkout") ?
                    "Proceed to payment" :  "Create Account"
                  }
                </Button>
                <div></div>
              </Layout>
            </form>
          </SignupStepContainer>
        </Section>
      </div>
      </>
    );
  }
}
