import React, { Component } from 'react';
import { screen } from 'helpers';

import { Redirect, withRouter } from 'react-router';
import { Switch, Route } from 'react-router-dom';

import Start from './steps/Start';
import LeadQuestions from './steps/LeadQuestions';
import Account from './steps/Account';
import Thankyou from './steps/Thankyou';
import Call from './steps/Call';
import CPCall from './steps/CPCall';

@withRouter
@screen
export default class SignupStart extends Component {
  componentDidMount() {
    //window?.fbq('track', 'Lead');
    //we check if we do not came from v2 to avoid stepping on it
    !window?.localStorage?.getItem('ttu_version')?.includes('v2') && window.localStorage.setItem('ttu_version', 'v1');
    window?.gtag('event', 'digital_lead_begin');
  }

  render() {
    return (
      <Switch>
        <Route
          path="/talk-with-us/questions"
          render={(props) => (
            <LeadQuestions
              step={1}
              numberOfSteps={4}
              title="first, let’s see if ketamine therapy is right for you."
              onDone={(success) => {
                if (success) {
                  this.props.history.push('/talk-with-us/account');
                } else {
                  this.props.history.push('/talk-with-us/thank-you');
                }
              }}
            />
          )}
          exact
        />
        <Route
          path="/talk-with-us/account"
          component={() => (
            <Account
              step={2}
              numberOfSteps={4}
              onDone={(account) => {
                if (account) {
                  window.sessionStorage.setItem(
                    'account',
                    JSON.stringify(account)
                  );
                }
                this.props.history.push('/talk-with-us/call');
              }}
            />
          )}
          exact
        />
        <Route
          path="/talk-with-us/call"
          component={() => <Call step={3} numberOfSteps={4} />}
          exact
        />
        <Route
          path="/talk-with-us/reschedule"
          component={() => <Call step={0} numberOfSteps={0} />}
          exact
        />
        <Route
          path="/talk-with-us/cp-call"
          component={() => <CPCall step={3} numberOfSteps={4} />}
          exact
        />
        <Route
          path="/talk-with-us/thank-you"
          component={() => <Thankyou />}
          exact
        />
        <Route
          path="/talk-with-us"
          component={() => (
            <Start
              stepHeader={<TalkWithUsHeader />}
              flow="talk"
              history={this.props.history}
              onDone={() => {
                this.props.history.push('/talk-with-us/questions');
              }}
            />
          )}
          exact
        />
        <Redirect to="/talk-with-us" />
      </Switch>
    );
  }
}

const TalkWithUsHeader = () => {
  return (
    <div className="talkWithUsHeader">
      <h3 className="signupStep__title title" style={{ marginBottom: '40px' }}>
        what to expect
      </h3>
      <ol>
        <li>
          Complete our intake form to make sure <strong>ketamine therapy</strong> can help you
          achieve your mental health goals.
        </li>
        <li>
          Schedule a time to speak with our Welcome Team to discuss your
          treatment goals.
        </li>
        <li>
          On our call, discover which of our holistic <strong>ketamine therapy</strong> programs
          is right for you and get started on your healing journey.{' '}
        </li>
      </ol>
    </div>
  );
};
