import React, { Component, useState, useEffect } from 'react';
import { screen } from 'helpers';
import * as Yup from 'yup';
import { PUBLIC_TOKEN } from 'utils/env';
import { request } from 'utils/api';
import { Redirect, withRouter } from 'react-router';
import { Switch, Route } from 'react-router-dom';

import {
  Section,
  Stepper,
  Button,
  SignupStepContainer,
  Input,
  Radio,
  Textarea,
  ErrorDialog,
  Container,
} from 'components';

import { FieldArray, Formik } from 'formik';

@withRouter
@screen
export default class NetworkContact extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    // window?.fbq('track', 'Lead');
    // window?.gtag('event', 'digital_lead_begin');
  }

  render() {
    return (
      <Switch>
        <Route
          path="/contact/schedule"
          component={() => {
            return (
              <Section
                color={'changeyellow'}
                style={{ borderRadius: 0, minHeight: '100vh' }}>
                <Stepper current={2} steps={2} />
                <Container>
                  <h3 className="signupStep__title title">Schedule</h3>
                  <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                    <Calendly height="80vh" />
                  </div>
                </Container>
              </Section>
            );
          }}
          exact
        />
        <Route
          path="/contact"
          render={() => (
            <ContactForm
              nextStep={() => this.props.history.push('/contact/schedule')}
            />
          )}
          exact
        />
        <Redirect to="/contact" />
      </Switch>
    );
  }
}

const Calendly = ({
  minWidth = '320px',
  height = '640px',
  url = 'https://calendly.com/d/d2q-sfz-ghx/nue-life-partnership',
}) => {
  useEffect(() => {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute(
      'src',
      'https://assets.calendly.com/assets/external/widget.js'
    );
    head.appendChild(script);
  }, []);

  return (
    <div
      className="calendly-inline-widget"
      data-url={url}
      style={{ minWidth, height }}
    />
  );
};

const options = [
  { value: 'GP/MM', label: 'General Psychiatry / Medication Management' },
  { value: 'Psycotherapy', label: 'Psychotherapy' },
  {
    value: 'KetPAsync',
    label: 'Ketamine-assisted Psychotherapy (asynchronous)',
  },
  { value: 'KetPSync', label: 'Ketamine-assisted Psychotherapy (synchronous)' },
  { value: 'KetIM', label: 'Ketamine IM' },
  { value: 'KetIV', label: 'Ketamine IV' },
  { value: 'PIT', label: 'Psychedelic Integration Therapy' },
  { value: 'Spravato', label: 'Spravato' },
  { value: 'TMS', label: 'TMS' },
  { value: 'Other', label: 'Other' },
];

const initialForm = {
  firstName: '',
  lastName: '',
  credentials: '',
  email: '',
  phone: '',
  practiceName: '',
  numberClinicians: '',
  modalitiesPractice: [],
  somethingToShare: '',
  hearAboutUs: '',
};

const NetworkContactSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  lastName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  numberClinicians: Yup.number()
    .min(0, 'Must be a positive number')
    .required('Required'),
  modalitiesPractice: Yup.array()
    .of(Yup.string())
    .required('Required')
    .min(1, 'Must offer at least one modality'),
});

const ContactForm = ({ nextStep }) => {
  const [requestError, setRequestError] = useState(null);
  return (
    <div className="stepQuestions">
      {requestError && (
        <ErrorDialog
          title="Oops something went wrong"
          message={requestError}
          onClose={() => setRequestError(null)}
        />
      )}
      <Section color={'changeyellow'} style={{ borderRadius: 0 }}>
        <Stepper current={0} steps={2} />
        <SignupStepContainer
          title={'Submit your information and schedule a call with our team'}>
          <Formik
            initialValues={initialForm}
            validationSchema={NetworkContactSchema}
            onSubmit={async (values) => {
              const { success } = await request({
                method: 'POST',
                path: '/v1/lead-b2b/create',
                headers: {
                  Authorization: `Basic ${PUBLIC_TOKEN}`,
                },
                body: {
                  ...values,
                  modalitiesPractice: values.modalitiesPractice.join(', '),
                  entryOrigin: window.localStorage.entry_origin,
                  utmTerm: window.localStorage.utm_term,
                  utmSource: window.localStorage.utm_source,
                  utmMedium: window.localStorage.utm_medium,
                  utmContent: window.localStorage.utm_content,
                  utmCampaign: window.localStorage.utm_campaign,
                },
              });
              if (success) {
                window?.gtag('event', 'contacted');
                window?.gtag('event', 'conversion', {
                  send_to: 'AW-743375642/pKQDCI3ZxtwDEJqGvOIC',
                });
                nextStep();
              } else {
                setRequestError('Unknown error');
              }
            }}>
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form className="stepQuestions__form" onSubmit={handleSubmit}>
                <div className="stepQuestions__content">
                  <div className="generatedQuestion">
                    <div className="generatedQuestion__number statement-title"></div>
                    <div className="generatedQuestion__content">
                      <div className="generatedQuestion__title bodycopylong">
                        First Name*
                      </div>
                      <Input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.firstName}
                        error={touched.firstName && errors.firstName}
                        name={'firstName'}
                      />
                    </div>
                  </div>
                  <div className="generatedQuestion">
                    <div className="generatedQuestion__number statement-title"></div>
                    <div className="generatedQuestion__content">
                      <div className="generatedQuestion__title bodycopylong">
                        Last Name*
                      </div>
                      <Input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.lastName}
                        error={touched.lastName && errors.lastName}
                        name={'lastName'}
                      />
                    </div>
                  </div>
                  <div className="generatedQuestion">
                    <div className="generatedQuestion__number statement-title"></div>
                    <div className="generatedQuestion__content">
                      <div className="generatedQuestion__title bodycopylong">
                        Credentials (e.g. MD, DO, NP, LPC)
                      </div>
                      <Input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.credentials}
                        name={'credentials'}
                      />
                    </div>
                  </div>
                  <div className="generatedQuestion">
                    <div className="generatedQuestion__number statement-title"></div>
                    <div className="generatedQuestion__content">
                      <div className="generatedQuestion__title bodycopylong">
                        Email*
                      </div>
                      <Input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        error={touched.email && errors.email}
                        name={'email'}
                      />
                    </div>
                  </div>
                  <div className="generatedQuestion">
                    <div className="generatedQuestion__number statement-title"></div>
                    <div className="generatedQuestion__content">
                      <div className="generatedQuestion__title bodycopylong">
                        Phone
                      </div>
                      <Input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.phone}
                        name={'phone'}
                      />
                    </div>
                  </div>
                  <div className="generatedQuestion">
                    <div className="generatedQuestion__number statement-title"></div>
                    <div className="generatedQuestion__content">
                      <div className="generatedQuestion__title bodycopylong">
                        Practice name*
                      </div>
                      <Input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.practiceName}
                        name={'practiceName'}
                      />
                    </div>
                  </div>
                  <div className="generatedQuestion">
                    <div className="generatedQuestion__number statement-title"></div>
                    <div className="generatedQuestion__content">
                      <div className="generatedQuestion__title bodycopylong">
                        # of Clinicians at Practice*
                      </div>
                      <Input
                        min={0}
                        type="number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.numberClinicians}
                        error={
                          touched.numberClinicians && errors.numberClinicians
                        }
                        name={'numberClinicians'}
                      />
                    </div>
                  </div>
                  <div className="generatedQuestion">
                    <div className="generatedQuestion__number statement-title"></div>
                    <div className="generatedQuestion__content">
                      <div className="generatedQuestion__title bodycopylong">
                        Modalities Practice Offers*
                      </div>
                      <FieldArray
                        name="modalitiesPractice"
                        render={(helpers) => {
                          const fieldErr =
                            touched.modalitiesPractice &&
                            errors.modalitiesPractice;
                          return options.map(({ value, label }) => {
                            return (
                              <Radio
                                onChange={(_, { checked }) => {
                                  if (checked) {
                                    helpers.push(value);
                                  } else {
                                    const idx =
                                      values.modalitiesPractice.indexOf(value);
                                    helpers.remove(idx);
                                  }
                                }}
                                checked={values.modalitiesPractice.includes(
                                  value
                                )}
                                error={fieldErr}
                                value={value}
                                name={label}
                                key={value}
                                label={label}
                                type={'checkbox'}
                              />
                            );
                          });
                        }}
                      />
                      {errors.modalitiesPractice && touched.modalitiesPractice && (
                        <div className="input error">
                          <div className={'input__error'}>
                            {errors.modalitiesPractice}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="generatedQuestion">
                    <div className="generatedQuestion__number statement-title"></div>
                    <div className="generatedQuestion__content">
                      <div className="generatedQuestion__title bodycopylong">
                        Anything else you'd like to share about your practice?
                      </div>
                      <Textarea
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.somethingToShare}
                        name={'somethingToShare'}
                      />
                    </div>
                  </div>
                  <div className="generatedQuestion">
                    <div className="generatedQuestion__number statement-title">
                      .
                    </div>
                    <div className="generatedQuestion__content">
                      <div className="generatedQuestion__title bodycopylong">
                        How did you hear about us?
                      </div>
                      <Textarea
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.hearAboutUs}
                        name={'hearAboutUs'}
                      />
                    </div>
                  </div>

                  <div className="stepQuestions__submit">
                    <div />
                    <Button
                      type={'submit'}
                      fluid
                      nueblue
                      disabled={isSubmitting}>
                      Continue
                    </Button>
                  </div>

                  <br />
                  <br />
                  <br />
                </div>
              </form>
            )}
          </Formik>
        </SignupStepContainer>
      </Section>
    </div>
  );
};
