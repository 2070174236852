import React from 'react';
import './app-footer.less';
import { Container } from '../Container';
import { IOS_APP, ANDROID_APP } from 'utils/env';

import footerImg1 from './assets/footer-app-1.png';
import footerImg2 from './assets/footer-app-2.png';
import footerImg1Webp from './assets/footer-app-1.webp';
import footerImg2Webp from './assets/footer-app-2.webp';

export const AppFooter = () => {
  return (
    <div className={'appFooter'}>
      <Container>
        <h3 className="appFooter__title title">Download the Nue Life app for free today</h3>

        <div className="appFooter__content">
          <div className="appFooter__text left-space">
            <p className="bodytext">
              Personalized, affordable at-home therapy supported by the Nue Life
              mobile app.
            </p>
            <div className="appFooter__download">download now on:</div>
            <div className="appFooter__downloadLinks">
              <a
                onClick={() => {
                  window?.fbq('track', 'AppLead');
                  window?.gtag('event', 'app_lead');
                }}
                target="_blank"
                href={IOS_APP}
                rel="noreferrer">
                <img src="/assets/ios-store-v2.png" />
              </a>
            </div>
            <div className="appFooter__download">
              also available on android:
            </div>
            <div className="appFooter__downloadLinks">
              <a
                onClick={() => {
                  window?.fbq('track', 'AppLead');
                  window?.gtag('event', 'app_lead');
                }}
                target="_blank"
                href={ANDROID_APP}
                rel="noreferrer">
                <img src="/assets/android-store.png" />
              </a>
            </div>
          </div>
          <div className="appFooter__image">
            <div className="only-desktop offset">
              <picture>
                <source type="image/webp" srcSet={footerImg2Webp} />
                <img
                  src={footerImg2}
                  alt="app starting experience screenshot"
                />
              </picture>
            </div>
            <picture>
              <source type="image/webp" srcSet={footerImg1Webp} />
              <img src={footerImg1} alt="app playing screenshot" />
            </picture>
          </div>
        </div>
      </Container>
    </div>
  );
};
