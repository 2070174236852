import React, { useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import ReactPortal from './portal';
import classNames from "classnames";

import './modal.less';

function Modal({ children, isOpen, handleClose, buttonClose = true, border = true, scrollable = false, bigger = false }) {
  const nodeRef = useRef(null);
  useEffect(() => {
    const closeOnEscapeKey = (e) => (e.key === 'Escape' ? handleClose() : null);
    document.body.addEventListener('keydown', closeOnEscapeKey);
    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKey);
    };
  }, [handleClose]);

  return (
    <ReactPortal wrapperId="react-portal-modal-container">
      <CSSTransition
        in={isOpen}
        timeout={{ entry: 0, exit: 300 }}
        unmountOnExit
        classNames="modal"
        nodeRef={nodeRef}>
        <div className="modal" ref={nodeRef} onClick={handleClose}>
          <div 
          className={classNames("modal-content", {borderless : !border, scrollable: scrollable, bigger : bigger})}
          onClick={(e) => e.stopPropagation()}>
            {buttonClose && <div onClick={handleClose} className="close-btn" />}
            <div className="content">{children}</div>
          </div>
        </div>
      </CSSTransition>
    </ReactPortal>
  );
}
export default Modal;
