import React from "react";
import './banner-styles.less';
import { AssementFooter } from "../AssessmentFooter";


export const StatementBanner = ({
    onBack = () => {},
    onNext = () => {},
    hideNext = false,
    hideBack = false,
    title,
    subtitle,
    children
}) => {
    return (
        <div className='yellow_background'>
            <div className='title_container banner_container'>
                <h1 className='label statement_title'>{title}</h1>
                <h2 className='label statement_subtitle'>{subtitle}</h2>
                {children}
            </div>
            <AssementFooter
                onBack={onBack}
                onNext={onNext}
                primary
                hideNext={hideNext}
                hideBack={hideBack}
            />
        </div>
    );
}