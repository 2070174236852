import React from 'react';

import NetworkLogo from '../assets/network_logo.svg?react';

const Logo = () => {
  return (
    <div className="icon logo-with-text">
      <NetworkLogo className="resize-mobile" />
    </div>
  );
};

export default Logo;
