import React, { Component, createRef } from 'react';
import './plansdrawer.less';
import { Link } from 'react-router-dom';
import { screen } from 'helpers';
import logo from 'assets/logo.svg';

import { Container, Section, Layout, Stepper } from 'components';

import { GlobalContext } from 'context';
import { PlanForUpgrade } from 'components/PlanForUpgrade';
import classNames from 'classnames';

function getState({ context }) {
  const startInfo = context.startInfo;
  return {
    dateOfBirth: startInfo ? startInfo.dateOfBirth : undefined,
    state: startInfo?.state,
    locked: false,
    loading: false,
    error: false,
    allOptions: false
  };
}

const planColors = ['calmblue', 'nueblue'];

@screen
export default class PlansDrawer extends Component {
  static contextType = GlobalContext;
  static title = 'Maintenance Plans';

  state = getState(this);

  discoverRef = createRef();
  plansRef = createRef();

  componentDidMount() {
    window.scrollTo({ top: 0 });
    this.context.loadFlagQuestions();
    window?.gtag('event', 'view_start_form');

    try {
      const params = new URL(document.location).searchParams;

      var email = params.get('email') ? params.get('email') : '';
      var code = params.get('code') ? params.get('code') : '';
      var practice = params.get('practice') ? params.get('practice') : '';
      var allOptions = params.get('allOptions') ? params.get('allOptions') : 0;

      this.context.setEmail(email);
      this.context.setCheckoutCode(code);
      this.context.setCheckoutPractice(practice);
      this.setState({allOptions: allOptions});

    } catch (e) {}
  }

  render() {
    const { plansForUpgrade } = this.context;

    const plansToShow = (this.state.allOptions == 1)
                ? plansForUpgrade.filter(filterPlan => !filterPlan.id.includes("upgrade"))
                : plansForUpgrade.filter(filterPlan => filterPlan.id.includes("4") || filterPlan.id.includes("6"));

    return (
      <div className={classNames('stepStart', 'checkout')}>
        <div className="header">
          <Layout
            spread
            horizontal
            center
            style={{
              flexDirection: 'row',
              display: 'flex',
              justifyContent: 'center',
            }}>
            <Link to="/home">
              <img src={logo} />
            </Link>
          </Layout>
        </div>
        <Section wellness flat>
          {' '}
          <div>
            <Stepper current={0} steps={4} />
          </div>
          <div className="plansForUpgrade plansDrawer">
            <div className="plans">
              <div className="title">
                find the right Maintenance program for you
              </div>
              {plansForUpgrade.length > 0 && (
                <div className="wrapper" style={{ justifyContent: 'center' }}>
                  {plansToShow.map((plan, index) =>
                    plan.displayAtMaintain == 'true' ? (
                      <PlanForUpgrade
                        key={plan.id}
                        plan={plan}
                        color={planColors[index]}
                        flow={this.props.flow}
                        email={this.context.email}
                        code={this.context.checkoutCode}
                        practice={this.context.checkoutPractice}
                      />
                    ) : (
                      <></>
                    )
                  )}
                </div>
              )}
            </div>
          </div>
        </Section>
      </div>
    );
  }
}
