import React, { Component } from 'react';
import { screen } from 'helpers';
import { Button } from '../../../components/Button';
import './styles.less';
import { GlobalContext } from 'context';


@screen
export default class AppOnlySignUpStart extends Component {
  static contextType = GlobalContext;

  static title = 'Sign up | Ketamine Therapy Consultation | Nue Life';

  render() {
    return (
      <div className='background'>
        {/* TODO: add progress bar */}
        <div className='title_container'>
          <h1 className='label start_title'>
            Welcome to Nue Life Health!<br />
            Let’s create your personal account.<br />
          </h1>
          <div className='started_button_container'>
            <Button
              secondary
              className={'started_button'}
              onClick={() => {
                this.props.history.push('/free-user/sign-up/state');
              }}
            >
              {'Get Started'}
            </Button>
          </div>
            <h1 className='label start_sub_title'>
              We are dedicated to supporting both your physical and mental health.
            </h1>         
        </div>
      </div>
    );
  }
}