import React from 'react';
import './landing.less';
import { Link } from 'react-router-dom';

import { Button, IconArrowDown } from 'components';

import styles from './hero.modules.less';
import NueLink from './NueLink';

function scrollToTargetAdjusted(element) {
  const headerOffset = 90;
  const elementPosition = element.getBoundingClientRect().top;
  const offsetPosition =
    elementPosition - headerOffset - document.body.getBoundingClientRect().top;

  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth',
  });
}

const Hero = ({ scrollToRef }) => {
  return (
    <div id="nue-network-hero" className="column">
      <h2 className={styles.header2}>
        Transformative at-home <strong>ketamine therapy</strong> delivered by ethical &
        experienced providers.
      </h2>
      <p className={styles.bodytext}>
        <b>The fastest-acting antidepressant. Safe and effective.</b>
      </p>
      <p className={styles.bodytext}>
        <br />
        <b className="mb">
          Begin to experience rest & relief 24-72 hours after your first
          treatment.
        </b>
        <br />
        Nue Life therapies are not a free clinical trial. Affordable treatment
        packages start at $167 per session. Contact us to learn more.
      </p>

      <Button secondary long as={NueLink} to={'/talk-to-us'}>
        Get started
      </Button>

      <Button
        pill
        className="only-desktop"
        transparent
        aria-label="scroll down"
        onClick={() => scrollToTargetAdjusted(scrollToRef.current)}
        to="discover">
        <IconArrowDown />
      </Button>
    </div>
  );
};

export default Hero;
