import React from 'react';
import './review.less';

export const Review = ({ review }) => {
  return (
    <div className="review">
      <p className="review__quote bodytext">“ {review.quote} ”</p>
      <div className="review__profile">
        <div className="name">
          <span className="name">{review.name}</span>
        </div>
      </div>
    </div>
  );
};
