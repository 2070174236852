import React, { Component } from 'react';
import { screen } from 'helpers';
import { Button } from '../../../components/Button';
import './styles.less';
import { GlobalContext } from 'context';
import { ProgressBar } from 'components/ProgressBar';

@screen
export default class Start extends Component {

  static contextType = GlobalContext;
  static title = 'Talk to Us | Ketamine Therapy Consultation | Nue Life';
  static description =
    'Talk to one of our experts to help you identify which Nue Life ketamine therapy program is the best fit to start your journey to mental wellness.';
  state = {
    isValidQuery: true
  }

  componentDidMount() {
    window.localStorage.setItem('ttu_version', 'v2');
    this.setState({
      isValidQuery : window.sessionStorage.getItem('validQueryParams') != null
                        ? window.sessionStorage.getItem('validQueryParams')
                        : true});
  }


    render() {


    return (
    <div>
        {/*<ProgressBar
          questionIndex={0}
    /> */}
      <div className='background'>       
        <div className='title_container'>
          <h1 className='label start_title'>
            Is Nue Life right for me?<br/>
            Take the assessment
          </h1>
          <div className='started_button_container'>
            <Button
              secondary
              className={'started_button'}
              onClick={() => {
                this.props.history.push('/talk-to-us/state');
              }}
              disabled={!this.state.isValidQuery}
            >
              {'Get Started'}
            </Button>
          </div>
          <div className='started_button_container'>
            <p className='caveat_start'>
                My Ketamine Home is now powered by Nue Life
            </p>
          </div>
        </div>
      </div>
    </div>
    );
  }
}