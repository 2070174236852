import React from 'react';
// import { Loader } from 'semantic-ui-react';
import Hls from 'hls.js';

import './stream.less';

const IS_IOS =
  /iPad|iPhone|iPod/.test(navigator.platform) ||
  (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

export default class VideoStream extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      error: null,
    };
    this.ref = React.createRef();
  }

  componentDidMount() {
    const { video } = this.props;
    if (video) {
      this.loadStream(video.playbackUrl);
    }
    this.ref?.current?.addEventListener('ended', this.onPlaybackEnded);
  }

  componentDidUpdate(lastProps, lastState) {
    const status = this.props.video?.status;
    const lastStatus = lastProps.video?.status;
    const playbackUrl = this.props.video?.playbackUrl;
    const lastPlaybackUrl = lastProps.video?.playbackUrl;
    const { isActive } = this.state;
    if (playbackUrl && playbackUrl !== lastPlaybackUrl) {
      this.setState(
        {
          isActive: false,
        },
        () => {
          this.loadStream(playbackUrl);
        }
      );
    }
    if (status !== lastStatus) {
      // Attempt to load the live stream again if the status changes
      if (status === 'live' && playbackUrl) {
        this.loadStream(playbackUrl);
      }
    }
    if (isActive !== lastState.isActive) {
      if (isActive) {
        this.startStream();
      }
    }
  }

  componentWillUnmount() {
    this.unloadStream();
    this.ref?.current?.removeEventListener('ended', this.onPlaybackEnded);
  }

  loadStream(playbackUrl) {
    if (Hls.isSupported()) {
      this.hls = new Hls();
      this.hls.on(Hls.Events.ERROR, this.onHlsError);
      this.hls.on(Hls.Events.LEVEL_UPDATED, this.onHlsLevelUpdated);
      this.hls.on(Hls.Events.LEVEL_LOADED, this.onHlsLevelLoaded);
      this.hls.loadSource(playbackUrl);
    } else if (IS_IOS) {
      this.setState({
        isActive: true,
      });
    }
  }

  unloadStream() {
    if (this.hls) {
      this.hls.detachMedia();
      this.hls.off(Hls.Events.ERROR, this.onHlsError);
      this.ref.current.pause();
      this.hls = null;
    }
  }

  startStream() {
    if (this.hls) {
      this.hls.attachMedia(this.ref.current);
      // Note: iOS safari cannot autoplay, as it requires
      // user interaction.
      if (this.props.autoPlay) {
        this.play();
      }
    }
  }

  async play() {
    try {
      this.ref.current.play();
      if (this.props.onStart) {
        this.props.onStart();
      }
    } catch (err) {
      // DOMException may throw if not interacted with document yet
    }
  }

  onHlsLevelUpdated = (name, { details }) => {
    this.setLevel(details);
  };

  onHlsLevelLoaded = (name, { details }) => {
    this.setLevel(details);
  };

  onHlsError = (name, { fatal, ...rest }) => {
    if (fatal) {
      const { message, type } = this.getErrorDetails(rest);
      if (type === 'live_stream_inactive' || type === 'failed_request') {
        this.setLive(false);
      } else {
        this.setState({
          error: new Error(message || 'Stream error'),
        });
      }
    }
  };

  getErrorDetails({ details, networkDetails }) {
    const { video } = this.props;
    if (video?.source === 'mux') {
      const data = JSON.parse(networkDetails.response);
      const [message] = data.error.messages;
      return {
        message,
        type: data.error.type,
      };
    } else {
      return {
        message: details,
      };
    }
  }

  onPlaybackEnded = () => {
    const el = this.ref.current;
    if (el.webkitExitFullScreen) {
      el.webkitExitFullScreen();
    } else if (el.exitFullScreen) {
      el.exitFullScreen();
    }
    if (this.props.onEnd) {
      this.props.onEnd();
    }
  };

  setLevel(details) {
    if (this.isLiveVideo()) {
      this.setLive(details.live);
    } else {
      this.setState({
        isActive: true,
      });
    }
  }

  setLive(live) {
    if (live && !this.state.isActive) {
      this.setState({
        isActive: live,
      });
    }
  }

  isLiveVideo() {
    const { videoType } = this.props.video;
    return videoType === 'live';
  }

  isLoading() {
    const { videoType, status } = this.props.video;
    return videoType === 'vod' && status === 'preparing';
  }

  render() {
    if (Hls.isSupported() || IS_IOS) {
      const { video, loader } = this.props;
      const { isActive, error } = this.state;
      return (
        <div className="video-stream">
          {loader && this.isLoading() && <div>loading</div>}
          {error && (
            <div className={'video-stream__error'}>{error.message}</div>
          )}
          <video
            ref={this.ref}
            src={video.playbackUrl}
            controlsList="nodownload"
            disablePictureInPicture
            poster={video.poster}
            controls={isActive ? true : false}
            className={[
              'video-stream__video',
              !isActive ? 'video-stream__disabled' : null,
            ]
              .filter(Boolean)
              .join(' ')}
          />
        </div>
      );
    } else {
      return 'Live stream not supported';
    }
  }
}
