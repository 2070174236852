import React from 'react';
import { uniq } from 'lodash';

const params = new URLSearchParams(window.location.search);

export default function useExperiment(experimentId, friendlyName) {
  const [variant, setVariant] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      if (window.dataLayer) {
        await window.dataLayer.push({ event: 'optimize.activate' });
      }
      const intervalId = setInterval(() => {
        if (window.google_optimize !== undefined) {
          if (['1', '0'].includes(params.get('variant'))) {
            setVariant(params.get('variant'));
            return;
          }
          // Set the variant to the state.
          let variant = window.google_optimize.get(experimentId);
          if (variant) {
            variant = variant.replace(/\s/, '');
            setVariant(variant);
            window.gVariant = `${friendlyName}:${variant}`;
            window.localStorage.setItem('gVariant', uniq(window.gVariant));
          }
          clearInterval(intervalId);
        }
      }, 50);
    })();
  }, []);

  return variant;
}
