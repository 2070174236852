import React, { useState } from 'react';
import './footer.less';
import { Container } from '../Container';
import { Link } from 'react-router-dom';

import { Button } from '../Button';
import {
  IconInstagram,
  IconTwitter,
  IconFacebook,
  IconLinkedin,
  LogoWithText,
} from '../Icons';
import hipaa from 'assets/hipaa.png';
import { COMPANY_DOMAIN } from 'utils/env';

export const Footer = ({ LinkComponent = Link }) => {
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);

  return (
    <div className={'footer'}>
      <Container>
        <div className="footer__container">
          <div className="column logo">
            <LogoWithText />
          </div>
          <div className="column links">
            <div className="column">
              <p className="link">
                <LinkComponent to={'/how-ketamine-works'}>
                  how ketamine works
                </LinkComponent>
              </p>
              <p className="link">
                <a href="https://intercom.help/nuelife/en" target="_blank">
                  support
                </a>
              </p>
            </div>
            <div className="column">
              <p className="link">
                <a href="https://nue.freshteam.com/jobs" target="_blank">
                  careers
                </a>
              </p>
              <p className="link">
                <LinkComponent to={`/impact`}>impact</LinkComponent>
              </p>
            </div>
          </div>
          <form
            className="column last"
            onSubmit={async (e) => {
              e.preventDefault();
              const formData = new FormData();
              formData.append('g', 'RL2qjR');
              formData.append('email', email);
              formData.append(
                'timezone_offset',
                new Date().getTimezoneOffset() / 60
              );

              await fetch(
                'https://manage.kmail-lists.com/ajax/subscriptions/subscribe',
                {
                  headers: {
                    accept: '*/*',
                    'accept-language': 'en-GB,en-US;q=0.9,en;q=0.8',
                    'content-type':
                      'application/x-www-form-urlencoded; charset=UTF-8',
                  },
                  body: new URLSearchParams(formData).toString(),
                  method: 'POST',
                  mode: 'cors',
                  credentials: 'omit',
                }
              );
              setSuccess(true);
            }}>
            <p>
              <b>Begin your journey with us.</b> <br />
              Sign up to the Nue Life newsletter.
            </p>
            {!success && (
              <p>
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Your email"
                  type="email"
                />
                <Button wellness compact type="submit">
                  Sign up
                </Button>
              </p>
            )}
            <p></p>
            {success && (
              <p>
                Thanks for subscribing! Check your email for a confirmation
                message.
              </p>
            )}
          </form>
        </div>
        <div className="footer__bottom">
          <div className="certs">
            <img src={hipaa} alt="LegitScript approved" />

            <a
              href="https://legitscript.com"
              target="_blank"
              title="Verify LegitScript Approval"
              rel="noreferrer">
              <img
                src="//static.legitscript.com/seals/11149754.png"
                alt="LegitScript approved"
              />
            </a>
          </div>
        </div>
        <div className="footer__final">
          <div className="rights">
            © {new Date().getFullYear()} Nue Life Health, PBC. All Rights
            Reserved <br />
            <br />
            Nue Life Health PBC is a technology platform that provides services
            to affiliated independently owned and operated medical practices,
            and does not own, direct, or control the medical professionals
            providing the standard of care to their patients.
          </div>
          <div className="links">
            <a href={'https://about.nue.life/privacy-policy'}>Privacy policy</a>
            <a href={'https://about.nue.life/terms-conditions'}>
              Terms & conditions
            </a>
            <LinkComponent to={`/nue-care-terms-conditions`}>
              Nue Life Membership Terms
            </LinkComponent>
            {/* disabled for now should be ported */}
            {false && <a href={`${COMPANY_DOMAIN}/contact`}>Contact us</a>}
          </div>
          <div className="socialmedia">
            <a
              href="https://www.instagram.com/nue.life.health/"
              target="_blank"
              rel="noreferrer">
              <IconInstagram />
            </a>
            <a
              href="https://www.facebook.com/NueLifeHealth"
              target="_blank"
              rel="noreferrer">
              <IconFacebook />
            </a>
            <a
              href="https://twitter.com/nuelifehealth"
              target="_blank"
              rel="noreferrer">
              <IconTwitter />
            </a>
            <a
              href="https://www.linkedin.com/company/nuelife/"
              target="_blank"
              rel="noreferrer">
              <IconLinkedin />
            </a>
          </div>
        </div>
      </Container>
    </div>
  );
};
