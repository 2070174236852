import React, { Component } from 'react';
import { screen } from 'helpers';
import { Redirect, withRouter } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import Start from './steps/Start';
import DateAndState from './steps/DateAndState';
import OutOfState from './steps/OutOfState';
import ThankYou from './steps/ThankYou';
import QuestionnaireStart from './steps/QuestionnaireStart';
import YourEmail from './steps/YourEmail';
import Questionnaire from './steps/Questionnaire';
import End from './steps/End';
import { StatementBanner } from './components/StatementBanner';
import Congratulations from './steps/Congratulations';
import AppOnlySignUpStart from './steps/FreeUserSignUpStart';
import Account from 'screens/SignupFlows/steps/Account';
import FreeUserSignUpEnd from './steps/FreeUserSignUpEnd';


@withRouter
@screen
export default class AppOnlySignUp extends Component {

  componentDidMount() {
    window.sessionStorage.setItem('isAppOnlySignUp', 'true');
  }

  render() {
    return (
      <Switch>
        <Route path="/free-user/sign-up/email" component={() => 
          <YourEmail history={this.props.history} prevRoute={'/free-user/sign-up/available'} nextRoute={'/free-user/sign-up/account'} />} 
        exact />
        <Route path="/free-user/sign-up/thank-you" component={() => 
          <ThankYou />} exact />
        <Route path="/free-user/sign-up/out-of-state" component={() => 
          <OutOfState history={this.props.history} nextRoute={'/free-user/sign-up/thank-you'} />} 
        exact />
        <Route path="/free-user/sign-up/state" component={() => 
          <DateAndState history={this.props.history} 
            availableRoute={"/free-user/sign-up/account"} 
            unavailableRoute={"/free-user/sign-up/account"} 
            prevRoute={"/free-user/sign-up"} 
            textContent={<div className='blue label state_title'>Now, we'd like to get to know you better. Please fill in the following information to personalize your experience. Your details will be kept confidential and secure.</div>}
          />} 
        exact />
        <Route path="/free-user/sign-up/account" component={() => 
          <Account
            flow="free-user-sign-up"
            step={3}
            numberOfSteps={3}
            createLead
            stepTitle="You're almost there! Create your login information."
            stepSubTitle=" Remember, your password should be a combination of letters, numbers, and special characters for optimum security. "
            // setPersonalInfoFields={(fields) => this.setState(fields)}
            // plan={plan}
            onDone={(personalInfoFields) => {
              this.props.history.push(`/free-user/sign-up/congratulations`);
            }}
          />}
        exact />
        <Route path="/free-user/sign-up/congratulations" component={() => 
          <FreeUserSignUpEnd />} 
        exact />
        <Route path="/free-user/sign-up" component={() => 
          <AppOnlySignUpStart history={this.props.history} />} 
        exact />
        <Redirect to="/free-user/sign-up" />
      </Switch>
    );
  }
}